import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Divider,
  OutlinedInput,
  IconButton,
  Tooltip,
  FormControl,
  InputAdornment,
  Button,
  FormHelperText,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import { apiConfig } from 'src/config';
import axios from 'axios';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import LoadingAnimation from 'src/components/LoadingAnim';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonNotify = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

function StatusComingSoon() {
  const { t } = useTranslation();

  const calculateTimeLeft = () => {
    const difference = +new Date(`2023`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  const { partnerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    let res = await axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}stripe/api/v1/connect/partner/${partnerId}`
      )
      .catch(() => {
        enqueueSnackbar('Error fetching stripe connect url', {
          variant: 'error'
        });
      });
    console.log(res);
    if (res?.data?.accountLink?.url) {
      window.location.href = res?.data?.accountLink?.url;
    } else {
      enqueueSnackbar('Error fetching stripe connect url', {
        variant: 'error'
      });
    }
  }, [partnerId]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box textAlign="center" px={3}>
        <TypographyH1 variant="h1">{timeLeft[interval]}</TypographyH1>
        <TypographyH3 variant="h3">{interval}</TypographyH3>
      </Box>
    );
  });

  return (
    <>
      <Helmet>
        <title>Stripe Conenct</title>
      </Helmet>
      <MainContent>
        {apiConfig.ENVIRONMENT === 'demo' && (
          <Container maxWidth="md">
            <Box textAlign="center" mb={3}>
              <Container maxWidth="xs">
                <Typography
                  variant="h1"
                  sx={{
                    mt: 4,
                    mb: 2
                  }}
                >
                  {t('Coming Soon')}
                </Typography>
                <Typography
                  variant="h3"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 4
                  }}
                >
                  {t("We're working on it!")}
                </Typography>
              </Container>
              <img
                alt="Coming Soon"
                height={200}
                src="/static/images/status/coming-soon.svg"
              />
            </Box>

            <Box display="none" justifyContent="center">
              {timerComponents?.length ? timerComponents : <>Time's up!</>}
            </Box>

            <Container maxWidth="sm" sx={{ display: 'none' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: 4
                }}
              >
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInputWrapper
                    type="text"
                    placeholder={t('Enter your email address here...')}
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonNotify variant="contained" size="small">
                          {t('Notify Me')}
                        </ButtonNotify>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <MailTwoToneIcon />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {t("We'll email you once our website is launched!")}
                  </FormHelperText>
                </FormControl>
                <Divider
                  sx={{
                    my: 4
                  }}
                />
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Tooltip arrow placement="top" title="Facebook">
                    <IconButton color="primary">
                      <FacebookIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Twitter">
                    <IconButton color="primary">
                      <TwitterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Instagram">
                    <IconButton color="primary">
                      <InstagramIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Container>
          </Container>
        )}
        {apiConfig.ENVIRONMENT !== 'demo' && (
          <Box display="flex">
            <LoadingAnimation large/>
          </Box>
        )}
      </MainContent>
    </>
  );
}

export default StatusComingSoon;
