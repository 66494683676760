import axios from 'axios';
import { apiConfig } from 'src/config';

export const createDemoVendor = async (demoUserData, imageFile) => {

  const formData = new FormData();
  demoUserData.logoFileName = imageFile?.name;
  if (imageFile) {
    formData.append(imageFile?.name, imageFile);
  }
  formData.append('companyProfilePayload', JSON.stringify(demoUserData));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const payload = {
    companyProfilePayload: JSON.stringify(demoUserData)
  };
  console.log('payload', payload);
  console.log('config', config);

  return axios
    .post(
      apiConfig.APP_BASE_URL + 'demo/api/v1/add/vendor/demo',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const createSubscribe = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'demo/api/v1/subscribe', payload)
    .then((res) => {
      return res.data;
    });
};

export const getCheckDemoUserSubscribed = async (payload) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'demo/api/v1/check/subscription/status',
      payload
    )
    .then((res) => {
      return res.data;
    });
};
