import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Card,
  IconButton,
  Tooltip,
  alpha,
  styled,
  Badge
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderUserbox from './Userbox';
import ProfileSwitcher from './Buttons/ProfileSwitcher';
import {
  NotificationsActiveOutlined,
  NotificationsActiveSharp
} from '@mui/icons-material';
import PartnerNotifications from './PartnerNotifications';
import {
  fetchAllNotifications,
  markNotificationsAsRead
} from 'src/services/partner/notificationService';
import { useSelector } from 'react-redux';
import { fetchPartnerProfileById } from 'src/services/partner/profileService';
import Logo from 'src/layouts/partner/AccentHeaderLayout/Header/Logo/index.js';
import _ from 'lodash';
import { _arrayBufferToBase64 } from 'src/utils/functions';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    // background-color: ${alpha(theme.colors.primary.main, 0.95)};
    background-color: #fff;
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    // &.active,
    // &:active,
    // &:hover {
    //   background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
    //   color: ${theme.colors.alpha.trueWhite[100]};
    // }
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function Header(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState(false);
  const selectedProfile = useSelector(
    (state) => state.profileTeams.selectedProfile
  );
  const handleDrawerToggle = () => {
    setOpenNotifications(!openNotifications);
    if (selectedProfile?.partnerId) {
      markNotificationsAsRead(selectedProfile?.partnerId).then(() => {});
      setNewNotification(false);
    }
  };

  useEffect(async () => {
    fetchAllNotifications().then((notificaionsRes) => {
      if (notificaionsRes?.length > 0) {
        let notificationsResponseData = [...notificaionsRes];

        notificationsResponseData = _.orderBy(
          notificationsResponseData,
          'createdAt',
          'desc'
        );
        console.log(notificationsResponseData);
        let temp = notificationsResponseData?.map((notification) => {
          if (notification?.image?.data) {
            let testData = _arrayBufferToBase64(notification?.image?.data);
            notification.image = testData;
          }
          return notification;
        });
        setNotifications([...temp]);
      } else {
        setNotifications([]);
      }
    });
  }, []);

  const checkNotificationStatus = () => {
    if (selectedProfile?.partnerId) {
      fetchPartnerProfileById(selectedProfile?.partnerId).then((profile) => {
        if (
          profile?.notificationsRead !== undefined &&
          profile?.notificationsRead === false
        ) {
          setNewNotification(true);
        }
      });
    }
  };
  useEffect(() => {
    checkNotificationStatus();
    let messageInterval = setInterval(() => checkNotificationStatus(), 30000);
    return () => clearInterval(messageInterval);
  }, [checkNotificationStatus]);

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        {<Logo logo={props.partnerLogo} />}

        <BoxLogoWrapper>{/* <Logo /> */}</BoxLogoWrapper>
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <Box>
          {(!newNotification || notifications?.length === 0) && (
            <Tooltip arrow title="Open Notifications">
              <IconButtonPrimary
                sx={{ color: '#1179c4f2' }}
                onClick={handleDrawerToggle}
              >
                <NotificationsActiveOutlined />
              </IconButtonPrimary>
            </Tooltip>
          )}
          {newNotification && notifications?.length > 0 && (
            <IconButtonPrimary
              sx={{ color: '#1179c4f2' }}
              onClick={handleDrawerToggle}
            >
              <NotificationsBadge
                badgeContent={'NEW'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <NotificationsActiveSharp />
              </NotificationsBadge>
            </IconButtonPrimary>
          )}
        </Box>
        <Box>
          <ProfileSwitcher />
        </Box>
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButtonPrimary
              sx={{ color: '#1179c4f2' }}
              onClick={toggleSidebar}
            >
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButtonPrimary>
          </Tooltip>
        </Box>
      </Box>
      <PartnerNotifications
        notifications={notifications}
        openNotifications={openNotifications}
        handleDrawerToggle={handleDrawerToggle}
        newNotification={newNotification}
      />
    </HeaderWrapper>
  );
}

export default Header;
