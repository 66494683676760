import {
  Card,
  Grid,
  Typography,
  CardActionArea,
  CardMedia,
  Link,
  Chip
} from '@mui/material';

import styled from '@emotion/styled';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
    display: flex;
    align-items: center;
    // justify-content: space-between;
    position: relative;

    .MuiTouchRipple-root {
        opacity: .3;
    }

    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .05;
        }
    }
  `
);

const ProspectViewPage = ({ prospectPage }) => {
  const location = useLocation();
  const nevigate = useNavigate();
  return (
    <>
      <Card
        sx={{
          p: 3,
          mt: 2
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pb: 0,
              textAlign: 'left',
              mb: 0
            }}
          >
            <Typography variant="h4" mb={1}>
              {'Client Name'}
            </Typography>
            <Typography variant="body2" fontSize={'15px'}>
              {prospectPage?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pb: 0,
              textAlign: 'left',
              mb: 0
            }}
          >
            <Typography variant="h4" mb={1}>
              {'Client Company'}
            </Typography>
            <Typography variant="body2" fontSize={'15px'}>
              {prospectPage?.company}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} mt={2}>
            <div
              dangerouslySetInnerHTML={{
                __html: prospectPage?.description
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {prospectPage?.contents?.map((contentDetails) => (
              <Grid item xs={12} md={3}>
                <Card
                  sx={{ textAlign: 'center', position: 'relative' }}
                  className="contnet_card"
                >
                  <CardActionAreaWrapper
                    onClick={() => {
                      nevigate(
                        `/${location.pathname.split('/')[1]}/content/${
                          contentDetails.content_id
                        }`
                      );
                    }}
                  >
                    {contentDetails?.logo && (
                      <img
                        src={`data:image;base64, ${contentDetails?.logo}`}
                        alt="content"
                        className="content-img"
                      />
                    )}
                    {!contentDetails?.logo && (
                      <>
                        <CardMedia
                          component="img"
                          image="/static/images/placeholders/content_image_blank.jpg"
                          alt="..."
                          width={'auto'}
                          height={'150px'}
                        />
                      </>
                    )}
                  </CardActionAreaWrapper>
                  {/* <Typography variant="body2" maxHeight={50} minHeight={50}>
                    {contentDetails?.title}
                  </Typography> */}
                  <Typography
                    variant="body2"
                    maxHeight={50}
                    minHeight={50}
                    m={1}
                    className="content_playbook_title"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={`/${location.pathname.split('/')[1]}/content/${
                        contentDetails.content_id
                      }`}
                    >
                      {contentDetails?.title}
                    </Link>
                  </Typography>
                  <Chip
                    label={'Content'}
                    className="content_playbook_chip"
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      backgroundColor: '#FF2171',
                      color: '#fff',
                      fontSize: '12px'
                    }}
                  />
                </Card>
              </Grid>
            ))}
            {/* </Grid>
          <Grid container spacing={2} p={2}> */}
            {prospectPage?.playbooks?.map((playbookDetails) => (
              <Grid item xs={12} md={3}>
                <Card
                  sx={{ textAlign: 'center', position: 'relative' }}
                  className="contnet_card"
                >
                  <CardActionAreaWrapper
                    onClick={() => {
                      nevigate(
                        `/${location.pathname.split('/')[1]}/playbook/${
                          playbookDetails.playbook_id
                        }`
                      );
                    }}
                  >
                    {playbookDetails?.logo && (
                      <img
                        src={`data:image;base64, ${playbookDetails?.logo}`}
                        alt="content"
                        className="content-img"
                      />
                    )}
                    {!playbookDetails?.logo && (
                      <>
                        <CardMedia
                          component="img"
                          image="/static/images/placeholders/content_image_blank.jpg"
                          alt="..."
                          width={'auto'}
                          height={'150px'}
                        />
                      </>
                    )}
                  </CardActionAreaWrapper>
                  <Typography
                    variant="body2"
                    maxHeight={50}
                    minHeight={50}
                    m={1}
                    className="content_playbook_title"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={`/${location.pathname.split('/')[1]}/playbook/${
                        playbookDetails.playbook_id
                      }`}
                    >
                      {playbookDetails?.title}
                    </Link>
                  </Typography>
                  <Chip
                    label={'Playbook'}
                    className="content_playbook_chip"
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      backgroundColor: '#9400FF',
                      color: '#fff',
                      fontSize: '12px'
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProspectViewPage;
