import { useContext } from 'react';

import {
  Box,
  Card,
  IconButton,
  Tooltip,
  alpha,
  styled,
  Typography
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
// import HeaderSearch from './Search';
// import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import ProfileSwitcher from './Buttons/ProfileSwitcher';
import Logo from 'src/layouts/partnerProgram/AccentHeaderLayout/Header/Logo/index.js';

// import Logo from './Logo';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    // background-color: ${alpha(theme.colors.primary.main, 0.95)};
    background-color: #fff;

    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

   
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`
);

function Header(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <Logo logo={props.partnerVendorLogo} />
        <Typography variant="h3" color={'#1179c4f2'}>
          <h3>
            <strong>
              {props.vendorProfile && props.vendorProfile.business_name
                ? props.vendorProfile.business_name
                : 'Partner Portal'}
            </strong>
          </h3>
        </Typography>
        <BoxLogoWrapper>{/* <Logo /> */}</BoxLogoWrapper>
        {/* <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <HeaderSearch />
        </Box> */}
      </Box>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <ProfileSwitcher />
        </Box>
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButtonPrimary sx={{ color: '#1179c4f2' }} onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButtonPrimary>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
