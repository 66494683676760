import React, { useEffect, useState } from 'react';
import { apiConfig } from 'src/config';
import { getStandardVendorLogo } from 'src/services/public/profileService';
import { _arrayBufferToBase64 } from 'src/utils/functions';

export default function StandardVendorLogo({ logoHeight }) {
  const [standardLogo, setStandardLogo] = useState('');
  useEffect(() => {
    // getting stadard logo
    if (apiConfig?.DELIVERY_ENVIRONMENT === 'STANDARD') {
      console.log(window.location?.origin);
      const payload = {
        vendor_domain: window.location?.origin
      };
      getStandardVendorLogo(payload)
        .then((resp) => {
          if (resp) {
            const logoURL = `data:image;base64, ${_arrayBufferToBase64(
              resp?.logo?.data
            )}`;
            setStandardLogo(logoURL);
          } else {
            setStandardLogo('');
          }
        })
        .catch((error) => {
          console.log('error on fetching starndar vendor logo');
          console.log(error);
        });
    }
  }, []);
  return (
    <div>
      {apiConfig?.DELIVERY_ENVIRONMENT === 'STANDARD' ? (
        standardLogo && (
          <img
            style={{ height: logoHeight }}
            alt="Bufferloop"
            src={standardLogo}
          />
        )
      ) : (
        <img
          style={{ height: logoHeight }}
          alt="Bufferloop"
          src="/Bufferloop-Logo-login.png"
        />
      )}
    </div>
  );
}
