import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const ContentManagement = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Content'))
);
const ContentPreviewManagement = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Content/ContentPreview'))
);
const PlaybookManagement = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Playbook'))
);
const PlaybookPreview = Loader(
  lazy(() =>
    import('src/content/bufferloop/partner/BL-Playbook/BL-PlaybookContents')
  )
);
const TrainingManagement = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Training'))
);
const CoursePreview = Loader(
  lazy(() =>
    import('src/content/bufferloop/partner/BL-Training/BL-CoursePreview')
  )
);

const LecturePreview = Loader(
  lazy(() =>
    import('src/content/bufferloop/partner/BL-Training/BL-LecturePreview')
  )
);
const PDFEditor = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-PDFEditor'))
);

const ProgramSummary = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Summary'))
);
const PartnerLinks = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Links'))
);

const Referrals = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Referrals'))
);

const ProspectPages = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-ProspectPages'))
);
const Messenger = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Messenger'))
);
const Deals = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Deals'))
);
const DealsDetail = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-DealsDetail'))
);
const Leads = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Leads'))
);
const Performance = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Performance'))
);
const News = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-NewsAnnouncement'))
);
const partnerRoutes = [
  {
    path: '',
    element: <ProgramSummary />
  },
  /* {
    path: '/partner/program',
    element: <ContentManagement />
  }, */
  {
    path: '/partner/program/referrals',
    element: <Referrals />
  },
  {
    path: '/partner/program/prospectPages',
    element: <ProspectPages />
  },
  {
    path: '/partner/program/summary',
    element: <ProgramSummary />
  },
  {
    path: '/partner/program/leads',
    element: <Leads />
  },
  {
    path: '/partner/program/deals',
    element: <Deals />
  },
  {
    path: '/partner/program/deals/details/:id',
    element: <DealsDetail />
  },
  {
    path: '/partner/program/performance',
    element: <Performance />
  },

  {
    path: '/partner/program/news',
    element: <News />
  },

  {
    path: '/partner/program/:vendorId',
    element: <ProgramSummary />
  },
  {
    path: '/partner/program/content',
    element: <ContentManagement />
  },
  {
    path: '/partner/program/:vendorId/content/:contentId/preview',
    element: <ContentPreviewManagement />
  },
  {
    path: '/partner/program/playbook',
    element: <PlaybookManagement />
  },
  {
    path: '/partner/program/links',
    element: <PartnerLinks />
  },
  {
    path: '/partner/program/:vendorId/playbook/:playbookId/preview',
    element: <PlaybookPreview />
  },
  {
    path: '/partner/program/training',
    element: <TrainingManagement />
  },
  {
    path: '/partner/program/:vendorId/courses/:courseId/preview',
    element: <CoursePreview />
  },
  {
    path: '/partner/program/:vendorId/courses/lecture/:lectureId/preview',
    element: <LecturePreview />
  },
  {
    path: '/partner/program/pdf/edit/',
    element: <PDFEditor />
  },
  {
    path: '/partner/program/messaging',
    element: <Messenger />
  }
];

export default partnerRoutes;
