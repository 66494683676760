import 'react-quill/dist/quill.snow.css';

import { Grid, Typography /* Button */ } from '@mui/material';
// import { useState } from 'react';
// import GroupDetailsDialog from './GroupDetailsDialog.js';

function PageHeader({ name }) {
  // const [open, setOpen] = useState(false);
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={12} textAlign={'left'}>
          <Typography
            variant="h3"
            component="h3"
            className="inner_title"
            fontSize={20}
            gutterBottom
            style={{ margin: 0 }}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
