import {
  Accordion,
  AccordionDetails,
  Box,
  List,
  ListItemText
} from '@mui/material';

function Sidebar({ partnerBio, imageData }) {
  return (
    <>
      <Accordion
        sx={{
          p: 2
        }}
        defaultExpanded
      >
        {imageData ? (
          <AccordionDetails
            sx={{
              p: 0
            }}
          >
            <Box mt={2} textAlign="center">
              <img
                // src={imageUrl}
                src={`data:${partnerBio?.mimeType};base64, ${imageData}`}
                alt="content"
                width={'50%'}
                style={{
                  // border: '2px solid',
                  borderRadius: '50%'
                }}
              />
            </Box>
          </AccordionDetails>
        ) : null}

        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            <ListItemText
              primary={`Name: ${partnerBio?.name ? partnerBio?.name : ''}`}
              secondary={partnerBio?.email}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </List>
        </AccordionDetails>
        <br />
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            <ListItemText
              primary={`Company: ${
                partnerBio?.company ? partnerBio?.company : ''
              }`}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </List>
        </AccordionDetails>
        <br />

        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            <ListItemText
              primary={`Mobile: ${
                partnerBio?.mobile ? partnerBio?.mobile : ''
              }`}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </List>
        </AccordionDetails>
        <br />

        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            <ListItemText
              primary={`Email: ${partnerBio?.email ? partnerBio?.email : ''}`}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </List>
        </AccordionDetails>
        <br />

        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List disablePadding component="div">
            <ListItemText
              primary={`Linkedin Profile:: ${
                partnerBio?.linkedInProfileId
                  ? partnerBio?.linkedInProfileId
                  : ''
              }`}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Sidebar;
