import axios from 'axios';
import { apiConfig } from 'src/config';

// export const fetchGroupsForVendor = async (groupId) => {
//   return axios
//     .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/groups/fetch/' + groupId)
//     .then((res) => {
//       return res.data;
//     });
// };

export const addGroup = async (group) => {
  console.log('group to be created....', group);
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/groups', group)
    .then((res) => {
      return res.data;
    });
};

export const saveOnboardingForm = async (formData) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/groups/onboarding-form',
      formData
    )
    .then((res) => {
      return res.data;
    });
};

export const getOnboardingForm = async (group) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/groups/onboarding-form/' +
        group.vendorId +
        '/' +
        group.id
    )
    .then((res) => {
      return res.data;
    });
};

export const getOnboardingFormForPartner = async (groupId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'partner/public/api/v1/groups/onboarding-form/' +
        groupId
    )
    .then((res) => {
      return res.data;
    });
};

export const fetchGroupWisePartnerCountForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/partners/count/' + vendorId)
    .then((res) => {
      return res.data;
    });
};
export const fetchGroupsForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/groups/fetch/' + vendorId)
    .then((res) => {
      return res.data;
    });
};

export const saveOnboardingFormValues = async (formValuesToBeSaved) => {
  let encodedFormValuesToBeSaved = formValuesToBeSaved;
  return axios
    .post(
      apiConfig.APP_BASE_URL +
        'partner/public/api/v1/groups/onboarding-form/' +
        formValuesToBeSaved.groupId,
      encodedFormValuesToBeSaved
    )
    .then((res) => {
      return res.data;
    });
};

export const acceptPartnerRequest = async (partnerRequest) => {
  // let partnerRequest = partnerRequest;
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/partner/requests/accept',
      partnerRequest
    )
    .then((res) => {
      return res.data;
    });
};

export const declinePartnerRequest = async (partnerRequest) => {
  // let partnerRequest = partnerRequest;
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/partner/requests/decline',
      partnerRequest
    )
    .then((res) => {
      return res.data;
    });
};

export const updateGroup = async (group) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/groups/update', group)
    .then((res) => {
      return res.data;
    });
};

export const deleteGroup = async (group) => {
  return axios
    .delete(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/group/' +
        group.vendorId +
        '/' +
        group.id
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleGroup = async (group) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/group/' +
        group.vendorId +
        '/' +
        group.id
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
