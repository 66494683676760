import * as Yup from 'yup';
import { Formik } from 'formik';
// import { Link as RouterLink } from 'react-router-dom';

import {
  // Box,
  Button,
  // Divider,
  FormHelperText,
  // Checkbox,
  Link,
  // FormControlLabel,
  TextField,
  // Typography,
  CircularProgress,
  Box
  // styled
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import firebase from 'src/utils/firebase';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { fetchProfile as fetchVendorProfile } from 'src/services/vendor/profileService';
import { fetchProfile as fetchAdminProfile } from 'src/services/admin/profileService';
import { fetchProfile as fetchPartnerProfile } from 'src/services/partner/profileService';
import { Link as RouterLink } from 'react-router-dom';
import { fetchCustomerProfile } from 'src/services/prospect/prospectService';
import { apiConfig } from 'src/config';

/* const ImgWrapper = styled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
); */

function LoginFirebaseAuth() {
  const {
    signInWithEmailAndPassword,
    logout /* , sendEmailVerificationLink, logout */
  } = useAuth();
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useSnackbar();

  /* const handleGoogleClick = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  }; */

  return (
    <>
      {/* <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        variant="outlined"
      >
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        Sign in with Google
      </Button> */}
      {/* <Divider
        sx={{
          mt: 4,
          mb: 2
        }}
      >
        {t('or')}
      </Divider> */}
      <Formik
        initialValues={{
          email: '',
          password: '',
          terms: false,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(255)
            .required(t('The email field is required')),
          password: Yup.string()
            .max(255)
            .required(t('The password field is required'))
          /* terms: Yup.boolean().oneOf(
            [true],
            t('You must agree to our terms and conditions')
          ) */
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signInWithEmailAndPassword(values.email, values.password)
              .then(async (result) => {
                console.log('................', result);
                if (result?.user?.emailVerified) {
                  toast.enqueueSnackbar('Sign in Successful', {
                    variant: 'success'
                  });
                } else {
                  // sendEmailVerificationLink();
                  toast.enqueueSnackbar('Please verify email address', {
                    variant: 'error'
                  });
                  logout();
                  navigate('/login');
                  return;
                }
                console.log(result);
                const idTokenResult = await firebase
                  .auth()
                  .currentUser.getIdTokenResult();
                //
                let claims = idTokenResult?.claims;
                console.log('idTokenResult......', idTokenResult);
                if (
                  claims &&
                  (claims.role === 'admin' || claims.role === 'superAdmin')
                ) {
                  if (apiConfig.DELIVERY_ENVIRONMENT === 'NETWORK') {
                    fetchAdminProfile(values.email, idTokenResult)
                      .then((resp) => {
                        // console.log('user profile', profile);
                        if (resp?.suspended === true) {
                          toast.enqueueSnackbar('User Suspended', {
                            variant: 'error'
                          });
                          navigate('/login');
                        } else {
                          navigate('/admin');
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        toast.enqueueSnackbar(
                          'Error while fetching profile in bufferloop',
                          { variant: 'error' }
                        );
                        navigate('/login');
                      });
                  } else {
                    toast.enqueueSnackbar('You are not authorized', {
                      variant: 'warning'
                    });
                    logout();
                    navigate('/login');
                    return;
                  }

                  // navigate('/admin');
                } else if (claims && claims.role === 'vendor') {
                  //  fetch profile
                  fetchVendorProfile(values.email, idTokenResult)
                    .then((resp) => {
                      if (resp?.suspended === true) {
                        toast.enqueueSnackbar('User Suspended', {
                          variant: 'error'
                        });
                        navigate('/login');
                      } else {
                        navigate('/vendor');
                      }
                      // console.log('user profile', profile);
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.enqueueSnackbar(
                        'Error while fetching profile in bufferloop',
                        { variant: 'error' }
                      );
                      navigate('/login');
                    });
                } else if (claims && claims.role === 'partner') {
                  fetchPartnerProfile(values.email, idTokenResult)
                    .then((resp) => {
                      // console.log('user profile', profile);
                      if (resp?.suspended === true) {
                        toast.enqueueSnackbar('User Suspended', {
                          variant: 'error'
                        });
                        navigate('/login');
                      } else {
                        navigate('/partner');
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.enqueueSnackbar(
                        'Error while fetching partner profile',
                        { variant: 'error' }
                      );
                      navigate('/login');
                    });
                } else if (claims && claims.role === 'customer') {
                  fetchCustomerProfile(values.email)
                    .then(() => {
                      // console.log('user profile', profile);
                      navigate('/customer');
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.enqueueSnackbar(
                        'Error while fetching customer profile',
                        { variant: 'error' }
                      );
                      navigate('/login');
                    });
                } else {
                  navigate('/login');
                  toast.enqueueSnackbar('You are not authorized!', {
                    variant: 'error'
                  });
                }
                //
                if (isMountedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              })
              .catch((error) => {
                console.log(error);
                if (
                  error.message ===
                  'Firebase: The user account has been disabled by an administrator. (auth/user-disabled).'
                ) {
                  toast.enqueueSnackbar(
                    "We're sorry, but your account has been disabled. Please contact with admin",
                    { variant: 'error' }
                  );
                } else {
                  toast.enqueueSnackbar(
                    'Authentication Error - Error while signing in',
                    { variant: 'error' }
                  );
                }
                navigate('/login');
              });
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label={t('Email address')}
              placeholder={t('Your email address here...')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t('Password')}
              placeholder={t('Your password here...')}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="space-between"
            >
              {/* <Box display={{ xs: 'block', md: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.terms}
                      name="terms"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <>
                      <Typography variant="body2">
                        {t('I accept the')}{' '}
                        <Link component="a" href="#">
                          {t('terms and conditions')}
                        </Link>
                        .
                      </Typography>
                    </>
                  }
                />
              </Box> */}
              <Link component={RouterLink} to="/account/recover-password">
                <b>{t('Lost password?')}</b>
              </Link>
              <Link component={RouterLink} to="/account/email-verification">
                <b>{t('Email Verification ?')}</b>
              </Link>
            </Box>
            {Boolean(touched.terms && errors.terms) && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}
            <Button
              sx={{
                mt: 3
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('Sign in')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default LoginFirebaseAuth;
