// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import {
  AdminPanelSettingsOutlined,
  AssignmentIndOutlined,
  AssignmentOutlined,
  BadgeOutlined,
  CategoryOutlined,
  CountertopsOutlined,
  DashboardOutlined,
  FlagCircleOutlined,
  NotificationsActiveOutlined,
  PeopleAltOutlined,
  AutoAwesomeOutlined,
} from '@mui/icons-material';

const menuItems = [
  {
    // heading: 'Admin Menu',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardOutlined,
        link: '/admin/dashboards'
      },
      {
        name: 'Vendors',
        icon: BadgeOutlined,
        link: '/admin/vendors'
      },
      {
        name: 'Partners',
        icon: PeopleAltOutlined,
        link: '/admin/partners'
      },
      {
        name: 'Invoices',
        icon: AssignmentOutlined,
        link: '/admin/invoices'
      },
      {
        name: 'Customers',
        icon: PeopleAltOutlined,
        link: '/admin/customers'
      },
      {
        name: 'Admin Users',
        icon: AdminPanelSettingsOutlined,
        link: '/admin/users'
      },
      {
        name: 'AppSumo',
        icon: AutoAwesomeOutlined,
        link: '/admin/appsumo'
      },
      {
        name: 'Coupons',
        icon: CountertopsOutlined,
        link: '/admin/coupons'
      },
      {
        name: 'Demo Vendors',
        icon: AssignmentIndOutlined,
        link: '/admin/demo'
      },
      {
        name: 'System Flags',
        icon: FlagCircleOutlined,
        link: '/admin/flags'
      },
      {
        name: 'Notifications',
        icon: NotificationsActiveOutlined,
        link: '/admin/notifications'
      },
      {
        name: 'Category',
        icon: CategoryOutlined,
        link: '/admin/category'
      },
      // {
      //   name: 'AppSumo',
      //   icon: AutoAwesomeOutlined,
      //   link: '/admin/appsumo'
      // }
    ]
  }
];

export default menuItems;
