import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  IconButton,
  // Link,
  // ListItemText,
  Tooltip,
  Typography,
  // alpha,
  // styled,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import NotificationDialog from './NotificationPreviewDetails';
import { fetchAllNotifications } from 'src/services/vendor/notificationService';
import { _arrayBufferToBase64 } from 'src/utils/functions';
import _ from 'lodash';

function VendorNotifications({
  notifications,
  openNotifications,
  setOpenNotifications,
  newNotification
}) {
  // const IconButtonPrimary = styled(IconButton)(
  //   ({ theme }) => `
  //     background: ${theme.colors.alpha.trueWhite[10]};
  //     color: ${theme.colors.primary.dark[70]};
  //     padding: 0;
  //     width: 42px;
  //     height: 42px;
  //     border-radius: 100%;
  //     margin-left: ${theme.spacing(2)};

  //     &.active,
  //     &:active,
  //     &:hover {
  //       background: ${alpha(theme.colors.alpha.black[5], 0.2)};
  //       color:  ${theme.colors.error.dark};
  //     }
  // `
  // );
  const imgBox = {
    height: '40px',
    width: '60px',
    borderRadius: '0.5',
    objectFit: 'contain'
  };
  const theme = useTheme();
  const [selectedNotification, setSelectedNotification] = useState();
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [notificationsObj, setNotificationsObj] = useState(notifications);
  const handleDrawerToggle = () => {
    setOpenNotifications(!openNotifications);
  };

  useEffect(() => {
    fetchAllNotifications().then((notificaionsRes) => {
      if (notificaionsRes?.length > 0) {
        let notificationsResponseData = [...notificaionsRes];

        notificationsResponseData = _.orderBy(
          notificationsResponseData,
          'createdAt',
          'desc'
        );
        console.log(notificationsResponseData);
        let temp = notificationsResponseData?.map((notification) => {
          if (notification?.image?.data) {
            let testData = _arrayBufferToBase64(notification?.image?.data);
            notification.image = testData;
          }
          return notification;
        });
        setNotificationsObj([...temp]);
      } else {
        setNotificationsObj([]);
      }
    });
  }, [newNotification]);

  return (
    <Drawer
      sx={{
        display: { xs: 'none', md: 'flex' }
      }}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'left' : 'right'}
      open={openNotifications}
      onClose={handleDrawerToggle}
      elevation={9}
    >
      <Box
        sx={{
          minWidth: 360
        }}
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography id="vendor_notification_slider" variant="h3">
            Notifications
          </Typography>
        </Box>
        <Tooltip arrow title={'Close'}>
          <IconButton
            size="small"
            sx={{ width: 30 }}
            color="error"
            onClick={() => {
              setOpenNotifications(false);
            }}
          >
            X
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <Box flex flexDirection={'column'} justifyContent={'flex-start'}>
        {notificationsObj &&
          notificationsObj?.map((notification) => (
            <>
              <Card
                sx={{
                  m: 2,
                  p: 1,
                  mb: 2,
                  // background: `${theme.colors.gradients.blue5}`,
                  borderRadius: 0.5,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setSelectedNotification(notification);
                  setOpenNotificationDialog(true);
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2.5}>
                    {notification?.image && (
                      <img
                        alt=""
                        variant="square"
                        style={imgBox}
                        src={`data:image;base64, ${notification?.image}`}
                      />
                    )}
                  </Grid>
                  <Grid item sx={12} sm={9.5}>
                    <Grid item md={12}>
                      {/* <IconButton
                        onClick={() => {
                          setSelectedNotification(notification);
                          setOpenNotificationDialog(true);
                        }}
                      > */}
                      <Typography
                        variant="body2"
                        sx={{ maxWidth: '300px', fontWeight: 'bold' }}
                      >
                        {notification?.title}
                      </Typography>
                      {/* </IconButton> */}
                    </Grid>
                    {/* <Grid item sx={8} md={12}>
                      <Grid container>
                        <Grid item md={10}>
                          <Typography
                            variant="subtitle2"
                            sx={{ ml: 1, maxWidth: '300px' }}
                            className="nameEllips"
                          >
                            <Link>{notification?.link}</Link>
                          </Typography>{' '}
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Card>
            </>
          ))}
      </Box>
      <NotificationDialog
        notification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        setOpenNotificationDialog={setOpenNotificationDialog}
        openNotificationDialog={openNotificationDialog}
      />
    </Drawer>
  );
}

export default VendorNotifications;
