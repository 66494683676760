import axios from 'axios';
import { apiConfig } from 'src/config';

export const saveProspect = async (prospect, imageFile) => {
  const formData = new FormData();
  prospect.logoFileName = imageFile?.name;
  if (imageFile) {
    formData.append(imageFile?.name, imageFile);
  }
  formData.append('prospectData', JSON.stringify(prospect));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'prospect/api/v1/save/prospect',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const createProspect = async (prospect, imageFile) => {
  const formData = new FormData();
  prospect.logoFileName = imageFile?.name;
  if (imageFile) {
    formData.append(imageFile?.name, imageFile);
  }
  formData.append('prospectData', JSON.stringify(prospect));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'prospect/api/v1/create/prospect',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const getSingleProspect = async (prospectId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'prospect/api/v1/get/single/' + prospectId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchAllProspects = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'prospect/api/v1/fetch/all/lite')
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateCustomer = async (prospectId) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL +
        'prospect/api/v1/activate/prospect/' +
        prospectId,
      { prospectId: prospectId }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateLoginForCustomer = async (prospectId) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL +
        'prospect/api/v1/generate/credentials/' +
        prospectId,
      { prospectId: prospectId }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchCustomerProfile = async (userEmail) => {
  console.log(userEmail);
  return axios
    .post(apiConfig.APP_BASE_URL + 'prospect/api/v1/profile/fetch', {
      userEmail
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchCustomerProfileById = async (customerId) => {
  return axios
    .get(
      `${apiConfig.APP_BASE_URL}prospect/api/v1/profile/fetch/id/${customerId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getBillingAccountURL = async (stripeCustomerId) => {
  console.log(stripeCustomerId);
  return axios
    .post(apiConfig.APP_BASE_URL + 'prospect/api/v1/get/billing/url', {
      stripeCustomerId: stripeCustomerId
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchActiveUserSubscriptions = async (stripeCustomerId) => {
  console.log(stripeCustomerId);
  return axios
    .post(apiConfig.APP_BASE_URL + 'prospect/api/v1/get/user/subscriptions', {
      stripeCustomerId: stripeCustomerId
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchProducts = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'prospect/api/v1/fetch/products/')
    .then((res) => {
      return res?.data?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createDirectProspect = async (prospect, imageFile) => {
  const formData = new FormData();
  prospect.logoFileName = imageFile?.name;
  if (imageFile) {
    formData.append(imageFile?.name, imageFile);
  }
  formData.append('prospectData', JSON.stringify(prospect));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'prospect/api/v1/save/prospect/customer',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};
