import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
  styled,
  Button
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import firebase from 'src/utils/firebase';
import FirebaseAuthRegister from '../RegisterFirebaseAuth';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { signupwithGoogle } from 'src/services/partner/registrationService';
import { fetchProfile } from 'src/services/vendor/profileService';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import LoadingAnimatio2 from 'src/components/LoadingAnim2';
import { apiConfig } from 'src/config';
import StandardVendorLogo from 'src/components/Standard-Logo/StandardVendorLogo';
import TempLinkPlan from './TempLinkPlan';
import EmailVerifyDialog from './EmailVerifyDialog';
import { sendEmail } from 'src/services/email/emailService';
import { signUpEmailTemplate } from 'src/emailTemplates';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0 0 0 0;
    }
    width: 100%;
    display: flex;
    align-items: center;
`
);

const LogoWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: ${theme.spacing(4)};
    top: ${theme.spacing(4)};
`
);

function RegisterCover() {
  const { method, signInWithGoogle } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [emailVerifyModel, setEmailVerifyModel] = useState(false);
  const googleSignupHandler = async () => {
    await signInWithGoogle()
      .then(async (data) => {
        setLoading(true);
        console.log('data', data);
        console.log('data?.user', data?.user);
        let payload = {
          name: data?.user?.displayName,
          email: data?.user?.email
        };
        await signupwithGoogle(payload)
          .then(async (data) => {
            console.log('data...........', data);
            // let provider = new firebase.auth.GoogleAuthProvider();
            // await firebase
            //   .auth()
            //   .currentUser.reauthenticateWithPopup(provider)
            //   .catch((error) => {
            //     console.log(error);
            //   });
            const idTokenResult = await firebase
              .auth()
              .currentUser.getIdTokenResult();
            //

            let claims = idTokenResult.claims;

            console.log('idTokenResult', idTokenResult);
            console.log('claims', claims);
            const vendorLogo = '';
            const subject = `Welcome to Bufferloop✌️🚀`;
            const message = payload.name;
            const userLogo =
              vendorLogo?.selectedVendorLogo ||
              `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
            const urlLink = process.env.REACT_APP_UI_PORTAL_URL;
            const emailPayload = signUpEmailTemplate(
              subject,
              message,
              userLogo,
              urlLink,
              [payload.email]
            );

            await sendEmail(emailPayload)
              .then(() => {})
              .catch((error) =>
                console.log('Error sending welcome email to partner', error)
              );

            console.log('claims.role', claims.role);
            console.log('data?.user?.email', payload?.email);
            if (claims && claims.role === 'partner') {
              await fetchProfile(payload?.email, idTokenResult)
                .then(() => {
                  setLoading(false);
                  navigate('/test');
                  navigate('/partner');
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                  enqueueSnackbar('Error while fetching partner profile', {
                    variant: 'error'
                  });
                  navigate('/login');
                });
            } else {
              navigate('/login');
              setLoading(false);
              // enqueueSnackbar('You are not authorized!', {
              //   variant: 'error'
              // });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            if (err?.response?.data) {
              enqueueSnackbar(err?.response?.data, {
                variant: 'error'
              });
            }
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log('google sign in error', error);
      });
  };

  useEffect(() => {
    if (apiConfig.DELIVERY_ENVIRONMENT === 'STANDARD') {
      navigate(`/login`);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Register - Cover</title>
      </Helmet>

      {loading && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-around'}
        >
          <LoadingAnimatio2 large={false} />
        </Box>
      )}
      {!loading && (
        <Content>
          <MainContent>
            <LogoWrapper
              sx={{
                display: { xs: 'none', sm: 'inline-block' }
              }}
            >
              {/* <Logo /> */}
            </LogoWrapper>

            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              maxWidth="sm"
            >
              <div style={{ marginTop: '7px' }}>
                <StandardVendorLogo logoHeight={50} />
              </div>

              <Card
                sx={{
                  p: 4,
                  my: 2
                }}
              >
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1
                    }}
                  >
                    {t('Partner Signup')}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    {t('Fill in the fields below to sign up for an account.')}
                  </Typography>
                </Box>
                {method === 'FirebaseAuth' && (
                  <FirebaseAuthRegister setOpen={setEmailVerifyModel} />
                )}
                {process.env.REACT_APP_ENVIRONMENT === 'prod' ||
                process.env.REACT_APP_ENVIRONMENT === 'demo' ? (
                  <></>
                ) : (
                  <TempLinkPlan />
                )}

                <EmailVerifyDialog
                  setOpen={setEmailVerifyModel}
                  open={emailVerifyModel}
                />

                <Box
                  mt={4}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box justifyContent={'space-around'}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                      fontWeight="bold"
                    >
                      {t('Already have an account?')}
                    </Typography>{' '}
                    <Link component={RouterLink} to="/login">
                      <b>{t('Sign in here')}</b>
                    </Link>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      googleSignupHandler();
                    }}
                    color={'error'}
                  >
                    {'Sign up with Google'}
                  </Button>
                </Box>
              </Card>
            </Container>
          </MainContent>
        </Content>
      )}
    </>
  );
}

export default RegisterCover;
