import { Helmet } from 'react-helmet-async';
import NewVendorOnboarding from '../../admin/BL-Customers/NewVendorOnboarding';

function index() {
  return (
    <>
      <Helmet>
        <title>Customer Information</title>
      </Helmet>
      <NewVendorOnboarding />
    </>
  );
}

export default index;
