import axios from 'axios';
import { apiConfig } from 'src/config';

export const getCompanyProfileForPartner = async (partnerId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/company/profile/' + partnerId)
    .then((res) => {
      return res?.data;
    });
};

export const saveCompanyProfileForPartner = async (
  partnerCompanyProfilePayload,
  logoFile
) => {
  const formData = new FormData();
  if (logoFile) {
    formData.append(logoFile?.name, logoFile);
  }
  formData.append(
    'companyProfilePayload',
    JSON.stringify(partnerCompanyProfilePayload)
  );

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/company/profile',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};

// Member services

export const saveMemberDetails = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/team/member/create',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

export const updateMemberDetails = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/team/member/update',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

export const deleteTeamMemberForPartner = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/team/member/remove',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

// export const getAllTeamMembersForPartner = async (partnerId) => {
//   return axios
//     .get(
//       apiConfig.APP_BASE_URL + 'partner/api/v1/team/member/fetch/' + partnerId
//     )
//     .then((res) => {
//       return res.data;
//     });
// };

export const getAllTeamMembersForPartner2 = async (partnerId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL + 'partner/api/v1/team/member/2/fetch/' + partnerId
    )
    .then((res) => {
      return res.data;
    });
};

// Teams Services

export const saveTeamForPartner = async (team) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'partner/api/v1/team/new', team)
    .then((res) => {
      return res.data;
    });
};

export const updateTeamForPartner = async (teamPayload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'partner/api/v1/team/update', teamPayload)
    .then((res) => {
      return res.data;
    });
};
export const deleteteam = async (partnerId, teamId) => {
  return axios
    .delete(
      apiConfig.APP_BASE_URL +
        'partner/api/v1/team/delete/' +
        partnerId +
        '/' +
        teamId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleTeamForPartner = async (partnerId, teamId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'partner/api/v1/team/single/' +
        partnerId +
        '/' +
        teamId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllTeamsForPartner = async (partnerId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/team/fetch/' + partnerId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
