import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Footer from 'src/components/Footer';
// import Header from 'src/layouts/BaseLayout/Header';

import {
  getOnboardingFormForPartner,
  saveOnboardingFormValues
} from 'src/services/vendor/groupService';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import useAuth from 'src/hooks/useAuth';
import { Formik } from 'formik';
import useRefMounted from 'src/hooks/useRefMounted';
import axios from 'axios';
import {
  apiConfig,
  emailContentTemplate
  // joinRequestEmailConfig,
  // sendRequestEmailConfig
} from 'src/config';
import { sendEmail } from 'src/services/email/emailService';
import validator from 'validator';
import { validate } from 'react-email-validator';
import { useSelector } from 'react-redux';
import {
  fetchVendorProfileById,
  getCompanyProfileForVendor
} from 'src/services/public/profileService';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import _ from 'lodash';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: auto;
        box-shadow: none;
      }
`
);

function OnboardingFormPublic() {
  const {
    fetchSignInMethodsForEmail,
    logout,
    signInWithEmailAndPassword,
    user
  } = useAuth();
  const selectedProfile = useSelector(
    (state) => state.profileTeams.selectedProfile
  );
  const { groupId } = useParams();
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValuesMap, setCustomFieldValuesMap] = useState();
  const [partnerName, setPartnerName] = useState(
    selectedProfile?.name === 'My'
      ? user?.name
      : selectedProfile?.profile?.business_name
  );
  const [partnerEmail, setPartnerEmail] = useState(
    selectedProfile?.name === 'My'
      ? user?.email
      : selectedProfile?.profile?.email
  );
  const [partnerWebsite, setPartnerWebsite] = useState(
    selectedProfile?.name === 'My'
      ? user?.website
      : selectedProfile?.profile?.website
  );
  const [onboardingForm, setOnboardingForm] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAddPartnerDialog, setOpenAddPartnerDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const [vendorId, setVendorId] = useState();
  const [vendorImage, setVendorImage] = useState();
  const [vendorCompanyProfile, setVendorCompanyProfile] = useState();
  const [vendorProfile, setVendorProfile] = useState();

  useEffect(() => {
    setLoading(true);

    getOnboardingFormForPartner(groupId)
      .then((onboardingForm) => {
        setVendorId(parseInt(onboardingForm?.vendorId));
        setOnboardingForm(onboardingForm);
        fetchVendorProfileById(onboardingForm.vendorId).then((res) => {
          setVendorProfile(res);
        });
        const countryField = {
          field_name: 'Country',
          mandatory: true,
          selectOptions: [],
          type: 'text'
        };

        const customFieldsTemp = [countryField].concat(
          onboardingForm?.customFields
        );
        if (customFieldsTemp?.length > 0) {
          // [...onboardingForm.customFields];
          let temp = customFieldsTemp?.map((field) => {
            if (field?.type === 'select-multiple') {
              field.selectedValues = [];
            }
            return field;
          });
          let tempMap = new Map();
          customFieldsTemp.forEach((field) => {
            tempMap.set(field?.field_name, field);
          });
          setCustomFieldValuesMap(tempMap);
          setCustomFields([...temp]);
        }
        setFormEnabled(onboardingForm.isEnabled);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [groupId]);

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(async () => {
    if (vendorId) {
      getCompanyProfileForVendor(vendorId).then((vendorCompanyProfileRes) => {
        if (vendorCompanyProfileRes) {
          setVendorCompanyProfile(vendorCompanyProfileRes);
          let data = _arrayBufferToBase64(vendorCompanyProfileRes?.logo?.data);
          setVendorImage(data);
        } else {
          setVendorCompanyProfile();
          setVendorImage();
        }
      });
    }
  }, [vendorId, groupId]);

  const handleChange = (event) => {
    if (
      [
        'text',
        'email',
        'number',
        'textArea',
        'textarea',
        'url',
        'select-one',
        'radio',
        'select',
        'select-multiple'
      ].includes(event.target.type)
    ) {
      let field = customFieldValuesMap.get(event.target.name);
      field.value = event.target.value;
      customFieldValuesMap.set(event.target.name, field);
    }

    if (event.target.name === 'privacy_policy') {
      let field = customFieldValuesMap.get(event.target.name);
      field.value = event.target.checked;
      customFieldValuesMap.set(event.target.name, field);
    }
    if (
      event.target.type === 'checkbox' &&
      event.target.name !== 'privacy_policy'
    ) {
      let field = customFieldValuesMap.get(event.target.name);
      if (!field.selectedValues.includes(event.target.value)) {
        field.selectedValues.push(event.target.value);
      } else {
        field.selectedValues.splice(
          field.selectedValues.indexOf(event.target.value),
          1
        );
      }
      customFieldValuesMap.set(event.target.name, field);
    }
  };

  const resetForm = () => {
    let tempObj = [];
    customFields.forEach((value) => {
      value.value = '';
      tempObj.push(value);
    });

    setPartnerName('');
    setPartnerEmail('');
    setPartnerWebsite('');
    setCustomFields(tempObj);
    window.location.reload(false);
  };

  const checkIfPartnerIsSignedUp = async (partnerEmail) => {
    try {
      const singInMethods = await fetchSignInMethodsForEmail(partnerEmail);
      if (singInMethods?.length > 0) {
        return true;
      }
      return false;
    } catch (error) {
      enqueueSnackbar('Please check if email is correct', { variant: 'error' });
      setLoading(false);
      throw new Error();
    }
  };

  const saveForm = async () => {
    setLoading(true);
    let error = false;

    let fieldNames = [];
    let allField = [];
    customFieldValuesMap?.forEach((element, fieldName) => {
      fieldNames.push(fieldName);
      allField.push(element);
    });
    let requiredFields = [
      'text',
      'textArea',
      'number',
      'email',
      'url',
      'radio',
      'select'
    ];

    if (!partnerName || partnerName === '') {
      enqueueSnackbar('Please enter name', { variant: 'error' });
      error = true;
    } else if (partnerName?.length > 50) {
      enqueueSnackbar('Partner Name should be less than 50 characters', {
        variant: 'error'
      });
      error = true;
    }
    if (
      !partnerEmail ||
      partnerEmail === '' ||
      !validator.isEmail(partnerEmail)
    ) {
      enqueueSnackbar('Please enter valid email', { variant: 'error' });
      error = true;
    } else if (partnerEmail?.length > 50) {
      enqueueSnackbar('Email should be less than 50 characters', {
        variant: 'error'
      });
      error = true;
    }

    if (_.isEmpty(partnerWebsite) || partnerWebsite.trim()?.length <= 0) {
      enqueueSnackbar('Website field is required', {
        variant: 'error'
      });
      error = true;
    }

    if (!_.isEmpty(partnerWebsite) || partnerWebsite?.trim()?.length > 0) {
      if (!_.isEmpty(partnerWebsite) && !validator?.isURL(partnerWebsite)) {
        enqueueSnackbar('Please Enter Valid Website URL', {
          variant: 'error'
        });
        error = true;
      }
    }

    allField.forEach((item) => {
      if (item?.mandatory) {
        if (item.type === 'select-multiple') {
          if (item.selectedValues?.length <= 0) {
            error = true;
            enqueueSnackbar(`${item.field_name} field is required`, {
              variant: 'error'
            });
          }
        }
        if (requiredFields.includes(item.type)) {
          if (
            item.value === null ||
            item.value === undefined ||
            item.value === ''
          ) {
            error = true;
            enqueueSnackbar(`${item.field_name} field is required`, {
              variant: 'error'
            });
          }
        }
        if (item.type === 'email') {
          if (validate(item.value) === false) {
            error = true;
            enqueueSnackbar('Please enter valide email address', {
              variant: 'error'
            });
          }
        }
        if (item.field_name === 'privacy_policy') {
          if (!item.value && item.value !== true) {
            error = true;
            enqueueSnackbar(
              'Please Accept Terms & Condition and Privacy Policy',
              {
                variant: 'error'
              }
            );
          }
        }
      }
    });

    if (error) {
      setLoading(false);
      return;
    }

    let partnerSignedUp = await checkIfPartnerIsSignedUp(partnerEmail);
    if (!partnerSignedUp) {
      setLoading(false);
      setOpenAddPartnerDialog(true);
      return;
    }

    setLoading(true);
    let payload = {
      form_id: onboardingForm.form_id,
      group_id: onboardingForm.group_id,
      groupId: onboardingForm.groupId,
      partnerName: partnerName,
      partnerEmail: partnerEmail,
      partnerWebsite: partnerWebsite,
      customFieldValuesMap: [
        customFieldValuesMap ? [...customFieldValuesMap] : []
      ],
      vendorId: onboardingForm.vendorId
    };

    let success = await saveOnboardingFormValues(payload)
      .catch((error) => {
        if (error?.response?.data) {
          if (error?.response?.data?.includes('partnerEmail')) {
            enqueueSnackbar(
              'Partner with this email already exists in the system',
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(error?.response?.data, { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Saving partner data failed', { variant: 'error' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    if (success) {
      setLoading(false);
      enqueueSnackbar('Partner details submitted successfully', {
        variant: 'success'
      });

      if (vendorProfile?.id) {
        const vendorLogo = `${process.env.REACT_APP_BASE_API_URL}public/api/v1/vendor/image/${onboardingForm?.vendorId}/`;
        // let joinRequestEmailPayload = {
        //   subject: `${joinRequestEmailConfig.subject} ${partnerName}`,
        //   messageBody: `${joinRequestEmailConfig.messageBody}`,
        //   toIds: [vendorProfile.email]
        // };

        const subjectJoin = `You have New Partner Request`;
        const messageJoin = `<h1>Hey ${vendorProfile?.business_name},</h1>
                      <p>You have a new partner request. Login to the dashboard to see more details.</p>
                      <p>Act on partner request to approve or decline.</p>`;
        const userLogoJoin =
          vendorLogo || `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
        const urlLinkJoin = process.env.REACT_APP_UI_PORTAL_URL;
        const joinRequestEmailPayload = emailContentTemplate(
          subjectJoin,
          messageJoin,
          userLogoJoin,
          urlLinkJoin,
          [vendorProfile.email]
        );

        // let sendRequestEmailPayload = {
        //   subject: `${sendRequestEmailConfig.subject} ${vendorProfile.business_name}`,
        //   messageBody: `${sendRequestEmailConfig.messageBody}`,
        //   toIds: [partnerEmail]
        // };

        const subjectReq = `Request for joining partner program`;
        const messageReq = `<p>You have applied for the ${vendorProfile?.business_name} partner  program.</p>
          <p>Depending on your inputs, merchant will proceed for approval.</p>`;
        const userLogoReq =
          vendorLogo || `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
        const urlLinkReq = process.env.REACT_APP_UI_PORTAL_URL;
        const sendRequestEmailPayload = emailContentTemplate(
          subjectReq,
          messageReq,
          userLogoReq,
          urlLinkReq,
          [partnerEmail],
          true
        );

        sendEmail(joinRequestEmailPayload).catch((error) => {
          console.log('Error sending join partner request email', error);
        });
        sendEmail(sendRequestEmailPayload).catch((error) => {
          console.log('Error sending join partner request email', error);
        });
      }
      navigate('/partner');
      // navigate(-1);
      setFormSubmitted(true);
    }
  };
  return (
    <>
      {!loading && (
        <>
          {!formSubmitted && formEnabled && (
            <Grid
              container
              spacing={1}
              p={1}
              // sx={{ maxWidth: '50%', width: '100%', margin: 'auto' }}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              <Grid
                item
                display={'flex'}
                flexDirection={'column'}
                // alignContent={'center'}
                alignItems={'center'}
                // alignSelf={'center'}
                md={6}
                sm={12}
                xs={12}
              >
                <Card
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    zIndex: '999'
                  }}
                >
                  <Box mt={2}>
                    {/* <Header key={'headerSection'} vendorImage={vendorImage} /> */}
                    <img
                      src={`data:image;base64, ${vendorImage}`}
                      alt=""
                      style={{
                        maxWidth: '150px',
                        width: '100%'
                      }}
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="h3" color={'primary'}>
                      {vendorProfile?.business_name}
                    </Typography>
                    <div
                      style={{
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: vendorCompanyProfile?.description
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} />
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  zIndex: 15
                }}
              >
                <Card
                  key={'onboardingFormCard'}
                  // sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                >
                  <Box mt={2}>
                    <h2 align="center">{vendorProfile?.business_name} Form</h2>
                  </Box>
                  <CardContent>
                    <Grid
                      item
                      display={'flex'}
                      flexDirection={'column'}
                      // alignContent={'center'}
                      alignItems={'center'}
                      // alignSelf={'center'}
                      mb={1}
                    >
                      {/* <Typography variant="h3" color={'primary'}>
                      Please fill details below
                    </Typography> */}
                    </Grid>
                    {/* <Divider /> */}
                    <Grid container display={'flex'} flexDirection={'column'}>
                      <Grid
                        key={'partnerName'}
                        item
                        mr={3}
                        ml={3}
                        mb={1}
                        mt={2}
                      >
                        <TextField
                          name="partnerName"
                          placeholder={'Enter name'}
                          variant="outlined"
                          label={'Name'}
                          value={partnerName}
                          fullWidth
                          // sx={{ width: '500px' }}
                          onChange={(event) => {
                            setPartnerName(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        key={'partnerEmail'}
                        mr={3}
                        ml={3}
                        mb={1}
                        mt={2}
                      >
                        <TextField
                          name="partnerEmail"
                          placeholder={'Enter email'}
                          variant="outlined"
                          fullWidth
                          label={'Email'}
                          value={partnerEmail}
                          type={'email'}
                          onChange={(event) => {
                            setPartnerEmail(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        key={'partnerWebsite'}
                        mr={3}
                        ml={3}
                        mb={2}
                        mt={2}
                      >
                        <TextField
                          name="partnerWebsite"
                          placeholder={'Enter website'}
                          variant="outlined"
                          label={'Website*'}
                          type={'url'}
                          fullWidth
                          value={partnerWebsite}
                          onChange={(event) => {
                            setPartnerWebsite(event.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider key={'divider_123'} />
                    <form action="#">
                      <Grid
                        container
                        display={'flex'}
                        flexDirection={'column'}
                        key={'customFields'}
                      >
                        {customFields?.map((customField, index) => (
                          <>
                            <Grid
                              mr={3}
                              ml={3}
                              mb={2}
                              mt={2}
                              item
                              md={12}
                              key={index}
                            >
                              {customField &&
                                (customField.type === 'text' ||
                                  customField.type === 'number' ||
                                  customField.type === 'email' ||
                                  customField.type === 'url') && (
                                  <TextField
                                    fullWidth
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    required={customField.mandatory}
                                    label={customField.field_name}
                                    type={customField.type}
                                    name={customField.field_name}
                                    onChange={handleChange}
                                  />
                                )}
                              {customField &&
                                customField.type === 'textArea' && (
                                  <TextField
                                    name={customField.field_name}
                                    required={customField.mandatory}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    label={customField.field_name}
                                    type={customField.text_field_type}
                                    multiline
                                    minRows={3}
                                  />
                                )}
                              {customField && customField.type === 'checkbox' && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // checked={values.allDay}
                                      // onChange={handleChange}
                                      name={customField.field_name}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={customField.field_name}
                                />
                              )}
                              {customField && customField.type === 'radio' && (
                                <FormControl fullWidth>
                                  <FormLabel id={customField.field_name}>
                                    {customField.mandatory
                                      ? `${customField.field_name}*`
                                      : customField.field_name}
                                  </FormLabel>
                                  <RadioGroup
                                    // row
                                    aria-labelledby={customField.field_name}
                                    name={customField.field_name}
                                    required={customField.mandatory}
                                  >
                                    {customField.selectOptions &&
                                      customField.selectOptions?.map(
                                        (option, fieldIndex) => (
                                          <>
                                            <FormControlLabel
                                              key={fieldIndex}
                                              value={option.value}
                                              control={<Radio />}
                                              label={option.label}
                                              name={customField.field_name}
                                              onChange={handleChange}
                                            />
                                          </>
                                        )
                                      )}
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {customField && customField.type === 'select' && (
                                <FormControl fullWidth variant="outlined">
                                  {/* <Typography>{customField.field_name}</Typography> */}
                                  <FormLabel id={customField.field_name}>
                                    {customField.mandatory
                                      ? `${customField.field_name}*`
                                      : customField.field_name}
                                  </FormLabel>
                                  <Select
                                    native
                                    fullWidth
                                    id={customField.field_name}
                                    label={customField.field_name}
                                    required={customField.mandatory}
                                    inputProps={{
                                      name: customField.field_name
                                    }}
                                    name={customField.field_name}
                                    onChange={handleChange}
                                  >
                                    <option value="" selected disabled>
                                      Select{' '}
                                    </option>
                                    {customField.selectOptions &&
                                      customField.selectOptions?.map(
                                        (option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              )}
                              {customField &&
                                customField.type === 'select-multiple' && (
                                  <FormControl>
                                    <FormLabel id={customField.field_name}>
                                      {/* {customField.field_name} */}
                                      {customField.mandatory
                                        ? `${customField.field_name}*`
                                        : customField.field_name}
                                    </FormLabel>

                                    {customField.selectOptions &&
                                    customField.selectOptions?.length > 0
                                      ? customField.selectOptions?.map(
                                          (value, key) => {
                                            return (
                                              <FormControlLabel
                                                key={key}
                                                control={
                                                  <Checkbox
                                                    // name={value.label}
                                                    name={
                                                      customField.field_name
                                                    }
                                                    value={value.label}
                                                    color="primary"
                                                    onChange={handleChange}
                                                  />
                                                }
                                                label={value.label}
                                              />
                                            );
                                          }
                                        )
                                      : null}
                                  </FormControl>
                                )}

                              {customField &&
                                customField.field_name === 'privacy_policy' && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          // checked={values.allDay}
                                          // onChange={handleChange}
                                          name={customField.field_name}
                                          onChange={handleChange}
                                          color="primary"
                                        />
                                      }
                                      // label={`Accept Terms & Condition and Privacy Policy`}
                                    />
                                    <label>
                                      Accept{' '}
                                      <a
                                        href={customField?.termPolicyForm?.term}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Terms & Condition
                                      </a>{' '}
                                      and{' '}
                                      <a
                                        href={
                                          customField?.termPolicyForm?.policy
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Privacy Policy
                                      </a>
                                    </label>
                                  </>
                                )}
                            </Grid>
                            <Divider key={`divider${index}`} />
                          </>
                        ))}
                      </Grid>
                      <Grid
                        container
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-end'}
                      >
                        <Grid item md={12}>
                          <Button
                            sx={{ m: 1 }}
                            key={'resetFormButton'}
                            onClick={() => resetForm()}
                            type="reset"
                            size="medium"
                            variant="outlined"
                            color="error"
                            startIcon={<CancelOutlinedIcon fontSize="small" />}
                          >
                            {'Reset'}
                          </Button>
                          <Button
                            sx={{ m: 1 }}
                            key={'saveFormButton'}
                            onClick={() => saveForm()}
                            variant="contained"
                            color="success"
                            startIcon={<SaveOutlinedIcon fontSize="small" />}
                          >
                            {'Submit'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {formSubmitted && formEnabled && (
            <Card
              sx={{
                p: 3,
                m: 1,
                mt: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: '999'
              }}
            >
              <Typography variant="h4" color={'success'}>
                Partner details submitted successfully
              </Typography>
            </Card>
          )}
          {!formSubmitted && !formEnabled && (
            <Card
              sx={{
                p: 3,
                m: 1,
                mt: 10,
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h4" color={'success'}>
                Form seems to be disabled
              </Typography>
            </Card>
          )}
          <DialogWrapper
            maxWidth="lg"
            open={openAddPartnerDialog}
            onClose={() => {}}
          >
            <DialogTitle
              align="center"
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              disableTypography
            >
              <Typography variant="h4">{'Sign Up'}</Typography>
              <Tooltip arrow placement="top-end" title={'Close'}>
                <IconButton
                  color="primary"
                  sx={{
                    mx: 1,
                    alignContent: 'end'
                  }}
                  onClick={() => {
                    setOpenAddPartnerDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <DialogContent>
              <>
                <Formik
                  initialValues={{
                    email: partnerEmail,
                    website: partnerWebsite,
                    name: partnerName,
                    password: '',
                    terms: true,
                    submit: null
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email(
                        'The email provided should be a valid email address'
                      )
                      .max(50)
                      .required('The email field is required'),
                    password: Yup.string()
                      .min(6)
                      .max(15)
                      .required('The password field is required'),
                    terms: Yup.boolean().oneOf(
                      [true],
                      'You must agree to our terms and conditions'
                    )
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    setLoading(true);
                    try {
                      /* await createUserWithEmailAndPassword(
                    values.email,
                    values.password
                  ) */
                      let payload = {
                        email: partnerEmail,
                        password: values.password,
                        name: partnerName,
                        group_id: onboardingForm.group_id,
                        groupId: onboardingForm.groupId,
                        website: partnerWebsite
                      };
                      await axios
                        .post(
                          apiConfig.APP_BASE_URL +
                            'partner/public/api/v1/add/partner',
                          payload
                        )
                        .then(async () => {
                          await signInWithEmailAndPassword(
                            values.email,
                            values.password
                          ).then(async () => {
                            /* userCreds.user
                          .sendEmailVerification()
                          .then(function (res) {
                            console.log(
                              'send verification email call res',
                              res
                            );
                          }); */
                            // createPartner;
                            enqueueSnackbar(
                              'User is now registered, Saving details...',
                              {
                                variant: 'success'
                              }
                            );
                            logout();

                            setLoading(true);
                            let payload = {
                              form_id: onboardingForm.form_id,
                              group_id: onboardingForm.group_id,
                              groupId: onboardingForm.groupId,
                              partnerName: partnerName,
                              partnerEmail: partnerEmail,
                              partnerWebsite: partnerWebsite,
                              customFieldValuesMap: [
                                customFieldValuesMap
                                  ? [...customFieldValuesMap]
                                  : []
                              ],
                              vendorId: onboardingForm.vendorId
                            };
                            let success = await saveOnboardingFormValues(
                              payload
                            )
                              .catch((error) => {
                                if (error?.response?.data) {
                                  if (
                                    error?.response?.data?.includes(
                                      'partnerEmail'
                                    )
                                  ) {
                                    enqueueSnackbar(
                                      'Partner with this email already exists in the system',
                                      { variant: 'error' }
                                    );
                                  } else {
                                    enqueueSnackbar(error?.response?.data, {
                                      variant: 'error'
                                    });
                                  }
                                } else {
                                  enqueueSnackbar('Error saving partner data', {
                                    variant: 'error'
                                  });
                                }
                              })
                              .finally(() => {
                                setLoading(false);
                              });
                            if (success) {
                              enqueueSnackbar(
                                'Partner details submitted successfully',
                                {
                                  variant: 'success'
                                }
                              );

                              await fetchVendorProfileById(
                                onboardingForm.vendorId
                              ).then((res) => {
                                let vendorProfile = res;
                                if (vendorProfile?.id) {
                                  const vendorLogo = `${process.env.REACT_APP_BASE_API_URL}public/api/v1/vendor/image/${onboardingForm?.vendorId}/`;
                                  // let joinRequestEmailPayload = {
                                  //   subject: `${joinRequestEmailConfig.subject} ${partnerName}`,
                                  //   messageBody: `${joinRequestEmailConfig.messageBody}`,

                                  //   toIds: [vendorProfile.email]
                                  // };

                                  const subjectJoin = `You have New Partner Request`;
                                  const messageJoin = `<h1>Hey ${vendorProfile?.business_name},</h1>
                                  <p>You have a new partner request. Login to the dashboard to see more details.</p>
                                  <p>Act on partner request to approve or decline.</p>`;
                                  const userLogoJoin =
                                    vendorLogo ||
                                    `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
                                  const urlLinkJoin =
                                    process.env.REACT_APP_UI_PORTAL_URL;
                                  const joinRequestEmailPayload =
                                    emailContentTemplate(
                                      subjectJoin,
                                      messageJoin,
                                      userLogoJoin,
                                      urlLinkJoin,
                                      [vendorProfile.email]
                                    );

                                  // let sendRequestEmailPayload = {
                                  //   subject: `${sendRequestEmailConfig.subject} ${vendorProfile.business_name}`,
                                  //   messageBody: `${sendRequestEmailConfig.messageBody}`,
                                  //   toIds: [partnerEmail]
                                  // };

                                  const subjectReq = `Request for joining partner program`;
                                  const messageReq = `<p>You have applied for the ${vendorProfile?.business_name} partner program.</p>
                                    <p>Depending on your inputs, merchant will proceed for approval.</p>`;
                                  const userLogoReq =
                                    vendorLogo ||
                                    `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
                                  const urlLinkReq =
                                    process.env.REACT_APP_UI_PORTAL_URL;
                                  const sendRequestEmailPayload =
                                    emailContentTemplate(
                                      subjectReq,
                                      messageReq,
                                      userLogoReq,
                                      urlLinkReq,
                                      [partnerEmail],
                                      true
                                    );

                                  sendEmail(joinRequestEmailPayload).catch(
                                    (error) => {
                                      console.log(
                                        'Error sending join partner request email',
                                        error
                                      );
                                    }
                                  );
                                  sendEmail(sendRequestEmailPayload).catch(
                                    (error) => {
                                      console.log(
                                        'Error sending join partner request email',
                                        error
                                      );
                                    }
                                  );
                                }
                              });

                              setFormSubmitted(true);
                            }

                            setOpenAddPartnerDialog(false);
                            navigate('/partner');
                          });
                        })
                        .catch((err) => {
                          console.log(err.code);
                          if (err.code === 'auth/email-already-in-use') {
                            enqueueSnackbar(
                              'This email already exists in the system',
                              { variant: 'error' }
                            );
                          } else {
                            enqueueSnackbar('Error in signup', {
                              variant: 'error'
                            });
                          }
                          setOpenAddPartnerDialog(false);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                      if (isMountedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                      }
                    } catch (err) {
                      console.error(err);
                      if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={'Email address'}
                        placeholder={'Your email address here...'}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={'Password'}
                        placeholder={'Your password here...'}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.terms}
                            name="terms"
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {'I accept the'}{' '}
                            <Link component="a" href="#">
                              {'terms and conditions'}
                            </Link>
                            .
                          </Typography>
                        }
                      />
                      <Button
                        sx={{
                          mt: 3
                        }}
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        size="medium"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="success"
                      >
                        {'Create account'}
                      </Button>
                    </form>
                  )}
                </Formik>
              </>
            </DialogContent>
          </DialogWrapper>
          <Footer />
        </>
      )}
      {loading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: '20%'
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}
    </>
  );
}

export default OnboardingFormPublic;
