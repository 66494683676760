export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const uiPortUrl = process.env.REACT_APP_UI_PORTAL_URL;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
  // measurementId: 'G-47XYZSWLBC'
};
export const welcomeEmailConfig = {
  subject: 'Welcome to Bufferloop - ',
  messageBody:
    '<p>Welcome to Bufferloop,</p><p><br></p><p>Here you can connect with vast network of Vendors.</p><p>Please explore application.....</p><p>...Content here....</p><p><br></p><p><strong>Regards,</strong></p><p>Team Bufferloop</p><p><br></p><p><br></p>'
};

export const joinRequestEmailConfig = {
  subject: 'New join request from Partner - ',
  messageBody:
    '<p>Hello Vendor,</p><p><br></p><p>You have received a new request from partner<p><br></p><p><strong>Regards,</strong></p><p>Team Bufferloop</p><p><br></p><p><br></p>'
};

export const sendRequestEmailConfig = {
  subject: ' You have sent a join request to - ',
  messageBody:
    '<p>Hello Partner,</p><p><br></p><p>You have sent a join request to program<p><br></p><p><strong>Regards,</strong></p><p>Team Bufferloop</p><p><br></p><p><br></p>'
};

export const approveRewardsEmailConfig = {
  subject: 'Bufferloop - Your rewards are approved',
  messageBody: `<p>Dear Partner,</p><p>Some of your rewards were approved in the system</p><p>Please login to the application for further details</p><ul><li>Application Link - <a href=${uiPortUrl} rel="noopener noreferrer" target="_blank">Click here</a></li></ul></p><p>Thanks,</p><p>Team Bufferloop</p>`
};

export const DeclineEmailConfig = {
  subject: 'Your Join request was declined '
};

export const emailContentTemplate = (
  subject,
  bodyMessage,
  logo,
  url,
  userEmail,
  vendor
) => {
  return {
    subject: `${subject}`,
    messageBody: `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Your Email Title</title>
        <style>
        html {
            background-color: #f4f4f4;
        }
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
        }
        .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 2rem;
            background-color: #fff;
        }
            .header {
                background-color: #e8e8ff;
                color: #ffffff;
                text-align: center;
                padding: 10px;
                border-radius: 4px;
            }
            .content {
                padding: 20px;
            }
            .content p {
              font-size: 16px;
          }
        </style>
    </head>
    <body>
    <div style="background-color: #f4f4f4;">
        <div class="container" style="background-color: #f4f4f4; ">
            <img src="https://app.bufferloop.com/Bufferloop-Logo-login.png" width="150">
        </div>
        <div class="container">
            <div class="header" style="display: ${
              !vendor ? 'none' : 'block'
            }; ">
                <img src="${logo}" style="background-color: white; padding: 10px;  height: 45px; object-fit: contain;" alt="Bufferloop" />
            </div>
            <div class="content">
               <div>
               ${bodyMessage}
               </div>
                <div style="text-align: center; margin: 3rem 0; "><a href="${url}" style="background-color: #794DF5;color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;">Login To BufferLoop</a></div>
                <p>Team Bufferloop</p>
            </div>
        </div>
        <div class="container" style="background-color: #f4f4f4;">
            <div class="footer">
                <p style="font-size: .8rem;">You’re receiving this email because you are a partner of companies who use Bufferloop to manage their partner programs. If you suspect you have received a fraudulent email, please email us at care@bufferloop.com</p>
            </div>
        </div>
        </div>
    </body>
    </html>`,
    toIds: userEmail
  };
};

export const apiConfig = {
  // APP_BASE_URL: 'http://localhost:4000/bufferloop'
  // BUFFERLOOP CONFIG
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  DELIVERY_ENVIRONMENT: process.env.REACT_APP_DELIVERY_ENVIRONMENT,
  APP_BASE_URL: process.env.REACT_APP_BASE_API_URL,
  DEMO_VENDORS_API_URL: process.env.REACT_APP_DEMO_VENDORS_API_URL,
  APP_BASE_TRACKING_DOMAIN: process.env.REACT_APP_BASE_TRACKING_URL,
  TRACKING_SUBDOMAIN: process.env.REACT_APP_BL_TRACKING_SUBDOMAIN,
  TRACKING_ROUTE_PATH: process.env.REACT_APP_BASE_TRACKING_ROUTE_PATH,
  UI_BASE_URL: process.env.REACT_APP_BASE_UI_URL,

  // S3 Config
  S3_CLOUDFRONT_URL: process.env.REACT_APP_S3_CLOUDFRONT_URL,

  // STRIPE CONFIG
  STRIPE_CUSTOMER_PORTAL: process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL,
  STRIPE_NETWORK_PRICING_TABLE_ID:
    process.env.REACT_APP_STRIPE_NETWORK_PRICING_TABLE_ID,
  STRIPE_STANDARD_PRICING_TABLE_ID:
    process.env.REACT_APP_STRIPE_STANDARD_PRICING_TABLE_ID,
  STRIPE_PRICING_TABLE_PUBLISHABLE_KEY:
    process.env.REACT_APP_STRIPE_PRICING_TABLE_PUBLISHABLE_KEY
};

export const superAdminEmail = 'dev.bufferloop+superAdmin@gmail.com';

export const planType = {
  starter: {
    partners: 50,
    teams: 2,
    members: 5,
    groups: 2,
    offers: 5,
    deals: false,
    emailCampaigns: 5,
    cobranding: false,
    playbook: false,
    training: false,
    prospect: false,
    triggers: 10
  },
  premium: {
    partners: 150,
    teams: 5,
    members: 20,
    // members: 10,
    groups: 10,
    offers: 30,
    deals: true,
    emailCampaigns: 50,
    // emailCampaigns: 15,
    cobranding: true,
    playbook: true,
    training: true,
    prospect: true,
    triggers: 50
    // triggers: 30
  }
};
