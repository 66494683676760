export const acceptPartnerReqEmail = (
  subject,
  bodyMessage,
  logo,
  url,
  userEmail,
  vendor
) => {
  return {
    subject: `${subject}`,
    // messageBody: `<h1>Hello,</h1><p>A new customer request is created.</p><p>Customer - ${customerName}</p><p><strong>Regards,</strong></p><p>Team Bufferloop</p><p><br></p><p><br></p>`,
    messageBody: `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Email Title</title>
          <style>
          html {
                background-color: #f4f4f4;
            }
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f4f4f4;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                padding: 2rem;
                background-color: #fff;
            }
              .header {
                  background-color: #e8e8ff;
                  color: #ffffff;
                  text-align: center;
                  padding: 10px;
                  border-radius: 4px;
              }
              .content {
                  padding: 20px;
              }
              .content p {
                font-size: 16px;
                }
          </style>
      </head>
      <body>
      <div style="background-color: #f4f4f4;">
        <div class="container" style="background-color: #f4f4f4; ">
              <img src="https://app.bufferloop.com/Bufferloop-Logo-login.png" width="150">
          </div>
          <div class="container">
              <div class="header" style="display: ${
                !vendor ? 'none' : 'block'
              }; ">
                  <img src="${logo}" style="background-color: white; padding: 10px;  height: 45px; object-fit: contain;" alt="Bufferloop">
              </div>
              <div class="content">
                 <div>
                 ${bodyMessage}
                 </div>
                  <div style="text-align: center; margin: 3rem 0; "><a href="${url}" style="background-color: #794DF5;color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;">Login To BufferLoop</a></div>
              </div>
          </div>
          <div class="container" style="background-color: #f4f4f4;">
              <div class="footer">
                  <p style="font-size: .8rem;">You’re receiving this email because you are a partner of companies who use Bufferloop to manage their partner programs. If you suspect you have received a fraudulent email, please email us at care@bufferloop.com</p>
              </div>
          </div>
          </div>
      </body>
      </html>`,
    toIds: userEmail
  };
};

export const signUpEmailTemplate = (
  subject,
  bodyMessage,
  logo,
  url,
  userEmail,
  vendor
) => {
  return {
    subject: `${subject}`,
    // messageBody: `<h1>Hello,</h1><p>A new customer request is created.</p><p>Customer - ${customerName}</p><p><strong>Regards,</strong></p><p>Team Bufferloop</p><p><br></p><p><br></p>`,
    messageBody: `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Email Title</title>
          <style>
            html {
                background-color: #f4f4f4;
            }
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f4f4f4;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                padding: 2rem;
                background-color: #fff;
            }
              .header {
                  background-color: #e8e8ff;
                  color: #ffffff;
                  text-align: center;
                  padding: 10px;
                  border-radius: 4px;
              }
              .content {
                  padding: 20px;
              }
              .content p {
                font-size: 16px;
            }
          </style>
      </head>
      <body>
      <div style="background-color: #f4f4f4;">
        <div class="container" style="background-color: #f4f4f4; ">
              <img src="https://app.bufferloop.com/Bufferloop-Logo-login.png" width="150">
          </div>
          <div class="container">
              <div class="header" style="display: ${
                !vendor ? 'none' : 'block'
              }; " >
                  <img src="${logo}" style="background-color: white; padding: 10px;  height: 40px; object-fit: contain;" alt="Bufferloop">
              </div>
              <div class="content">
                  <p>You're all set, ${bodyMessage}! Congratulations on taking the first step towards your revenue growth.</p>
                  <p>When you're ready, you can log in to your account here and start exploring the platform marketplace.</p>
                  <div style="text-align: center; margin: 3rem 0; "><a href="${url}" style="background-color: #794DF5;color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;">Login To BufferLoop</a></div>
                  <p>We recommend checking out the marketplace products first, where you can apply for any product and make the most out of your new Bufferloop partner account.</p>
                  <p>For any further information simply head over to our Support Portal where you can:</p>
                  <ul>
                      <li>Find documentation on using the Bufferloop platform</li>
                      <li>Learn how to Apply for programs</li>
                      <li>Submit promotion requests</li>
                  </ul>
                  <p>For news, updates, events, and the occasional meme, follow us on social media.</p>
                  <p>So glad you're with us, ${bodyMessage} - we hope you love the platform!</p>
                  <p>Team Bufferloop</p>
              </div>
          </div>
          <div class="container" style="background-color: #f4f4f4;">
              <div class="footer">
                  <p style="font-size: .8rem;">You’re receiving this email because you are a partner of companies who use Bufferloop to manage their partner programs. If you suspect you have received a fraudulent email, please email us at care@bufferloop.com</p>
              </div>
          </div>
          </div>
      </body>
      </html>`,
    toIds: userEmail
  };
};
