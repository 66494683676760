import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from 'src/config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  /* if (window.location.hostname === 'localhost') {
    console.log('\x1b[35m%s', 'Running on Emmulator Setup');
    const auth = firebase.auth();
    auth.useEmulator('http://localhost:9099');
  } */
}

export default firebase;
