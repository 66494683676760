import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import SubscriptionsTwoToneIcon from '@mui/icons-material/SubscriptionsTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import RewardIcon from '@mui/icons-material/MonetizationOnTwoTone';
import MessagingIcon from '@mui/icons-material/ChatOutlined';
import GroupsIcon from '@mui/icons-material/GroupTwoTone';
import ProspectPagesIcon from '@mui/icons-material/StarOutline';
import { BarChartTwoTone, DashboardTwoTone } from '@mui/icons-material';
import NewspaperTwoToneIcon from '@mui/icons-material/NewspaperTwoTone';

const menuItems = [
  {
    // heading: 'Dashboards',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardTwoTone,
        link: '/vendor/dashboards'
      }
    ]
  },
  {
    heading: 'Partner Management',
    /* items: [
      {
        // name: 'Partner Management',
        // icon: AssignmentIndTwoToneIcon,
        // link: '/vendor', */
    items: [
      {
        name: 'Partners',
        icon: AssignmentIndTwoToneIcon,
        link: '/vendor/partners'
      },
      {
        name: 'Groups',
        icon: GroupsIcon,
        link: '/vendor/groups'
      },
      {
        name: 'Messaging',
        icon: MessagingIcon,
        link: '/vendor/messaging'
      }
    ]
    //   }
    // ]
  },
  {
    heading: 'Performance and Rewards',
    items: [
      {
        name: 'Pipeline',
        icon: AssignmentIndTwoToneIcon,
        link: '/vendor/pipeline'
      },
      {
        name: 'Analytics',
        icon: BarChartTwoTone,
        link: '/vendor/analytics'
        /* items: [
          {
            name: 'Dashboards',
            icon: HealthAndSafetyTwoToneIcon,
            link: '/accent-header/management/users',
          },
        ] */
      },
      {
        name: 'Referrals',
        icon: AssignmentIndTwoToneIcon,
        link: '/vendor/referrals'
      },
      {
        name: 'Rewards and Invoice',
        icon: RewardIcon,
        link: '/vendor/RewardsandInvoices'
      }
    ]
  },
  {
    heading: 'Engagement',
    items: [
      {
        name: 'News & Announcement',
        icon: NewspaperTwoToneIcon,
        link: '/vendor/news'
      },
      {
        name: 'Email Campaigns',
        icon: EmailTwoToneIcon,
        link: '/vendor/emailCampaign'
      },
      {
        name: 'Content Library',
        icon: LibraryBooksTwoToneIcon,
        link: '/vendor/content'
      },
      {
        name: 'Playbook',
        icon: SubscriptionsTwoToneIcon,
        link: '/vendor/playbook'
      },
      {
        name: 'Trainings',
        icon: SchoolTwoToneIcon,
        link: '/vendor/trainings'
      },
      {
        name: 'Prospect Pages',
        icon: ProspectPagesIcon,
        link: '/vendor/Prospect'
      }
    ]
  },
  {
    heading: 'Advance Settings',
    items: [
      {
        name: 'Triggers',
        icon: AutoAwesomeTwoToneIcon,
        link: '/vendor/triggers'
      },
      {
        name: 'Settings',
        icon: ReceiptTwoToneIcon,
        link: '/vendor/settings'
      }
    ]
  }
];

export default menuItems;
