import axios from 'axios';
import { apiConfig } from 'src/config';

export const getPartnerGroupForVendor = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'partner/api/v1/vendor/group/', payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
