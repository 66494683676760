import axios from 'axios';
import { apiConfig } from 'src/config';

export const inviteAdminUser = async (userReq) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'admin/api/v1/users/invite', userReq)
    .then((res) => {
      return res.data;
    });
};

export const updateAdminUser = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'admin/api/v1/users/update/', payload)
    .then((res) => {
      return res.data;
    });
};

export const fetchAllAdminUsers = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'admin/api/v1/users/fetch/all/')
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const signupwithGoogle = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'admin/api/v1/google/signup', payload)
    .then((res) => {
      return res.data;
    });
};

