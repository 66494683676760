import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const StripeSubscriptions = Loader(
  lazy(() => import('src/content/bufferloop/public/subscriptions'))
);
const StripeSubscriptionsSuccess = Loader(
  lazy(() => import('src/content/bufferloop/public/subscriptionsSuccess'))
);
const customerRoutes = [
  {
    path: '/customer',
    element: <StripeSubscriptions />
  },
  {
    path: '/customer/success',
    element: <StripeSubscriptionsSuccess />
  }
];

export default customerRoutes;
