import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
// import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
// import _ from 'lodash';
import { useParams } from 'react-router';
import axios from 'axios';
import { apiConfig } from 'src/config';
import { getSinglePlaybook } from 'src/services/public/playbookService';
import PlaybookContents from './PlaybookContents';

function PlaybookForContent() {
  console.log("ContentManagement")
  const [playbookPreview, setPlaybookPreview] = useState();
  // const [contents, setContents] = useState([]);
  const [playbookContents, setPlaybookContents] = useState([]);

  const [ready, setReady] = useState(true);
  const showToast = useSnackbar();
  const { playbookId } = useParams();

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const getPlaybookPreview = async (temp) => {
    setReady(false);
    const payload = {
      playbook_id: playbookId
    };
    console.log('payload.....', payload);
    await getSinglePlaybook(payload)
      .then((playbookPreview) => {
        let data = _arrayBufferToBase64(playbookPreview?.logo?.data);
        playbookPreview.logo = data;

        setPlaybookPreview(playbookPreview);
        console.log('playbookPreview', playbookPreview, temp);
        let tempContents = temp?.filter((contentElement) => {
          console.log('contentElement', contentElement);
          return playbookPreview?.contentIds?.includes(`${contentElement.id}`);
        });

        tempContents = tempContents?.map((c) => {
          let data = _arrayBufferToBase64(c?.logo?.data);
          c.logo = data;
          return c;
        });
        console.log('tempContents', tempContents);
        setPlaybookContents([...tempContents]);
        setReady(true);
      })
      .catch((err) => {
        showToast.enqueueSnackbar('error reading playbook', {
          variant: 'error'
        });
        console.error(err);
        setReady(true);
      });
  };

  useEffect(async () => {
    try {
      const contentRespone = await axios.get(
        apiConfig.APP_BASE_URL +
          'public/api/v1/playbook/content/fetch/' +
          playbookId
      );
      let temp = contentRespone?.data;
      setPlaybookContents([...temp]);
      getPlaybookPreview(temp);
    } catch (err) {
      console.error(err);
    }
  }, [playbookId]);

  return (
    <>
      <Helmet>
        {/* <title>Content - Preview</title> */}
        <title>
          {`${
            playbookPreview?.title ? playbookPreview?.title : 'Playbook Details'
          }`}
        </title>
      </Helmet>
      {/* <PageTitleWrapper> */}
      <Grid container display={'none'}>
        <Grid item textAlign={'center'} md={12}>
          <Typography variant="h3" component="h3" gutterBottom>
            {'Playbook'}
          </Typography>
        </Grid>
      </Grid>
      {/* </PageTitleWrapper> */}
      {ready && (
        <>
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <PlaybookContents
                playbookContents={playbookContents}
                playbookPreview={playbookPreview}
              />
            </Grid>
          </Grid>
        </>
      )}
      {!ready && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Footer />
    </>
  );
}

export default PlaybookForContent;
