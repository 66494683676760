import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  alpha,
  styled,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Tooltip,
  DialogContent,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
import OffersDialog from './OffersDialog';
import InfoDialog from './InfoDialog';
import { FilterAltOffSharp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { getOnboardingForm } from 'src/services/public/marketPlaceService';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import validator from 'validator';
import axios from 'axios';
import {
  apiConfig,
  emailContentTemplate
  // joinRequestEmailConfig,
  // sendRequestEmailConfig
} from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { saveOnboardingFormValues } from 'src/services/vendor/groupService';
import { fetchVendorProfileById } from 'src/services/public/profileService';
import { sendEmail } from 'src/services/email/emailService';
import { useNavigate } from 'react-router';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: auto;
        box-shadow: none;
      }
`
);

const TableWrapper = styled(Table)(
  ({ theme }) => `

    thead tr th {
        border: 0;
    }

    tbody tr td {
        position: relative;
        border: 0;

        & > div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            transition: ${theme.transitions.create(['all'])};
            height: 100%;
            width: 100%;
            content: "";
            background: ${theme.colors.alpha.white[100]};
            border-top: 1px solid ${theme.colors.alpha.black[10]};
            border-bottom: 1px solid ${theme.colors.alpha.black[10]};
            pointer-events: none;
            z-index: 4;
        }

        &:first-of-type:before {
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
            border-left: 1px solid ${theme.colors.alpha.black[10]};
        }
        

        &:last-child:before {
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
            border-right: 1px solid ${theme.colors.alpha.black[10]};
        }
    }

    tbody tr:hover td::before {
        background: ${alpha(theme.colors.primary.main, 0.02)};
        border-color: ${alpha(theme.colors.alpha.black[100], 0.25)} !important;
    }

  `
);

const TableRowDivider = styled(TableRow)(
  ({ theme }) => `
    height: ${theme.spacing(2)};
  `
);

const LabelAction = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    color: ${theme.colors.success.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(15)};
    font-weight: bold;
    border-radius: ${theme.general.borderRadiusSm};
  `
);
const LabelInfo = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.info.lighter};
    color: ${theme.colors.info.dark};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(15)};
    font-weight: normal;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const TableHeadWrapper = styled(TableHead)(
  ({ theme }) => `
      .MuiTableCell-root {
          text-transform: none;
          font-weight: normal;
          color: ${theme.colors.alpha.black[100]};
          font-size: ${theme.typography.pxToRem(16)};
          padding: ${theme.spacing(2)};
      }

      .MuiTableRow-root {
          background: transparent;
      }
  `
);
const MarketPlacePrograms = ({ programs, vendorCategories }) => {
  console.log('programs', programs);
  const applyFilters = (marketplaceFilter, query) => {
    return marketplaceFilter?.filter((filterData) => {
      let matches = true;

      if (query) {
        const properties = ['business_name'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (
            filterData[property].toLowerCase().includes(query.toLowerCase())
          ) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }
      if (selectedCategory && selectedCategory !== 'ALL') {
        const properties = ['vendorCategoryId'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (filterData[property] === selectedCategory + '') {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    });
  };

  const applyPagination = (marketplacePagination, page, limit) => {
    return marketplacePagination.slice(page * limit, page * limit + limit);
  };
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [openOfferDetailsDialog, setOpenOfferDetailsDialog] = useState(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [selectedOfferList, setSelectedOfferList] = useState();
  const [selectedDescription, setSelectedDescription] = useState();
  const [openAddPartnerDialog, setOpenAddPartnerDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    terms: false
  });
  const [selectedProgram, setSelectedProgram] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { logout, signInWithEmailAndPassword } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClearFilter = () => {
    setQuery('');
    setSelectedCategory('ALL');
    setSelectedCategory('');
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const closeOfferDetailsList = () => {
    setOpenOfferDetailsDialog(false);
  };

  const handleOfferDetailsDialogOpen = () => {
    setOpenOfferDetailsDialog(true);
  };
  const closeInfoDialog = () => {
    setOpenDescriptionDialog(false);
  };

  const handleInfoDialogOpen = () => {
    setOpenDescriptionDialog(true);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const filteredMarketplace = applyFilters(programs, query);
  const paginatedPartnerMarketplace = applyPagination(
    filteredMarketplace,
    page,
    limit
  );

  const fetchOnboardingForm = (groupId) => {
    getOnboardingForm(groupId)
      .then((onboardingForm) => {
        console.log('onboardingForm', onboardingForm);
        if (
          onboardingForm &&
          onboardingForm !== '' &&
          onboardingForm !== null &&
          onboardingForm !== undefined
        ) {
          if (onboardingForm?.isEnabled === true) {
            let link = `${process.env.REACT_APP_BASE_UI_URL}/onboarding/${groupId}`;
            window.open(link, '_blank');
          } else {
            setSelectedProgram(onboardingForm);
            enqueueSnackbar('Join request not enabled', {
              variant: 'warning'
            });
            setOpenAddPartnerDialog(true);
          }
        } else {
          enqueueSnackbar('Error Sending Join Request', {
            variant: 'error'
          });
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        // setLoading(false);
      });
  };

  const imgBox = {
    height: '40px',
    width: '80px',
    borderRadius: '0.5',
    objectFit: 'contain'
  };

  const handleSignupModel = () => {
    setOpenAddPartnerDialog(false);
  };

  const handleInputChange = (e) => {
    let formDataObj = { ...formData };
    formDataObj[e.target.name] = e.target.value;
    setFormData(formDataObj);
  };
  console.log('formData', formData);
  const validateForm = () => {
    let status = true;
    if (_.isEmpty(formData?.name?.trim())) {
      enqueueSnackbar('Please enter name', {
        variant: 'error'
      });
      status = false;
    }
    if (_.isEmpty(formData?.email?.trim())) {
      enqueueSnackbar('Please enter email address', {
        variant: 'error'
      });
      status = false;
    } else if (!validator.isEmail(formData?.email)) {
      enqueueSnackbar('Please enter valid email address', {
        variant: 'error'
      });
      status = false;
    }

    if (formData?.terms !== true) {
      enqueueSnackbar('Please accept terms and conditions', {
        variant: 'error'
      });
      status = false;
    }

    return status;
  };

  const submitSignupForm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      let payload = {
        email: formData.email,
        password: formData.password,
        name: formData.name,
        group_id: selectedProgram.group_id,
        groupId: selectedProgram.groupId
        // website: partnerWebsite
      };
      console.log('payload', payload);

      await axios
        .post(
          apiConfig.APP_BASE_URL + 'partner/public/api/v1/add/partner',
          payload
        )
        .then(async () => {
          await signInWithEmailAndPassword(
            formData.email,
            formData.password
          ).then(async () => {
            /* userCreds.user
          .sendEmailVerification()
          .then(function (res) {
            console.log(
              'send verification email call res',
              res
            );
          }); */
            // createPartner;
            enqueueSnackbar('User is now registered, Saving details...', {
              variant: 'success'
            });
            logout();
            let payload = {
              form_id: selectedProgram?.form_id,
              group_id: selectedProgram?.group_id,
              groupId: selectedProgram?.groupId,
              partnerName: formData?.name,
              partnerEmail: formData?.email,
              customFieldValuesMap: [],
              vendorId: selectedProgram?.vendorId
            };
            let success = await saveOnboardingFormValues(payload).catch(
              (error) => {
                if (error?.response?.data) {
                  if (error?.response?.data?.includes('partnerEmail')) {
                    enqueueSnackbar(
                      'Partner with this email already exists in the system',
                      { variant: 'error' }
                    );
                  } else {
                    enqueueSnackbar(error?.response?.data, {
                      variant: 'error'
                    });
                  }
                } else {
                  enqueueSnackbar('Error saving partner data', {
                    variant: 'error'
                  });
                }
              }
            );
            if (success) {
              enqueueSnackbar('Partner details submitted successfully', {
                variant: 'success'
              });
              await fetchVendorProfileById(selectedProgram.vendorId).then(
                (res) => {
                  let vendorProfile = res;
                  if (vendorProfile?.id) {
                    const vendorLogo = `${process.env.REACT_APP_BASE_API_URL}public/api/v1/vendor/image/${selectedProgram.vendorId}/`;
                    // let joinRequestEmailPayload = {
                    //   subject: `${joinRequestEmailConfig.subject} ${formData?.name}`,
                    //   messageBody: `${joinRequestEmailConfig.messageBody}`,
                    //   toIds: [vendorProfile.email]
                    // };

                    const subjectJoin = `You have New Partner Request`;
                    const messageJoin = `<h1>Hey ${vendorProfile?.business_name},</h1>
                      <p>You have a new partner request. Login to the dashboard to see more details.</p>
                      <p>Act on partner request to approve or decline.</p>`;
                    const userLogoJoin =
                      vendorLogo ||
                      `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
                    const urlLinkJoin = process.env.REACT_APP_UI_PORTAL_URL;
                    const joinRequestEmailPayload = emailContentTemplate(
                      subjectJoin,
                      messageJoin,
                      userLogoJoin,
                      urlLinkJoin,
                      [vendorProfile.email]
                    );

                    // let sendRequestEmailPayload = {
                    //   subject: `${sendRequestEmailConfig.subject} ${vendorProfile.business_name}`,
                    //   messageBody: `${sendRequestEmailConfig.messageBody}`,
                    //   toIds: [formData?.email]
                    // };

                    const subjectReq = `Request for joining partner program`;
                    const messageReq = `<p>You have applied for the ${vendorProfile?.business_name} partner  program.</p>
                      <p>Depending on your inputs, merchant will proceed for approval.</p>`;
                    const userLogoReq =
                      vendorLogo ||
                      `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
                    const urlLinkReq = process.env.REACT_APP_UI_PORTAL_URL;
                    const sendRequestEmailPayload = emailContentTemplate(
                      subjectReq,
                      messageReq,
                      userLogoReq,
                      urlLinkReq,
                      [formData?.email],
                      true
                    );

                    sendEmail(joinRequestEmailPayload).catch((error) => {
                      console.log(
                        'Error sending join partner request email',
                        error
                      );
                    });
                    sendEmail(sendRequestEmailPayload).catch((error) => {
                      console.log(
                        'Error sending join partner request email',
                        error
                      );
                    });
                  }
                }
              );
            }
            setIsSubmitting(false);
            handleSignupModel();
            navigate('/partner');
          });
        })
        .catch((err) => {
          console.log(err.code);
          if (err.code === 'auth/email-already-in-use') {
            enqueueSnackbar('This email already exists in the system', {
              variant: 'error'
            });
          } else {
            enqueueSnackbar('Error in signup', {
              variant: 'error'
            });
          }
          setOpenAddPartnerDialog(false);
        });
    }
  };

  return (
    <>
      <Card className="search_padding search_bottom_space">
        <Grid
          container
          spacing={2}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{
                m: 0
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder={t('Search by name')}
              value={query}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('Category*')}
              select
              value={selectedCategory}
              fullWidth
              onChange={(event) => {
                setSelectedCategory(event?.target?.value);
              }}
            >
              <MenuItem key={'ALL'} value={'ALL'}>
                {'ALL'}
              </MenuItem>
              {vendorCategories &&
                vendorCategories?.map((cat) => {
                  return (
                    <MenuItem key={cat?.id} value={cat?.id}>
                      {cat?.title}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <IconButton
              aria-label="delete"
              title="Clear Filter"
              onClick={handleClearFilter}
            >
              <FilterAltOffSharp sx={{ mt: 0.5, mr: 0.5 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>

      <Card>
        {paginatedPartnerMarketplace?.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t('No Partners Program')}
            </Typography>
          </>
        ) : (
          <Box className="content_block_space">
            <TableContainer>
              <TableWrapper>
                <TableHeadWrapper>
                  <TableRow>
                    {/* <TableCell>{t('Order')}</TableCell> */}
                    <TableCell align="left">{t('Vendors')}</TableCell>
                    <TableCell align="center">{t('Info')}</TableCell>
                    <TableCell align="center">{t('Offers')}</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHeadWrapper>
                <TableBody>
                  {paginatedPartnerMarketplace?.map((partnerProgram) => (
                    <>
                      <TableRow hover>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {partnerProgram?.companyProfile?.logo && (
                              <img
                                alt=""
                                variant="square"
                                style={imgBox}
                                // src="/static/images/placeholders/products/1.png"
                                src={`data:image;base64, ${partnerProgram?.companyProfile?.logo}`}
                              />
                            )}
                            {!partnerProgram?.companyProfile?.logo && (
                              <img
                                alt=""
                                variant="square"
                                style={imgBox}
                                src="/static/images/placeholders/content_image_blank.jpg"
                              />
                            )}
                            <Box pl={1}>
                              <Typography
                                variant="button"
                                noWrap
                                className="nameEllips"
                              >
                                {partnerProgram?.business_name}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <LabelInfo
                              role={'button'}
                              onClick={() => {
                                setSelectedDescription(
                                  partnerProgram?.companyProfile?.description
                                );
                                handleInfoDialogOpen();
                              }}
                            >
                              {'More Info'}
                            </LabelInfo>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <LabelInfo
                              role={'button'}
                              onClick={() => {
                                setSelectedOfferList(partnerProgram);
                                handleOfferDetailsDialogOpen(partnerProgram);
                              }}
                            >
                              {'See Offers'}
                            </LabelInfo>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <LabelAction>
                            <Button
                              variant="contained"
                              onClick={() => {
                                fetchOnboardingForm(partnerProgram?.group_id);
                              }}
                              endIcon={<CheckOutlinedIcon />}
                            >
                              Join Program
                            </Button>
                          </LabelAction>
                        </TableCell>
                      </TableRow>
                      <TableRowDivider />
                    </>
                  ))}
                </TableBody>
              </TableWrapper>
            </TableContainer>
            <Box className="table_pagination_spacing">
              <TablePagination
                component="div"
                count={filteredMarketplace?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </Box>
        )}
      </Card>
      <OffersDialog
        openOfferDetailsDialog={openOfferDetailsDialog}
        maxWidth="sm"
        fullWidth
        setOpenOfferDetailsDialog={setOpenOfferDetailsDialog}
        keepMounted
        selectedOfferList={selectedOfferList}
        closeOfferDetailsList={closeOfferDetailsList}
      />
      <InfoDialog
        openDescriptionDialog={openDescriptionDialog}
        maxWidth="sm"
        fullWidth
        setOpenDescriptionDialog={setOpenDescriptionDialog}
        keepMounted
        setSelectedDescription={setSelectedDescription}
        description={selectedDescription}
        closeOfferDetailsList={closeInfoDialog}
      />

      {/* sign up model */}
      <DialogWrapper
        maxWidth="sm"
        open={openAddPartnerDialog}
        onClose={handleSignupModel}
      >
        <DialogTitle
          align="center"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          disableTypography
        >
          <Typography variant="h4">{'Sign Up'}</Typography>
          <Tooltip arrow placement="top-end" title={'Close'}>
            <IconButton
              color="primary"
              sx={{
                mx: 1,
                alignContent: 'end'
              }}
              onClick={handleSignupModel}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <>
            <form onSubmit={submitSignupForm}>
              <TextField
                fullWidth
                label={'Name'}
                placeholder={'Your Name here...'}
                margin="normal"
                name="name"
                onChange={handleInputChange}
                value={formData.name}
                variant="outlined"
              />
              <TextField
                fullWidth
                label={'Email address'}
                placeholder={'Your email address here...'}
                margin="normal"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
                variant="outlined"
              />
              <TextField
                fullWidth
                label={'Password'}
                margin="normal"
                name="password"
                onChange={handleInputChange}
                type="password"
                value={formData.password}
                variant="outlined"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.terms}
                    name="terms"
                    color="primary"
                    onChange={(e) => {
                      let formDataObj = { ...formData };
                      formDataObj.terms = e.target.checked;
                      setFormData(formDataObj);
                    }}
                  />
                }
                label={
                  <Typography variant="body2">
                    {'I accept the'}{' '}
                    <a target="_blank" component="a" href="#">
                      {'terms and conditions'}
                    </a>
                    .
                  </Typography>
                }
              />
              <Button
                sx={{
                  mt: 3
                }}
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                size="medium"
                fullWidth
                type="submit"
                variant="contained"
                color="success"
              >
                {'Create account'}
              </Button>
            </form>
          </>
        </DialogContent>
      </DialogWrapper>
    </>
  );
};

MarketPlacePrograms.propTypes = {
  programs: PropTypes.array.isRequired
};

MarketPlacePrograms.defaultProps = {
  programs: []
};

export default MarketPlacePrograms;
