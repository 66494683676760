exports.contentURL = '/bufferloop/content/:contentId';
exports.contentURLPath = '/bufferloop/content/';
exports.playbookURL = '/bufferloop/playbook/:playbookId';
exports.playbookURLPath = '/bufferloop/playbook/';
exports.onboardingURL = '/bufferloop/onboarding/:groupId';
exports.onboardingURLPath = '/bufferloop/onboarding/';
exports.prospectPageURLPath = '/bufferloop/prospect/page/:prospectPageId';
exports.customerInformationpage = '/bufferloop/customer/information';
exports.demoCustomerInformationForm = '/bufferloop/demo/customer/information';
exports.appSumoCustomerInformationForm =
  '/bufferloop/appsumo/customer/information';
exports.marketPlacePage = '/marketplace';
exports.stripeConnectRefreshPage = '/stripe/connect/:partnerId';
// exports.subscriptionsPage = '/bufferloop/subscriptions';
// exports.customerSuccessPage = '/customer/subscription/success';
