import { createContext, useEffect, useReducer } from 'react';
import firebase from 'src/utils/firebase';
import PropTypes from 'prop-types';
import axios from 'axios';
import { apiConfig } from 'src/config';
import { getSingleTeamForVendor } from 'src/services/vendor/settingsService';
import _ from 'lodash';
import { getSingleTeamForPartner } from 'src/services/partner/settingsService';
import {
  setProfileTeams,
  setSelectedProfile
} from 'src/slices/profile-teams-reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchActiveVendorSubscriptions } from 'src/services/vendor/profileService';
import { getSingleTeamForAdmin } from 'src/services/admin/teamService';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  vendorGroupsMap: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
  if (action.type === 'LOAD_VENDOR_GROUPS') {
    const { vendorGroupsMap } = action.payload;

    return {
      ...state,
      vendorGroupsMap: vendorGroupsMap
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  sendResetPasswordLink: () => Promise.resolve(),
  fetchSignInMethodsForEmail: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  sendEmailVerificationLink: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  onAuthStateChanged: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  saveVendorGroupsMap: () => Promise.resolve(),
  profileSwitch: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatch2 = useDispatch();
  const selectedProfile = useSelector(
    (state) => state.profileTeams.selectedProfile
  );
  const getRoleFromUser = async (user) => {
    const idTokenResult = await user?.getIdTokenResult();
    let claims = idTokenResult?.claims;
    if (
      claims &&
      (claims.role === 'admin' ||
        claims.role === 'superAdmin' ||
        claims.role === 'vendor' ||
        claims.role === 'customer' ||
        claims.role === 'partner')
    ) {
      return idTokenResult;
    }
    return undefined;
  };
  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          getRoleFromUser(user)
            .then(async (tokenResult) => {
              let payload = {
                userEmail: user?.email,
                role: tokenResult?.claims?.role
              };
              // Setting up Auth token
              axios.interceptors.request.use(
                async (config) => {
                  // config.headers['token'] = tokenResult.token;
                  let currentToken = await firebase
                    .auth()
                    .currentUser?.getIdToken(false);
                  // console.log('currentToken', currentToken);
                  config.headers['token'] = currentToken;
                  return config;
                },
                (error) => {
                  Promise.reject(error);
                }
              );
              // console.log('firebase user', user, tokenResult);
              const loggedInUserProfileData = await axios.post(
                apiConfig.APP_BASE_URL + 'general/api/v1/fetch/profile',
                payload
              );
              // console.log('loggedInUserProfileData', loggedInUserProfileData);
              let loggedInUserProfile = loggedInUserProfileData?.data;
              let allowedMenus = [];

              axios.interceptors.response.use(
                (r) => {
                  return r;
                },
                (response) => {
                  if (
                    response?.response?.status === 401 &&
                    response.config?.url?.includes('bufferloop')
                  ) {
                    firebase.auth().signOut();
                    dispatch({
                      type: 'AUTH_STATE_CHANGED',
                      payload: {
                        isAuthenticated: false,
                        user: null
                      }
                    });
                    dispatch2(
                      setProfileTeams({
                        profileTeams: []
                      })
                    );
                    dispatch2(
                      setSelectedProfile({
                        selectedProfile: undefined
                      })
                    );
                  }
                }
              );

              // If no profile is selected, use default one
              if (!selectedProfile) {
                let defaultProfile = {
                  name: 'My',
                  partnerId: loggedInUserProfile.id,
                  invitedPartnerId: loggedInUserProfile.id,
                  allowedMenus: `["ALL"]`
                };
                dispatch2(
                  setProfileTeams({
                    profileTeams: []
                  })
                );
                dispatch2(
                  setSelectedProfile({
                    selectedProfile: defaultProfile
                  })
                );
              }

              if (loggedInUserProfile?.teamId) {
                let teamId = parseInt(loggedInUserProfile?.teamId);
                if (tokenResult?.claims?.role === 'vendor') {
                  let team = await getSingleTeamForVendor(
                    loggedInUserProfile.id,
                    teamId
                  ).catch((error) => {
                    console.log(error);
                  });
                  if (team?.allowedMenus) {
                    let menus = JSON.parse(team?.allowedMenus);
                    allowedMenus = _.concat(allowedMenus, menus);
                  }
                } else if (tokenResult?.claims?.role === 'partner') {
                  let team = await getSingleTeamForPartner(
                    loggedInUserProfile.id,
                    teamId
                  ).catch((error) => {
                    console.log(error);
                  });
                  if (team?.allowedMenus) {
                    let menus = JSON.parse(team?.allowedMenus);
                    allowedMenus = _.concat(allowedMenus, menus);
                  }
                } else if (tokenResult?.claims?.role === 'admin') {
                  let team = await getSingleTeamForAdmin(teamId).catch(
                    (error) => {
                      console.log(error);
                    }
                  );
                  if (team?.allowedMenus) {
                    let menus = JSON.parse(team?.allowedMenus);
                    allowedMenus = _.concat(allowedMenus, menus);
                  }
                }
              } else {
                allowedMenus.push('ALL');
              }

              // Check and update stripe status
              let subscribed = false;
              if (tokenResult?.claims?.role === 'vendor') {
                if (loggedInUserProfile?.subscriptionType === 'appSumo') {
                  subscribed = true;
                  // TODO: Additional handling would be needed in case app sumo subscription
                  // are time bound.
                } else if (loggedInUserProfile?.stripeCustomerId) {
                  await fetchActiveVendorSubscriptions(
                    loggedInUserProfile?.stripeCustomerId
                  )
                    .then((data) => {
                      if (data?.data?.length > 0) {
                        let subscritions = [...data.data];
                        // setSubscriptions([...temp]);
                        subscritions?.forEach((sub) => {
                          if (sub?.status?.toLowerCase() === 'active') {
                            subscribed = true;
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }

              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: true,
                  user: {
                    id: loggedInUserProfile.id,
                    loggedInUserProfileId: loggedInUserProfile.id,
                    avatar: user.photoURL,
                    email: loggedInUserProfile.email,
                    stripeCustomerId: loggedInUserProfile?.stripeCustomerId,
                    // name: loggedInUserProfile.business_name || user.email,
                    planType: loggedInUserProfile?.planType,
                    name: user?.displayName,
                    subscribed: subscribed,
                    // groupId: loggedInUserProfile?.groupId,
                    role: tokenResult?.claims?.role,
                    token: tokenResult?.token,
                    teamId: loggedInUserProfile?.teamId,
                    allowedMenus: allowedMenus,
                    vendor_domain: loggedInUserProfile?.vendor_domain,
                    vendor_tracking_domain:
                      loggedInUserProfile?.vendor_tracking_domain,
                    platFormFee: parseInt(
                      loggedInUserProfile?.platFormFee || 5
                    ),
                    plan: loggedInUserProfile?.currentPlan?.toLowerCase(),
                    subscriptionType: loggedInUserProfile?.subscriptionType
                  }
                }
              });
            })
            .catch((error) => {
              console.log(error);
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: false,
                  user: null
                }
              });
              dispatch2(
                setProfileTeams({
                  profileTeams: []
                })
              );
              dispatch2(
                setSelectedProfile({
                  selectedProfile: undefined
                })
              );
            });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          dispatch2(
            setProfileTeams({
              profileTeams: []
            })
          );
          dispatch2(
            setSelectedProfile({
              selectedProfile: undefined
            })
          );
        }
      }),
    [dispatch]
  );

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const sendEmailVerificationLink = () => {
    return firebase.auth().currentUser.sendEmailVerification();
  };

  const saveVendorGroupsMap = (vendorGroupsMap) => {
    dispatch({
      type: 'LOAD_VENDOR_GROUPS',
      payload: {
        vendorGroupsMap: vendorGroupsMap
      }
    });
  };

  const profileSwitch = (profile) => {
    if (profile?.name === 'My') {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            ...state?.user,
            id: profile.invitedPartnerId,
            allowedMenus: profile?.allowedMenus
          }
        }
      });
    } else {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            ...state?.user,
            id: profile.partnerId,
            allowedMenus: profile?.allowedMenus
          }
        }
      });
    }
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const sendResetPasswordLink = (email) => {
    // const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const fetchSignInMethodsForEmail = async (email) => {
    return firebase.auth().fetchSignInMethodsForEmail(email);
  };

  /*   const signinWith = async (email) => {
    return firebase.auth().signInWithCustomToken(email);
  }; */
  const logout = () => {
    return firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        sendResetPasswordLink,
        signInWithEmailAndPassword,
        sendEmailVerificationLink,
        fetchSignInMethodsForEmail,
        signInWithGoogle,
        logout,
        saveVendorGroupsMap,
        profileSwitch
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
