import { Box, Grid } from '@mui/material';
import Lottie from 'lottie-react';
import loadingAnim from '../../static/loading1.json';

const LoadingAnimation = ({ large }) => (
  <>
    {large && (
      <Grid container>
        <Grid item md={4} />
        <Grid item md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60vh'
            }}
          >
            <Lottie animationData={loadingAnim} />
          </Box>
        </Grid>
        <Grid item md={4} />
      </Grid>
    )}
    {!large && (
      <Grid container>
        <Grid item md={5} />
        <Grid item md={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60vh'
            }}
          >
            <Lottie animationData={loadingAnim} />;
          </Box>
        </Grid>
        <Grid item md={5} />
      </Grid>
    )}
  </>
);
export default LoadingAnimation;
