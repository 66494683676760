import { forwardRef, useContext, useEffect, useState } from 'react';

import {
  Box,
  Card,
  IconButton,
  Tooltip,
  alpha,
  styled,
  Typography,
  Badge,
  Button,
  Dialog,
  Slide
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
// import HeaderSearch from './Search';
// import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import {
  NotificationsActiveOutlined,
  NotificationsActiveSharp
} from '@mui/icons-material';
import VendorNotifications from './VendorNotifications';
import {
  fetchAllNotifications,
  markNotificationsAsRead
} from 'src/services/vendor/notificationService';

import Logo from './Logo';
import useAuth from 'src/hooks/useAuth';
import { fetchVendorProfileById } from 'src/services/vendor/profileService';
import { apiConfig, emailContentTemplate } from 'src/config';
import { useSnackbar } from 'notistack';
import {
  createSubscribe,
  getCheckDemoUserSubscribed
} from 'src/services/demo/demoService';
import { sendEmail } from 'src/services/email/emailService';
import { _arrayBufferToBase64 } from 'src/utils/functions';
import _ from 'lodash';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useTheme } from '@emotion/react';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    // background-color: ${alpha(theme.colors.primary.main, 0.95)};
    background-color: #fff;
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    // &.active,
    // &:active,
    // &:hover {
    //   background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
    //   color: ${theme.colors.alpha.trueWhite[100]};
    // }
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// const AvatarSuccess = styled(Avatar)(
//   ({ theme }) => `
//       background-color: ${theme.colors.success.lighter};
//       color: ${theme.colors.error.main};
//       width: ${theme.spacing(12)};
//       height: ${theme.spacing(12)};

//       .MuiSvgIcon-root {
//         font-size: ${theme.typography.pxToRem(45)};
//       }
// `
// );

function Header({ vendorCompanyProfile, vendorImage }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState(false);
  const { user } = useAuth();
  const [subscriptionModel, setSubscriptionModel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [submitSubscribeBtnDisable, setSubmitSubscribeBtnDisable] =
    useState(false);
  const [userSubscribed, setUserSubscribed] = useState(false);
  const [subscribeBoxShow, setSubscribeBoxShow] = useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setOpenNotifications(!openNotifications);
    if (user?.id) {
      markNotificationsAsRead(user?.id).then(() => {});
      setNewNotification(false);
    }
  };
  useEffect(async () => {
    fetchAllNotifications().then((notificaionsRes) => {
      if (notificaionsRes?.length > 0) {
        let notificationsResponseData = [...notificaionsRes];

        notificationsResponseData = _.orderBy(
          notificationsResponseData,
          'createdAt',
          'desc'
        );
        console.log(notificationsResponseData);
        let temp = notificationsResponseData?.map((notification) => {
          if (notification?.image?.data) {
            let testData = _arrayBufferToBase64(notification?.image?.data);
            notification.image = testData;
          }
          return notification;
        });
        setNotifications([...temp]);
      } else {
        setNotifications([]);
      }
    });
  }, []);

  const checkNotificationStatus = () => {
    if (user?.id) {
      fetchVendorProfileById(user?.id).then((profile) => {
        if (
          profile?.notificationsRead !== undefined &&
          profile?.notificationsRead === false
        ) {
          setNewNotification(true);
        }
      });
    }
  };
  useEffect(() => {
    checkNotificationStatus();
    let messageInterval = setInterval(() => checkNotificationStatus(), 30000);
    return () => clearInterval(messageInterval);
  }, [checkNotificationStatus]);

  const handleSubscriptionClose = () => {
    setSubscriptionModel(false);
  };

  const handleSubscription = () => {
    console.log('yes');
    const userEmail = user?.email || '';
    console.log('userEmail', userEmail);
    const payload = {
      email: userEmail
    };

    setSubmitSubscribeBtnDisable(true);
    createSubscribe(payload)
      .then(async (resp) => {
        setSubmitSubscribeBtnDisable(false);
        setSubscriptionModel(false);
        if (resp?.code === 200) {
          checkUserSubscribed();
          enqueueSnackbar('Subscription Requested Successfully', {
            variant: 'success'
          });
          enqueueSnackbar('Generating Login credentials', {
            variant: 'info'
          });

          const subjectCred = `Important and confidential : Your Bufferloop Account details`;
          const messageCred = `<h1>Hello,</h1><p>Welcome to Bufferloop, a secure email service.</p><p>We have created an account for you in Bufferloop. You can sign in to your account using the following credentials:</p> <ul><li>Username - ${userEmail}</li><li>Password - 123456</li><li>Login URL- <a href="${process.env.REACT_APP_PROD_UI_PORTAL_URL}" >${process.env.REACT_APP_PROD_UI_PORTAL_URL}</a></li></u><p>For any trouble with account  log in, please reach out to support.</p>`;
          const urlLinkCred = process.env.REACT_APP_PROD_UI_PORTAL_URL;
          const userLogoCred = `${process.env.REACT_APP_BASE_API_URL}public/api/v1/vendor/image/${user.id}/`;

          const customerLoginCredsEmailPayload = emailContentTemplate(
            subjectCred,
            messageCred,
            userLogoCred,
            urlLinkCred,
            [userEmail]
          );

          await sendEmail(customerLoginCredsEmailPayload)
            .then(() => {
              enqueueSnackbar(
                'Login Credentials generated and email sent to the user',
                { variant: 'success' }
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setSubscriptionModel(false);
        setSubmitSubscribeBtnDisable(false);
        enqueueSnackbar('Something went wrong. Please try again later.', {
          variant: 'error'
        });
        console.log(error);
      });
  };

  const checkUserSubscribed = () => {
    if (apiConfig.ENVIRONMENT === 'demo') {
      const userEmail = user?.email || '';
      const payloadForSubscriptionCheck = {
        email: userEmail
      };
      getCheckDemoUserSubscribed(payloadForSubscriptionCheck)
        .then((resp) => {
          setSubscribeBoxShow(true);
          if (resp?.subscribed) {
            setUserSubscribed(true);
          } else {
            setUserSubscribed(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    checkUserSubscribed();
  }, [user]);

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        {<Logo logo={vendorImage} />}
        <Box
          display="flex"
          ml={1}
          flexDirection={'column'}
          alignItems="flex-start"
        >
          <Typography variant="h3" color={'#1179c4f2'}>
            {user?.name || ''}
          </Typography>
          <Typography variant="h4" color={'#1179c4f2'}>
            {'Vendor Portal'}
          </Typography>
          <Typography sx={{ display: 'none' }} variant="h4" color={'#1179c4f2'}>
            {vendorCompanyProfile?.name}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        {subscribeBoxShow ? (
          <>
            {userSubscribed ? (
              <>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  aria-haspopup="true"
                  disabled
                  sx={{ mr: 1 }}
                >
                  Subscription Requested
                </Button>
              </>
            ) : apiConfig.ENVIRONMENT === 'demo' ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: theme.colors.alpha.black[100],
                  py: 1.5,
                  px: 3,
                  borderRadius: '3px'
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: 'white', fontWeight: 600 }}
                >
                  Subscribe To Get Real Account!
                </Typography>
                <Button
                  size="medium"
                  variant="contained"
                  color="success"
                  className="animated-bounce"
                  aria-haspopup="true"
                  disabled={submitSubscribeBtnDisable}
                  sx={{ backgroundColor: '#e3723e', mr: 1 }}
                  onClick={() => {
                    setSubscriptionModel(true);
                  }}
                  startIcon={<SubscriptionsIcon />}
                >
                  Subscribe
                </Button>
              </Box>
            ) : null}
          </>
        ) : null}

        <Box mr={1}>
          {(!newNotification || notifications?.length === 0) && (
            <Tooltip arrow title="Open Notifications">
              <IconButtonPrimary color="primary" onClick={handleDrawerToggle}>
                <NotificationsActiveOutlined
                  sx={{ color: '#1179c4f2' }}
                  pulse
                />
              </IconButtonPrimary>
            </Tooltip>
          )}
          {newNotification && notifications?.length > 0 && (
            <IconButtonPrimary color="primary" onClick={handleDrawerToggle}>
              <NotificationsBadge
                sx={{ color: '#1179c4f2' }}
                badgeContent={'NEW'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <NotificationsActiveSharp pulse />
              </NotificationsBadge>
            </IconButtonPrimary>
          )}
        </Box>
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButtonPrimary
              sx={{ color: '#1179c4f2' }}
              onClick={toggleSidebar}
            >
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButtonPrimary>
          </Tooltip>
        </Box>
      </Box>
      <VendorNotifications
        notifications={notifications}
        openNotifications={openNotifications}
        setOpenNotifications={setOpenNotifications}
        newNotification={newNotification}
      />

      {/* subscription popup */}
      <DialogWrapper
        open={subscriptionModel}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSubscriptionClose}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          {/* <AvatarSuccess>
            <SubscriptionsIcon />
          </AvatarSuccess> */}

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {'Are you sure you want to buy subscription'}?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="medium"
              sx={{
                mx: 1
              }}
              onClick={handleSubscriptionClose}
            >
              {'Cancel'}
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={handleSubscription}
              size="medium"
              sx={{
                mx: 1,
                px: 3
              }}
            >
              {'Yes'}
            </Button>
          </Box>
        </Box>
      </DialogWrapper>
    </HeaderWrapper>
  );
}

export default Header;
