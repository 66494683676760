import { useEffect, useRef, useState } from 'react';

import {
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled
} from '@mui/material';
import { useSelector } from 'react-redux';
import { setSelectedProfile } from 'src/slices/profile-teams-reducer';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
// import Text from 'src/components/Text';

// import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

// const IconButtonPrimary = styled(IconButton)(
//   ({ theme }) => `
//     margin-left: ${theme.spacing(1)};
//     background: ${theme.colors.alpha.trueWhite[10]};
//     color: ${theme.colors.alpha.trueWhite[70]};
//     padding: 0;
//     width: 42px;
//     height: 42px;
//     border-radius: 100%;
//     transition: ${theme.transitions.create(['background', 'color'])};

//     svg {
//       width: 26px;
//     }

//     // &.active,
//     // &:active,
//     // &:hover {
//     //   background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
//     //   color: ${theme.colors.alpha.trueWhite[100]};
//     // }
// `
// );

function ProfileSwitcher() {
  const dispatch = useDispatch();
  const { profileSwitch, user } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState();

  const switchProfile = async (team) => {
    let newTeam = { ...team };
    if (team?.allowedMenus) {
      let menus = JSON.parse(team?.allowedMenus);
      newTeam.allowedMenus = _.concat([], menus);
    }
    dispatch(
      setSelectedProfile({
        selectedProfile: newTeam
      })
    );
    await profileSwitch(newTeam);
    navigate('/test');
    navigate('/partner/home');
  };
  const ref = useRef(null);
  const profileTeams = useSelector(
    (state) => state?.profileTeams?.profileTeams
  );
  const selectedProfile = useSelector(
    (state) => state?.profileTeams?.selectedProfile
  );
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setProfile(selectedProfile);
  }, [selectedProfile]);
  return (
    <>
      <Tooltip
        arrow
        title={
          selectedProfile?.name !== 'My'
            ? selectedProfile?.profile?.business_name
            : 'My Profile'
        }
      >
        <Box
          sx={{ color: '#1179c4f2', cursor: 'pointer' }}
          ref={ref}
          onClick={handleOpen}
        >
          {selectedProfile?.name !== 'My'
            ? _.truncate(selectedProfile?.profile?.business_name, {
                length: 1,
                omission: ''
              })
            : 'Teams'}
        </Box>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading
            variant="body2"
            color="text.primary"
            textAlign={'center'}
          >
            {'Switch Teams'}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1
              }
            }}
            component="nav"
          >
            <ListItem
              className={profile?.name === 'My' ? 'active' : ''}
              button
              sx={{ marginTop: 2 }}
              onClick={() => {
                switchProfile({
                  name: 'My',
                  partnerId: user?.loggedInUserProfileId,
                  invitedPartnerId: user?.loggedInUserProfileId,
                  allowedMenus: `["ALL"]`
                });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary={'My Profile'}
              />
            </ListItem>
            {profileTeams?.map((team) => (
              <ListItem
                className={profile?.name === team?.name ? 'active' : ''}
                button
                sx={{ marginTop: 2 }}
                onClick={() => {
                  switchProfile(team);
                  handleClose();
                }}
              >
                <ListItemText
                  sx={{
                    pl: 1
                  }}
                  primary={team?.profile?.business_name}
                  secondary={`Team - ${team?.name}`}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Popover>
    </>
  );
}

export default ProfileSwitcher;
