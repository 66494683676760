import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {
  Grid,
  Box,
  TextField,
  Button,
  useTheme,
  styled,
  Typography,
  Card,
  CircularProgress
} from '@mui/material';
// import { useSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { _arrayBufferToBase64 } from 'src/utils/functions';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { sendEmail } from 'src/services/email/emailService';
import validator from 'validator';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { Helmet } from 'react-helmet-async';
import { createAppSumoCustomer } from 'src/services/public/appSumoService';
import { emailContentTemplate } from 'src/config';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function AppSumoCustomerInformationForm() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [appSumoCode, setAppSumoCode] = useState('');
  const theme = useTheme();
  const [imageData, setImageData] = useState();
  const [selectedImage, setSelectedImage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const [submitDisable, setSubmitDisable] = useState(false);
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader();

      // Register event listeners
      reader.addEventListener('loadend', (e) => resolve(e.target.result));
      reader.addEventListener('error', reject);

      // Read file
      reader.readAsArrayBuffer(file);
    });
  };
  const getAsByteArray = async (file) => {
    return new Uint8Array(await readFile(file));
  };

  useEffect(() => {
    console.log(selectedImage);
  }, []);

  const saveCustomerHandler = () => {
    let error = false;
    if (_.isEmpty(customerName) || customerName?.trim()?.length === 0) {
      enqueueSnackbar('Please enter Customer Name', { variant: 'error' });
      error = true;
    }
    if (_.isEmpty(name) || name?.trim()?.length === 0) {
      enqueueSnackbar('Please enter Product Name', { variant: 'error' });
      error = true;
    }
    if (
      _.isEmpty(email) ||
      !validator?.isEmail(email) ||
      email?.trim()?.length === 0
    ) {
      enqueueSnackbar('Please enter a valid Primary Email', {
        variant: 'error'
      });
      error = true;
    }
    if (
      !validator.isMobilePhone(contact) ||
      _.isEmpty(contact) ||
      contact?.trim()?.length === 0
    ) {
      enqueueSnackbar('Please enter valid contact number', {
        variant: 'error'
      });
      error = true;
    }
    if (_.isEmpty(companyName) || companyName?.trim()?.length === 0) {
      enqueueSnackbar('Please enter Company Name', { variant: 'error' });
      error = true;
    }

    if (_.isEmpty(appSumoCode) || appSumoCode?.trim()?.length === 0) {
      enqueueSnackbar('Please enter appsumo code', { variant: 'error' });
      error = true;
    }

    if (error) {
      return;
    }

    enqueueSnackbar('Processing...', { variant: 'success' });

    let payload = {
      customerName: customerName,
      name: name,
      email: email,
      website: website,
      description: description,
      contact: contact,
      companyName: companyName,
      appSumoCode: appSumoCode
    };
    setSubmitDisable(true);

    createAppSumoCustomer(payload, selectedImage)
      .then(async (data) => {
        setSubmitDisable(false);
        if (data?.code === 409) {
          enqueueSnackbar(
            `Email already exists. Please try with another email.`,
            { variant: 'error' }
          );
        } else if (data?.code === 411) {
          enqueueSnackbar(
            `Invalid AppSumo Code. Please enter correct appsumo code.`,
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('Your account is being created', {
            variant: 'success'
          });

          const subject = `New Customer Request Created`;
          const message = `<h1>Hello,</h1><p>A new customer request is created.</p><p>Customer - ${payload?.customerName}</p><p><br></p><p><br></p>`;
          const urlLink = `https://staging.bufferloop.com/`;
          const userLogo = `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
          const mailMessage = emailContentTemplate(
            subject,
            message,
            userLogo,
            urlLink,
            payload?.email
          );

          sendEmail(mailMessage).catch((error) => {
            console.log('Error sending join partner request email', error);
          });
          enqueueSnackbar('Generating Login credentials', {
            variant: 'info'
          });

          setTimeout(() => {
            enqueueSnackbar(
              'Login Credentials generated and email sent to the user',
              { variant: 'success' }
            );
          }, 2000);

          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_UI_PORTAL_URL}`;
            setSubmitDisable(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setSubmitDisable(false);
        console.log(error);
        console.log(error.message);
        console.log(error.response);
        if (error?.response?.data) {
          enqueueSnackbar(`Error -  ${error?.response?.data}`, {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(`Error creating customer`, { variant: 'error' });
        }
      });
  };
  return (
    <>
      <Helmet>
        <title>AppSumo Customer Information</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid
          container
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Grid item md={12} textAlign={'center'}>
            <Typography variant="h3" fontSize={20}>
              {'Customer Information'}
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box className="content_left_right_spacing">
        <Card
          sx={{
            mt: 0
          }}
        >
          <Grid container spacing={2} px={4} sx={{ mt: 0.5 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Customer Name*')} :</b>
              </Box>
              <TextField
                fullWidth
                name="customerName"
                placeholder={t('Enter customer name')}
                value={customerName}
                onChange={(event) => setCustomerName(event?.target?.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Product Name*')} :</b>
              </Box>
              <TextField
                fullWidth
                name="name"
                placeholder={t('Enter product name')}
                value={name}
                onChange={(event) => setName(event?.target?.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Primary Email*')} :</b>
              </Box>
              <TextField
                fullWidth
                name="email"
                placeholder={t('Enter email')}
                value={email}
                onChange={(event) => setEmail(event?.target?.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Company Name*')} :</b>
              </Box>
              <TextField
                fullWidth
                name="companyName"
                placeholder={t('Enter company name here')}
                value={companyName}
                onChange={(event) => setCompanyName(event?.target?.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Website')} :</b>
              </Box>
              <TextField
                fullWidth
                name="website"
                type={'url'}
                placeholder={t('Enter website')}
                variant="outlined"
                value={website}
                onChange={(event) => setWebsite(event?.target?.value)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Contact Number*')} :</b>
              </Box>
              <TextField
                fullWidth
                type={'tel'}
                name="contact"
                placeholder={t('Enter contact number')}
                value={contact}
                onChange={(event) => setContact(event?.target?.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('AppSumo Code*')} :</b>
              </Box>
              <TextField
                fullWidth
                type={'text'}
                name="appsumocode"
                placeholder={t('Enter appsumo code')}
                value={appSumoCode}
                onChange={(event) => setAppSumoCode(event?.target?.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mb: 0.5 }}>
                <b>{t('Description')} :</b>
              </Box>
              <EditorWrapper>
                <ReactQuill
                  value={description}
                  onChange={(desc) => setDescription(desc)}
                />
              </EditorWrapper>
            </Grid>

            <Grid item xs={6} sm={4} md={1}>
              <Box
                sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
              >
                <b>{t(' Image')} :</b>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={9}
              mr={1}
              display="flex"
              flexDirection="row"
            >
              <Grid item xs={12} sm={8} md={3} sx={{ alignSelf: 'center' }}>
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: 'none' }}
                  onChange={async (e) => {
                    setSelectedImage(e.target.files[0]);
                    let byteArray = await getAsByteArray(e.target.files[0]);
                    let data = _arrayBufferToBase64(byteArray);
                    setImageData(data);
                  }}
                />
                <label htmlFor="select-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadTwoToneIcon />}
                  >
                    Upload Logo
                  </Button>
                </label>
              </Grid>
              {imageData && (
                <Grid item xs={12} sm={8} md={6}>
                  <Box mt={2} textAlign="center">
                    <img
                      // src={imageUrl}
                      src={`data:${selectedImage?.mimeType};base64, ${imageData}`}
                      alt="vendorOnboarding"
                      width={'50%'}
                      style={{
                        // border: '2px solid #00000015',
                        borderRadius: '5%'
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} textAlign={'center'}>
              <Button
                variant="contained"
                color="success"
                size="medium"
                onClick={() => {
                  saveCustomerHandler();
                }}
                disabled={submitDisable}
                startIcon={
                  submitDisable ? (
                    <CircularProgress size="1rem" sx={{ color: '#fff' }} />
                  ) : (
                    <SaveOutlinedIcon fontSize="small" />
                  )
                }
              >
                {t('Save')}
              </Button>
            </Grid>
            <Grid
              sx={{
                mb: `${theme.spacing(3)}`
              }}
              item
              xs={12}
              sm={8}
              md={9}
            />
          </Grid>
        </Card>
      </Box>
    </>
  );
}
export default AppSumoCustomerInformationForm;
