import axios from 'axios';
import { apiConfig } from 'src/config';

export const getCompanyProfileForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/company/profile/' + vendorId)
    .then((res) => {
      return res.data;
    });
};

export const saveCompanyProfileForVendor = async (
  vendorCompanyProfilePayload,
  logoFile
) => {
  const formData = new FormData();
  if (logoFile) {
    formData.append(logoFile?.name, logoFile);
  }
  formData.append(
    'companyProfilePayload',
    JSON.stringify(vendorCompanyProfilePayload)
  );

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/company/profile',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};

// Member services

export const saveMemberDetails = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/team/member/create',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

export const updateMemberDetails = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/team/member/update',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

export const deleteTeamMemberForVendor = async (memberDetail) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/team/member/delete',
      memberDetail
    )
    .then((res) => {
      return res.data;
    });
};

export const getAllTeamMembersForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/team/member/fetch/' + vendorId)
    .then((res) => {
      return res.data;
    });
};

// Teams Services

export const saveTeamForVendor = async (team) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/team/new', team)
    .then((res) => {
      return res.data;
    });
};

export const updateTeamForVendor = async (teamPayload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/team/update', teamPayload)
    .then((res) => {
      return res.data;
    });
};

export const deleteteam = async (vendorId, teamId) => {
  return axios
    .delete(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/team/delete/' +
        vendorId +
        '/' +
        teamId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleTeamForVendor = async (vendorId, teamId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/team/single/' +
        vendorId +
        '/' +
        teamId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllTeamsForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/team/fetch/' + vendorId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchProducts = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/fetch/products/')
    .then((res) => {
      return res?.data?.data;
    })
    .catch((error) => {
      throw error;
    });
};
