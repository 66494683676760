import axios from 'axios';
import { apiConfig } from 'src/config';

export const getPartnerBioForProspectPage = async (vendorId, partnerId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'public/api/v1/prospect/page/partner/bio/' +
        vendorId +
        '/' +
        partnerId
    )
    .then((res) => {
      return res.data;
    });
};

export const getSingleProspectPage = async (prospectPageId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL + 'public/api/v1/prospect/page/' + prospectPageId
    )
    .then((res) => {
      return res.data;
    });
};

export const increaseViewCountForProspectPage = async (prospectPageId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'public/api/v1/prospect/page/increment/count/' +
        prospectPageId
    )
    .then((res) => {
      return res.data;
    });
};
