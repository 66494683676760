import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchProfile = async (userEmail, tokenResult) => {
  console.log(userEmail);
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'admin/api/v1/profile/fetch',
      {
        userEmail
      },
      {
        headers: {
          token: tokenResult?.token
        }
      }
    )
    .then((res) => {
      return res?.data;
    });
};
