import { Box, Button, Dialog, Typography, Zoom, styled } from '@mui/material';
import React, { forwardRef } from 'react';

const DialogWrapper = styled(Dialog)(
  () => `
            .MuiDialog-paper {
              overflow: visible;
            }
      `
);

const TransitionEnabelForm = forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const ButtonInfo = styled(Button)(
  ({ theme }) => `
           background: ${theme.colors.info.main};
           color: ${theme.palette.info.contrastText};
      
           &:hover {
              background: ${theme.colors.info.dark};
           }
          `
);

export default function EmailVerifyDialog({ open, setOpen }) {
  return (
    <>
      <DialogWrapper
        open={open}
        maxWidth="sm"
        TransitionComponent={TransitionEnabelForm}
        keepMounted
        // onClose={closeEnableFormDialog}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h4"
          >
            Thank you for joining Bufferloop.
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {
              'The verification email has been sent to your registered email address.'
            }
          </Typography>

          <Box>
            <ButtonInfo
              onClick={async () => {
                setOpen(false);
              }}
              size="medium"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              Close
            </ButtonInfo>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}
