import {
  alpha,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';


const ContentPreview = ({ contentPreview }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
      display: flex;
      align-items: center;
      // justify-content: space-between;
      position: relative;
  
      .MuiTouchRipple-root {
          opacity: .3;
      }
  
      &:hover {
          .MuiCardActionArea-focusHighlight {
              opacity: .05;
          }
      }
    `
  );

  const getFileNameFromKey = (key, vendorId, contentId) => {
    console.log('key', key);
    let fileName = key?.replace(
      `vendor/${vendorId}/contents/${contentId}/`,
      ''
    );
    console.log('fileName', fileName, vendorId, contentId);
    return fileName;
  };
  const currentAttachments = contentPreview?.attachments?.map((file, index) => (
    <ListItem disableGutters component="div" key={index}>
      <>
        <ListItemText
          primary={getFileNameFromKey(
            file.key,
            contentPreview.vendorId,
            contentPreview.id
          )}
        />
        <Button
          sx={{
            mt: { xs: 2, sm: 0 },
            mr: 2
          }}
          href={file.url}
          target="_self"
          variant="outlined"
          startIcon={<DownloadIcon scale={100} color="success" />}
        >
          {t('Download')}
        </Button>
      </>
    </ListItem>
  ));

  return (
    <>
      <Card
        sx={{
          p: 5,
          mb: 3
        }}
      >
        <CardHeader
          title={
            <>
              <Typography
                variant="h4"
                mb={1}
                fontSize={20}
                textAlign={'center'}
              >
                {contentPreview?.title}
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </>
          }
          disableTypography
        />

        <Grid container spacing={1}>
          {contentPreview?.description && (
            // <Grid item xs={12} md={12}>
            <>
              <Grid item xs={12} md={8} p={2}>
                <Card
                  sx={{
                    padding: 1,
                    textAlign: 'left',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    minHeight: '100%',
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                                0 0.6rem 1.6rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.2
                      )}, 
                                0 0.2rem 0.2rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.15
                      )}`
                    }
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contentPreview?.description
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={4} p={2}>
                <Card
                  sx={{
                    textAlign: 'center',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 20rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                                0 0.6rem 1.6rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.2
                      )}, 
                                0 0.2rem 0.2rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.15
                      )}`
                    }
                  }}
                >
                  <CardActionAreaWrapper>
                    {!contentPreview?.logo && (
                      <CardMedia
                        component="img"
                        height={'200px'}
                        width={'auto'}
                        image="/static/images/placeholders/content_image_blank.jpg"
                        alt="..."
                      />
                    )}
                    {contentPreview?.logo && (
                      <Box>
                        <img
                          // src={imageUrl}
                          src={`data:image;base64, ${contentPreview?.logo}`}
                          alt="content"
                          style={{
                            borderRadius: '5%'
                          }}
                          height={'200px'}
                          width={'auto'}
                        />
                      </Box>
                    )}
                  </CardActionAreaWrapper>
                  <CardActions
                    sx={{
                      justifyContent: 'center'
                    }}
                  >
                    {/* <Button
                      href={contentPreview?.imageFileURL}
                      target="_self"
                      variant="outlined"
                      startIcon={<DownloadIcon scale={50} color="success" />}
                    >
                      {t('Download')}
                    </Button> */}
                  </CardActions>
                </Card>
              </Grid>
            </>
            // </Grid>
          )}
          {/* </Grid> */}
          {contentPreview?.attachments?.length > 0 && (
            <Grid item xs={12} md={12}>
              <Divider />
              <Card
                sx={{
                  p: 1,
                  mb: 2,
                  mt: 2
                }}
              >
                {currentAttachments}
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

ContentPreview.defaultProps = {
  contents: []
};
export default ContentPreview;
