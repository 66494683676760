import {
  Grid,
  Card,
  styled,
  CardActionArea,
  CardMedia,
  Typography,
  Button,
  Box,
  Dialog,
  ListItemText,
  DialogTitle
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getSingleNotification } from 'src/services/vendor/notificationService';
import { _arrayBufferToBase64 } from 'src/utils/functions';
// import DownloadIcon from '@mui/icons-material/DownloadForOffline';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: auto;
        box-shadow: none;
      }
`
);

function NotificationDialog({
  notification,
  openNotificationDialog,
  setOpenNotificationDialog,
  setSelectedNotification
}) {
  const [imageData, SetImageData] = useState();

  const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
      display: flex;
      align-items: center;
      // justify-content: space-between;
      position: relative;
  
      .MuiTouchRipple-root {
          opacity: .3;
      }
  
      &:hover {
          .MuiCardActionArea-focusHighlight {
              opacity: .05;
          }
      }
    `
  );

  useEffect(() => {
    SetImageData();
    if (notification?.id) {
      getSingleNotification(notification?.id).then((singleNotificationRes) => {
        SetImageData(_arrayBufferToBase64(singleNotificationRes?.image?.data));
      });
    }
  }, [notification]);
  const handleCloseNotificationDialog = () => {
    setOpenNotificationDialog(false);
    setSelectedNotification();
  };
  return (
    <DialogWrapper
      maxWidth="md"
      fullWidth
      open={openNotificationDialog}
      onClose={handleCloseNotificationDialog}
    >
      <DialogTitle
        sx={{
          flexDirection: 'row',
          textAlign: 'center'
        }}
      >
        <Typography variant="button" fontSize={18}>
          {notification?.title}
        </Typography>
      </DialogTitle>
      <Grid
        container
        spacing={3}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        className="content_block_space"
      >
        {notification && (
          <>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionAreaWrapper className="content_block_space">
                  {!imageData && (
                    <CardMedia
                      component="img"
                      height={'250px'}
                      width={'auto'}
                      image="/static/images/placeholders/content_image_blank.jpg"
                      alt="..."
                    />
                  )}
                  {imageData && (
                    <Box>
                      <img
                        // src={imageUrl}
                        src={`data:image;base64, ${imageData}`}
                        alt="content"
                        style={{
                          borderRadius: '5%'
                        }}
                        width={'100%'}
                        height={'auto'}
                      />
                    </Box>
                  )}
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card className="content_block_space">
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h4'
                  }}
                  primary={'Descriprion :'}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: notification?.description
                  }}
                />
              </Card>
            </Grid>
            {notification?.link && (
              <Grid item xs={12} md={12} mt={1} textAlign={'right'}>
                <Button
                  variant="text"
                  onClick={() => {
                    if (notification?.link) {
                      window.open(notification?.link);
                    }
                  }}
                >
                  {'More Info'}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </DialogWrapper>
  );
}

export default NotificationDialog;
