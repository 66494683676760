import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
// import ThemeSettings from 'src/components/ThemeSettings';

import Sidebar from './Sidebar';
import Header from './Header';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import { getCompanyProfileForVendor } from 'src/services/vendor/settingsService';
import { useSnackbar } from 'notistack';
import { apiConfig } from 'src/config';
import { useDispatch } from 'react-redux';
import { setVendorLogo } from 'src/slices/vendorReducer';

const AccentHeaderLayout = () => {
  const theme = useTheme();

  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [vendorImage, setVendorImage] = useState();
  const [vendorCompanyProfile, setVendorCompanyProfile] = useState();
  const dispatch = useDispatch();

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(async () => {
    let vendorId = parseInt(user?.id);
    if (vendorId) {
      getCompanyProfileForVendor(vendorId).then((vendorCompanyProfileRes) => {
        if (vendorCompanyProfileRes) {
          setVendorCompanyProfile(vendorCompanyProfileRes);
          let data = _arrayBufferToBase64(vendorCompanyProfileRes?.logo?.data);
          setVendorImage(data);
          const vendorlogodata = `data:image/png;base64,${data}`;
          dispatch(
            setVendorLogo({
              selectedVendorLogo: vendorlogodata || ''
            })
          );
        } else {
          setVendorCompanyProfile();
          setVendorImage();
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user?.role !== 'vendor') {
      logout();
    } else if (
      user?.role === 'vendor' &&
      !user?.subscribed &&
      apiConfig.ENVIRONMENT !== 'demo'
    ) {
      if (user?.subscriptionType !== 'appSumo') {
        enqueueSnackbar('You have no active subscription', {
          variant: 'error'
        });
        window.location.href = apiConfig.STRIPE_CUSTOMER_PORTAL;
      }
    } else if (user?.role === 'vendor' && apiConfig.ENVIRONMENT === 'demo') {
      // logout();
    } else if (
      user?.role === 'vendor' &&
      apiConfig.ENVIRONMENT !== 'demo' &&
      user?.subscribed
    ) {
      if (
        user?.planType === 'STANDARD' &&
        apiConfig.DELIVERY_ENVIRONMENT === 'NETWORK'
      ) {
        enqueueSnackbar('You are not authorized', {
          variant: 'warning'
        });
        logout();
      } else if (
        user?.planType === 'NETWORK' &&
        apiConfig.DELIVERY_ENVIRONMENT === 'STANDARD'
      ) {
        enqueueSnackbar('You are not authorized', {
          variant: 'warning'
        });
        logout();
      } else if (
        user?.planType === 'STANDARD' &&
        apiConfig.DELIVERY_ENVIRONMENT === 'STANDARD' &&
        !user?.vendor_domain?.includes(window?.location?.hostname)
      ) {
        enqueueSnackbar('You are not authorized', {
          variant: 'warning'
        });
        logout();
      }
      // logout();
    } else if (user) {
      logout();
    }
  }, [user]);

  return (
    <>
      <Header
        vendorCompanyProfile={vendorCompanyProfile}
        vendorImage={vendorImage}
      />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${theme.sidebar.width}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentHeaderLayout;
