import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import AddAppSumoCode from 'src/content/bufferloop/admin/BL-AppSumo/AddAppSumoCode';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

/* const Automation = Loader(
  lazy(() => import('src/content/dashboards/Automation'))
); */
/* const Messenger = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Messenger'))
); */
// const Reports = Loader(lazy(() => import('src/content/dashboards/Reports')));
// const Banking = Loader(lazy(() => import('src/content/dashboards/Banking')));
// const Commerce = Loader(lazy(() => import('src/content/dashboards/Commerce')));
// const Expenses = Loader(lazy(() => import('src/content/dashboards/Expenses')));
// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
// const Finance = Loader(lazy(() => import('src/content/dashboards/Finance')));
// const Fitness = Loader(lazy(() => import('src/content/dashboards/Fitness')));
// const HealthcareDoctor = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare'))
// );
// const HealthcareHospital = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare/HospitalView'))
// );
// const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
// const Learning = Loader(lazy(() => import('src/content/dashboards/Learning')));
// const Monitoring = Loader(
//   lazy(() => import('src/content/dashboards/Monitoring'))
// );
// const Products = Loader(lazy(() => import('src/content/dashboards/Products')));
// const Statistics = Loader(
//   lazy(() => import('src/content/dashboards/Statistics'))
// );
// const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

const NewVendorOnboarding = Loader(
  lazy(() =>
    import('src/content/bufferloop/admin/BL-Customers/NewVendorOnboarding')
  )
);
const EditVendorOnboarding = Loader(
  lazy(() =>
    import('src/content/bufferloop/admin/BL-Customers/EditVendorOnboarding')
  )
);

const VendorDetails = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Vendors'))
);

const PartnerDetails = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Partners'))
);

const SingleVendorDetails = Loader(
  lazy(() =>
    import('src/content/bufferloop/admin/BL-Vendors/SingleVendorDetails')
  )
);

const SinglePartnerDetails = Loader(
  lazy(() =>
    import('src/content/bufferloop/admin/BL-Partners/SinglePartnerDetails')
  )
);

const PendingDetails = Loader(
  lazy(() =>
    import('src/content/bufferloop/admin/BL-Vendors/PartnerRequestDetails')
  )
);

const Dashboard = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Dashboard'))
);

const Invoices = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Invoices'))
);
const Customers = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Customers'))
);
const AdminUsers = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-AdminUsers'))
);
const Coupons = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Coupons'))
);
const DummyVendorAccounts = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-DummyVendorAccounts'))
);
const SystemFlags = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-SystemFlags'))
);
const Notifications = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Notifications'))
);
const Category = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-Category'))
);

const PrintableInvoice = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-PrintableInvoice'))
);

const NewDummyVendors = Loader(
  lazy(() =>
    import(
      'src/content/bufferloop/admin/BL-DummyVendorAccounts/DummyVendorsPage'
    )
  )
);

const AppSumo = Loader(
  lazy(() => import('src/content/bufferloop/admin/BL-AppSumo'))
);

const adminRoutes = [
  {
    path: '',
    element: <Dashboard />
  },
  {
    path: '/admin/dashboards',
    element: <Dashboard />
  },
  {
    path: '/admin/vendors',
    element: <VendorDetails />
  },

  {
    path: '/admin/vendors/details/:vendorId',
    element: <SingleVendorDetails />
  },
  {
    path: '/admin/partners/:vendorId',
    element: <PendingDetails />
  },
  {
    path: '/admin/partners',
    element: <PartnerDetails />
  },
  {
    path: '/admin/partners/details/:partnerId',
    element: <SinglePartnerDetails />
  },
  {
    path: '/admin/vendor/onboarding',
    element: <Customers />
  },
  {
    path: '/admin/vendor/onboarding/new',
    element: <NewVendorOnboarding />
  },
  {
    path: '/admin/vendor/onboarding/edit/:prospectId',
    element: <EditVendorOnboarding />
  },
  {
    path: '/admin/invoices',
    element: <Invoices />
  },
  {
    path: '/admin/invoices/details/:vendorId/:invoiceId',
    element: <PrintableInvoice />
  },
  {
    path: '/admin/customers',
    element: <Customers />
  },
  {
    path: '/admin/users',
    element: <AdminUsers />
  },
  {
    path: '/admin/coupons',
    element: <Coupons />
  },
  {
    path: '/admin/appsumo',
    element: <AppSumo />
  },
  // {
  //   path: '/admin/appsumo/add',
  //   element: <AddAppSumoCode />
  // },
  {
    path: '/admin/demo',
    element: <DummyVendorAccounts />
  },
  {
    path: '/admin/demo/new',
    element: <NewDummyVendors />
  },
  {
    path: '/admin/flags',
    element: <SystemFlags />
  },
  {
    path: '/admin/invoices',
    element: <Invoices />
  },
  {
    path: '/admin/notifications',
    element: <Notifications />
  },
  {
    path: '/admin/category',
    element: <Category />
  },
  {
    path: '/admin/appsumo',
    element: <AppSumo />
  }
];

export default adminRoutes;
