import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { apiConfig } from 'src/config';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Messenger = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Messenger'))
);
const Dashboard = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Dashboard'))
);

const DashboardDemo = Loader(
  lazy(() => import('src/content/bufferloop/demoVendor/BL-Dashboard'))
);

const Referrals = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Referrals'))
);

const RewardsAndInvoices = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-RewardsAndInvoices'))
);
const StandardRewards = Loader(
  lazy(() =>
    import(
      'src/content/bufferloop/vendor/BL-RewardsAndInvoices/StandardRewards'
    )
  )
);
const Partners = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Partners'))
);
const PendingPartners = Loader(
  lazy(() =>
    import('src/content/bufferloop/vendor/BL-Partners/BL-PendingPartners')
  )
);
const Groups = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Groups'))
);
const Triggers = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Triggers'))
);
const ContentManagement = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Content'))
);
const EmailCampaign = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-EmailCampaign'))
);
const NewsAnnouncement = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-NewsAnnouncement'))
);

const NewEmailCampaignForm = Loader(
  lazy(() =>
    import(
      'src/content/bufferloop/vendor/BL-EmailCampaign/NewEmailCampaignForm'
    )
  )
);
const EditEmailCampaignForm = Loader(
  lazy(() =>
    import('src/content/bufferloop/vendor/BL-EmailCampaign/EditCampignForm')
  )
);
const PlaybookManagement = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Playbook'))
);

const TrainingsManagement = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Trainings'))
);

const GroupDetailsPage = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-GroupDetails'))
);

const Lessons = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Trainings/BL-Lessons'))
);

const Lectures = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Trainings/BL-Lectures'))
);

const LectureDetails = Loader(
  lazy(() =>
    import('src/content/bufferloop/vendor/BL-Trainings/BL-LecturesDetails')
  )
);

const EnrolledPartners = Loader(
  lazy(() =>
    import('src/content/bufferloop/vendor/BL-Trainings/BL-EnrolledPartners')
  )
);

const Preview = Loader(
  lazy(() =>
    import('src/content/bufferloop/vendor/BL-Trainings/BL-LecturePreview')
  )
);

const PartnersPreview = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-PartnersPreview'))
);

const ViewInvoice = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-ViewInvoice'))
);

const PrintableInvoice = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-PrintableInvoice'))
);

const Settings = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Settings'))
);

const ProspectPages = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Prospect'))
);

const Analytics = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Analytics'))
);

const AnalyticsDemo = Loader(
  lazy(() => import('src/content/bufferloop/demoVendor/BL-Analytics'))
);

const Pipeline = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-Pipeline'))
);

const ProspectViewPage = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-ProspectViewPage'))
);

const PiplineDetails = Loader(
  lazy(() => import('src/content/bufferloop/vendor/BL-PiplineDetails'))
);

const vendorRoutes = [
  {
    path: '/vendor/dashboards',
    element:
      apiConfig.ENVIRONMENT === 'demo' ? <DashboardDemo /> : <Dashboard />
  },
  {
    path: '/vendor/referrals',
    element: <Referrals />
  },
  {
    path: '/vendor/referrals/:slug',
    element: <Referrals />
  },
  {
    path: '/vendor/RewardsandInvoices',
    element: <RewardsAndInvoices />
  },
  {
    path: '/vendor/Rewards',
    element: <StandardRewards />
  },
  {
    path: '/vendor/invoice/:invoiceId',
    element: <ViewInvoice />
  },
  {
    path: '/vendor/invoice/success/:vendorId/:invoiceId',
    element: <ViewInvoice />
  },
  {
    path: '/vendor/invoice/failure/:vendorId/:invoiceId',
    element: <ViewInvoice />
  },
  {
    path: '/vendor/printableInvoice/:invoiceId',
    element: <PrintableInvoice />
  },
  {
    path: '',
    element:
      apiConfig.ENVIRONMENT === 'demo' ? <DashboardDemo /> : <Dashboard />
  },
  {
    path: '/vendor/partners',
    element: <Partners />
  },
  {
    path: '/vendor/partners/:partnerId',
    element: <PartnersPreview />
  },
  {
    path: '/vendor/partners/pending',
    element: <PendingPartners />
  },
  {
    path: '/vendor/groups',
    element: <Groups />
  },
  {
    path: '/vendor/triggers',
    element: <Triggers />
  },
  {
    path: '/vendor/group/:groupId',
    element: <GroupDetailsPage />
  },
  {
    path: '/vendor/messaging',
    element: <Messenger />
  },
  {
    path: '/vendor/content',
    element: <ContentManagement />
  },
  {
    path: '/vendor/news',
    element: <NewsAnnouncement />
  },
  {
    path: '/vendor/emailCampaign',
    element: <EmailCampaign />
  },
  {
    path: '/vendor/emailCampaign/new',
    element: <NewEmailCampaignForm />
  },
  {
    path: '/vendor/emailCampaign/edit/:campaignId',
    element: <EditEmailCampaignForm />
  },
  {
    path: '/vendor/playbook',
    element: <PlaybookManagement />
  },
  {
    path: '/vendor/trainings',
    element: <TrainingsManagement />
  },
  {
    path: '/vendor/trainings/:vendorId/:courseId',
    element: <Lessons />
  },
  {
    path: '/vendor/:vendorId/courses/:courseId/lessons/:lessonId',
    element: <Lectures />
  },
  {
    path: '/vendor/:vendorId/courses/:courseId/lessons/:lessonId/lectures/:lectureId',
    element: <LectureDetails />
  },
  {
    path: '/vendor/trainings/enrolled/:vendorId/:courseId',
    element: <EnrolledPartners />
  },
  {
    path: '/vendor/:vendorId/courses/:courseId/lessons/:lessonId/lectures/preview/:lectureId/',
    element: <Preview />
  },
  {
    path: '/vendor/partners',
    element: <Partners />
  },
  {
    path: '/vendor/prospect',
    element: <ProspectPages />
  },
  {
    path: '/vendor/prospects/view/:prospectPageId',
    element: <ProspectViewPage />
  },
  {
    path: '/vendor/settings',
    element: <Settings />
  },
  {
    path: '/vendor/analytics',
    element:
      apiConfig.ENVIRONMENT === 'demo' ? <AnalyticsDemo /> : <Analytics />
  },

  {
    path: '/vendor/pipeline',
    element: <Pipeline />
  },
  {
    path: '/vendor/pipeline/details/:id',
    element: <PiplineDetails />
  }
];

export default vendorRoutes;
