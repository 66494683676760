import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
// import ThemeSettings from 'src/components/ThemeSettings';

import Sidebar from './Sidebar';
import Header from './Header';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import { getPartnerGroupForVendor } from 'src/services/partner/groupService';
import { useDispatch } from 'react-redux';
import {
  // setSelectedVendor,
  // setSelectedVendorId,
  setVendorGroupsMap
} from 'src/slices/vendorReducer';
// import { getAllTeamsForPartner } from 'src/services/partner/settingsService';
import { getAllTeamsForLoggedInPartner } from 'src/services/partner/team-service';
import { setProfileTeams } from 'src/slices/profile-teams-reducer';
import { useSelector } from 'react-redux';
import { getCompanyProfileForPartner } from 'src/services/partner/settingsService';

const AccentHeaderLayout = () => {
  const theme = useTheme();
  const { user, logout, saveVendorGroupsMap } = useAuth();
  // const [profileTeams, setProfileTeams] = useState([]);
  const dispatch = useDispatch();
  const selectedProfile = useSelector(
    (state) => state.profileTeams.selectedProfile
  );
  const [partnerProfile, setPartnerProfile] = useState();
  const [partnerLogo, setPartnerLogo] = useState();
  const fetchVendorGroupsMap = () => {
    if (selectedProfile?.partnerId) {
      let payload = {
        partnerId: selectedProfile?.partnerId
      };
      getPartnerGroupForVendor(payload)
        .then(async (partnerGroupsForVendorData) => {
          let vendorGroupsMap = new Map();
          partnerGroupsForVendorData?.map((e) => {
            vendorGroupsMap.set(parseInt(e?.vendorId), e);
            return e;
          });
          dispatch(
            setVendorGroupsMap({
              vendorGroupsMap: vendorGroupsMap
            })
          );
          await saveVendorGroupsMap(vendorGroupsMap);
        })
        .catch((error) => {
          console.log('Error fetching vendor group info for partner', error);
        });
    }
  };

  const fetchPartnerCompany = () => {
    if (selectedProfile?.partnerId) {
      getCompanyProfileForPartner(selectedProfile?.partnerId)
        .then((partnerProfileCompanyRes) => {
          if (partnerProfileCompanyRes) {
            let data = _arrayBufferToBase64(
              partnerProfileCompanyRes?.logo?.data
            );
            setPartnerProfile(partnerProfileCompanyRes);
            setPartnerLogo(data);
          }
        })
        .catch((error) => {
          console.log('error fetching vendor profile', error);
          setPartnerProfile();
        });
    }
  };
  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    fetchVendorGroupsMap();
    /* dispatch(
      setSelectedVendorId({
        selectedVendorId: undefined
      })
    );
    dispatch(
      setSelectedVendor({
        selectedVendorId: undefined
      })
    ); */
    fetchPartnerCompany();
  }, [user, selectedProfile]);

  useEffect(() => {
    if (selectedProfile?.invitedPartnerId) {
      getAllTeamsForLoggedInPartner(selectedProfile?.invitedPartnerId).then(
        (teamMappings) => {
          let teams = [];
          teamMappings?.map((mapping) => {
            mapping.team.partnerId = parseInt(mapping?.partnerId);
            mapping.team.invitedPartnerId = parseInt(mapping?.invitedPartnerId);
            mapping.team.profile = mapping?.profile;
            teams.push(mapping?.team);
            return mapping;
          });
          // setProfileTeams([...teams]);
          dispatch(
            setProfileTeams({
              profileTeams: teams
            })
          );
        }
      );
    } else {
      dispatch(
        setProfileTeams({
          profileTeams: []
        })
      );
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (user?.role !== 'partner') {
      logout();
    }
  }, [user]);

  return (
    <>
      <Header partnerProfile={partnerProfile} partnerLogo={partnerLogo} />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${theme.sidebar.width}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccentHeaderLayout;
