import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileTeams: [],
  selectedProfile: undefined
};

const profileTeamsSlice = createSlice({
  name: 'profileTeams',
  initialState,
  reducers: {
    setProfileTeams(state, action) {
      const { profileTeams } = action.payload;
      state.profileTeams = profileTeams;
    },
    setSelectedProfile(state, action) {
      const { selectedProfile } = action.payload;
      state.selectedProfile = selectedProfile;
    }
  }
});

export default profileTeamsSlice.reducer;

export const { setProfileTeams, setSelectedProfile } =
  profileTeamsSlice.actions;
