import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedVendorId: undefined,
  selectedVendor: undefined,
  vendorGroupsMap: undefined
};

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setSelectedVendorId(state, action) {
      const { selectedVendorId } = action.payload;
      state.selectedVendorId = selectedVendorId;
    },
    setSelectedVendor(state, action) {
      const { selectedVendor } = action.payload;
      state.selectedVendor = selectedVendor;
    },
    setVendorGroupsMap(state, action) {
      const { vendorGroupsMap } = action.payload;
      state.vendorGroupsMap = vendorGroupsMap;
    },
    setVendorPlan(state, action) {
      const { selectedVendorPlan } = action.payload;
      state.selectedVendorPlan = selectedVendorPlan;
    },
    deSelectedVendorId(state, action) {
      console.log(action.payload);
      state.selectedVendorId = undefined;
    },
    deSelectedVendor(state, action) {
      console.log(action.payload);
      state.selectedVendor = undefined;
    },
    setVendorLogo(state, action) {
      const { selectedVendorLogo } = action.payload;
      state.selectedVendorLogo = selectedVendorLogo;
    }
  }
});

export default vendorSlice.reducer;

export const {
  setSelectedVendorId,
  setSelectedVendor,
  deSelectedVendorId,
  deSelectedVendor,
  setVendorGroupsMap,
  setVendorPlan,
  setVendorLogo
} = vendorSlice.actions;

// export default vendorSlice;
