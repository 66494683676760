import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const PartnerHome = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-PartnerHome'))
);

const MarketPlace = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-MarketPlace'))
);

const Settings = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Settings'))
);
const Rewards = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-RewardsAndWithdrawals'))
);

const Support = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Support'))
);

const Analytics = Loader(
  lazy(() => import('src/content/bufferloop/partner/BL-Analytics'))
);


const partnerRoutes = [
  {
    path: '',
    element: <PartnerHome />
  },
  {
    path: '/partner/home',
    element: <PartnerHome />
  },
  {
    path: '/partner/marketplace',
    element: <MarketPlace />
  },
  {
    path: '/partner/settings',
    element: <Settings />
  },
  {
    path: '/partner/rewards',
    element: <Rewards />
  },
  {
    path: '/partner/support',
    element: <Support />
  },

  {
    path: '/partner/analytics',
    element: <Analytics />
  }
];

export default partnerRoutes;
