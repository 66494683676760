import axios from "axios";
import { apiConfig } from "src/config";

export const getOnboardingForm = async (groupId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'public/api/v1/groups/onboarding-form/' +
        groupId
    )
    .then((res) => {
      return res.data;
    });
};

export const fetchAllVendorCategories = async () => {
    return axios
      .get(apiConfig.APP_BASE_URL + 'public/api/v1/vendor/category/fetch')
      .then((res) => {
        return res.data;
      });
  };
