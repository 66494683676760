import { useState, useEffect, useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
// import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import ContentPreview from './ContentPreview';
import { useSnackbar } from 'notistack';
// import _ from 'lodash';
// import { getSingleContent } from 'src/services/partner/contentService';
import { useParams } from 'react-router';
import { getSingleContent } from 'src/services/public/contentService';

function ContentManagement() {
  const isMountedRef = useRefMounted();
  const [contentPreview, setContentPreview] = useState([]);
  const [ready, setReady] = useState(true);
  const showToast = useSnackbar();
  const { contentId } = useParams();

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const getPreviewContent = useCallback(async () => {
    setReady(false);
    const payload = {
      content_id: contentId
    };
    getSingleContent(payload)
      .then((contentPreview) => {
        let data = _arrayBufferToBase64(contentPreview?.logo?.data);
        contentPreview.logo = data;
        setContentPreview(contentPreview);
        setReady(true);
      })
      .catch((err) => {
        showToast.enqueueSnackbar('error reading content', {
          variant: 'error'
        });
        console.error(err);
        setReady(true);
      });
  }, [isMountedRef]);

  useEffect(() => {
    getPreviewContent();
  }, [getPreviewContent]);

  return (
    <>
      <Helmet>
        {/* <title>Content - Preview</title> */}
        <title>
          {`${contentPreview?.title ? contentPreview?.title : 'Content Title'}`}
        </title>
      </Helmet>
      {/* <PageTitleWrapper> */}
      <Grid container display={'none'}>
        <Grid item textAlign={'center'} md={12}>
          <Typography variant="h4" gutterBottom>
            {`${
              contentPreview?.title ? contentPreview?.title : 'Content Title'
            }`}
          </Typography>
        </Grid>
      </Grid>
      {/* </PageTitleWrapper> */}
      {ready && (
        <>
          <Grid
            sx={{
              px: 4,
              mt: 2
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <ContentPreview
                contentPreview={contentPreview}
                getPreviewContent={getPreviewContent}
              />
            </Grid>
          </Grid>
        </>
      )}
      {!ready && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Footer />
    </>
  );
}

export default ContentManagement;
