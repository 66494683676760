import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import vendorReducer from 'src/slices/vendorReducer';
import profileTeamReducer from 'src/slices/profile-teams-reducer';
// import { reducer as vendorReducer } from 'src/slices/vendor';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  vendor: vendorReducer,
  profileTeams: profileTeamReducer
});

export default rootReducer;
