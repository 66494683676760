import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function TempLinkPlan() {
  return (
    <>
      <Grid container sx={{ my: 2 }}>
        <Grid item xs={12} sm={4} textAlign={'center'}>
          <a href="https://demo.bufferloop.com/bufferloop/demo/customer/information">
            Free Demo Trial
          </a>
        </Grid>

        <Grid item xs={12} sm={4} textAlign={'center'}>
          <Link to="/bufferloop/customer/information">
            LTD (Life Time Deal)
          </Link>
        </Grid>

        <Grid item xs={12} sm={4} textAlign={'center'}>
          <Link to="/bufferloop/appsumo/customer/information">AppSumo</Link>
        </Grid>
      </Grid>
    </>
  );
}
