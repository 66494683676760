import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import Footer from 'src/components/Footer';

import { Grid } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import { apiConfig } from 'src/config';
// import useAuth from 'src/hooks/useAuth';
import MarketPlacePrograms from './MarketPlacePrograms';
import LoadingAnimation from 'src/components/LoadingAnim';
import { _arrayBufferToBase64 } from 'src/utils/functions';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import PageHeader from 'src/components/PageHeader/index.js';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { fetchAllVendorCategories } from 'src/services/public/marketPlaceService';

function PublicMarketPlaceProgram() {
  const isMountedRef = useRefMounted();
  // const { user } = useAuth();
  const [programs, setPrograms] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const getMarketplaceProgram = useCallback(async () => {
    try {
      const partnerMarketplace = await axios.get(
        apiConfig.APP_BASE_URL + 'public/api/v1/marketplace/programs/'
      );
      console.log('partnerMarketplace', partnerMarketplace);

      if (isMountedRef.current) {
        if (partnerMarketplace?.data?.length > 0) {
          let temp = partnerMarketplace?.data?.map((prog) => {
            if (prog?.companyProfile?.logo?.data?.length > 0) {
              prog.companyProfile.logo = _arrayBufferToBase64(
                prog?.companyProfile?.logo?.data
              );
            }
            return prog;
          });
          setPrograms([...temp]);
        } else {
          setPrograms([]);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    setLoading(true);
    getMarketplaceProgram().finally(() => {
      setLoading(false);
    });
  }, [getMarketplaceProgram]);

  const getVendorCategories = useCallback(async () => {
    try {
      const vendorCategroiesResponse = await fetchAllVendorCategories();
      if (vendorCategroiesResponse) {
        let vendorCategoriesResponseData = [...vendorCategroiesResponse];

        vendorCategoriesResponseData = _.orderBy(
          vendorCategoriesResponseData,
          'createdAt',
          'asc'
        );
        setVendorCategories([...vendorCategoriesResponseData]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getVendorCategories();
  }, [isMountedRef, getVendorCategories]);

  return (
    <>
      <Helmet>
        <title>MarketPlace</title>
      </Helmet>
      <Grid
        container
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={10}>
          <PageTitleWrapper>
            <PageHeader name="MarketPlace" />
          </PageTitleWrapper>

          {loading && <LoadingAnimation />}
          {!loading && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
                className="content_left_right_spacing"
              >
                <Grid item xs={12}>
                  <MarketPlacePrograms
                    vendorCategories={vendorCategories}
                    getMarketplaceProgram={getMarketplaceProgram}
                    programs={programs}
                  />
                </Grid>
              </Grid>
              <Footer />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={1} />
      </Grid>
    </>
  );
}

export default PublicMarketPlaceProgram;
