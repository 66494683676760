// Member services

import axios from "axios";
import { apiConfig } from "src/config";

export const saveMemberDetails = async (memberDetail) => {
    return axios
      .post(
        apiConfig.APP_BASE_URL + 'admin/api/v1/team/member/create',
        memberDetail
      )
      .then((res) => {
        return res.data;
      });
  };
  
  export const updateMemberDetails = async (memberDetail) => {
    return axios
      .post(
        apiConfig.APP_BASE_URL + 'admin/api/v1/team/member/update',
        memberDetail
      )
      .then((res) => {
        return res.data;
      });
  };
  
  export const deleteTeamMemberForAdmin = async (memberDetail) => {
    return axios
      .post(
        apiConfig.APP_BASE_URL + 'admin/api/v1/team/member/delete',
        memberDetail
      )
      .then((res) => {
        return res.data;
      });
  };
  
  export const getAllTeamMembersForAdmin = async () => {
    return axios
      .get(apiConfig.APP_BASE_URL + 'admin/api/v1/team/member/fetch/')
      .then((res) => {
        return res.data;
      });
  };
  
  // Teams Services
  
  export const saveTeamForAdmin = async (team) => {
    return axios
      .post(apiConfig.APP_BASE_URL + 'admin/api/v1/team/new', team)
      .then((res) => {
        return res.data;
      });
  };
  
  export const updateTeamForAdmin = async (teamPayload) => {
    return axios
      .post(apiConfig.APP_BASE_URL + 'admin/api/v1/team/update', teamPayload)
      .then((res) => {
        return res.data;
      });
  };
  
  export const deleteteam = async ( teamId) => {
    return axios
      .delete(
        apiConfig.APP_BASE_URL +
          'admin/api/v1/team/delete/' +
          teamId
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };
  
  export const getSingleTeamForAdmin = async ( teamId) => {
    return axios
      .get(
        apiConfig.APP_BASE_URL +
          'admin/api/v1/team/single/' +
          teamId
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };
  
  export const getAllTeamsForAdmin = async () => {
    return axios
      .get(apiConfig.APP_BASE_URL + 'admin/api/v1/team/fetch/')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };