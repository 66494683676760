import axios from 'axios';
import { apiConfig } from 'src/config';

export const sendEmail = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'email/api/v1/send', payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
