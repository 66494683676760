import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchAllNotifications = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'vendor/api/v1/notifications/fetch/all')
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleNotification = async (notificationId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/notifications/fetch/single/' +
        notificationId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const markNotificationsAsRead = async (vendorId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'vendor/api/v1/notifications/mark/read/' +
        vendorId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
