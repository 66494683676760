import axios from 'axios';
import { apiConfig } from 'src/config';

export const getAllTeamsForLoggedInPartner = async (partnerId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'partner/api/v1/loggedIn/teams/fetch/' +
        partnerId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
