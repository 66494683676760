import { useRoutes } from 'react-router-dom';
import router from 'src/router/';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { Box, CircularProgress, CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect } from 'react';
import { apiConfig } from './config';
import axios from 'axios';

function App() {
  const auth = useAuth();
  const baseRouter = useRoutes(router);

  useEffect(() => {
    // Setting up Auth token
    axios.interceptors.request.use(
      async (config) => {
        config.headers['deliveryType'] = apiConfig?.DELIVERY_ENVIRONMENT;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? (
            baseRouter
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
