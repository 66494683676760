import Footer from 'src/components/Footer';
import { Card, Grid } from '@mui/material';
import ProspectViewPage from './ProspectViewPage';
import Sidebar from './Sidebar';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useRefMounted from 'src/hooks/useRefMounted';
import {
  getPartnerBioForProspectPage,
  getSingleProspectPage,
  increaseViewCountForProspectPage
} from 'src/services/public/prospectService';
import LoadingAnimatio2 from 'src/components/LoadingAnim2';
// import { getAllContentforPartner } from 'src/services/partner/contentService';
// import { getAllPlaybook } from 'src/services/partner/playbookService';
// import useAuth from 'src/hooks/useAuth';

function ViewPage() {
  const { prospectPageId } = useParams();
  // const { user } = useAuth();
  const [prospectPage, setProspectPage] = useState();
  const [partnerBio, setPartnerBio] = useState();
  const [ready, setReady] = useState(true);
  // const [content, setContent] = useState([]);
  // const [playbook, setPlaybook] = useState([]);
  const isMountedRef = useRefMounted();
  const [imageData, setImageData] = useState();

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const getProspectPage = useCallback(async () => {
    try {
      setReady(false);
      let prospectPage = await getSingleProspectPage(prospectPageId);
      if (isMountedRef.current) {
        prospectPage.contents = prospectPage?.contents?.map((content) => {
          if (content?.logo) {
            content.logo = _arrayBufferToBase64(content?.logo?.data);
          }
          return content;
        });
        prospectPage.playbooks = prospectPage?.playbooks?.map((playbook) => {
          if (playbook?.logo) {
            playbook.logo = _arrayBufferToBase64(playbook?.logo?.data);
          }
          return playbook;
        });

        setProspectPage(prospectPage);
        console.log('prospectPage...', prospectPage);
        if (prospectPage) {
          let partnerBio = await getPartnerBioForProspectPage(
            parseInt(prospectPage?.vendorId),
            parseInt(prospectPage?.partnerId)
          );
          setPartnerBio(partnerBio);
          let data = _arrayBufferToBase64(partnerBio?.logo?.data);
          if (partnerBio) {
            setImageData(data);
          }
          increaseViewCountForProspectPage(prospectPageId);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setReady(true);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getProspectPage();
  }, [getProspectPage, prospectPageId]);

  return (
    <>
      {ready && (
        <>
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  mt: 2
                }}
              >
                <Sidebar partnerBio={partnerBio} imageData={imageData} />
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <ProspectViewPage prospectPage={prospectPage} />
            </Grid>
          </Grid>
          <Footer />
        </>
      )}
      {!ready && <LoadingAnimatio2 />}
    </>
  );
}

export default ViewPage;
