import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  styled
} from '@mui/material';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: auto;
        box-shadow: none;
      }
`
);

function InfoDialog({
  description,
  openDescriptionDialog,
  setOpenDescriptionDialog,
  setSelectedDescription
}) {
  const handleCreateLessonClose = () => {
    setOpenDescriptionDialog(false);
    setSelectedDescription('');
  };
  return (
    <>
      <DialogWrapper
        fullWidth
        maxWidth="sm"
        open={openDescriptionDialog}
        onClose={handleCreateLessonClose}
      >
        <Box
          p={2}
          display={{ xs: 'block', md: 'block' }}
          alignItems="center"
          // textAlign={{ xs: 'center', md: 'center' }}
          justifyContent="space-between"
        >
          <DialogTitle
            sx={{
              // flexDirection: 'row',
              textAlign: 'center'
            }}
          >
            <Typography variant="button" fontSize={18}>
              {'Vendor Info'}
            </Typography>
          </DialogTitle>
          <Divider />
          <div>
            <Box m={1} minHeight={200}>
              {!description && <>Not Available</>}
              {description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              )}
            </Box>
          </div>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default InfoDialog;
