import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchAllPrograms = async (partnerId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/programs/' + partnerId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAllVendorPrograms = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/fetch/all/vendors')
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAllVendorCategories = async () => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/vendor/category/fetch')
    .then((res) => {
      return res.data;
    });
};

export const fetchVendorProfileById = async (vendorId) => {
  return axios
    .get(`${apiConfig.APP_BASE_URL}partner/api/v1/profile/fetch/id/${vendorId}`)
    .then((res) => {
      return res.data;
    });
};

export const getCompanyProfileForVendor = async (vendorId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'partner/api/v1/vendor/company/profile/' +
        vendorId
    )
    .then((res) => {
      return res.data;
    });
};

export const getOffersByVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/offers/fetch/' + vendorId)
    .then((res) => {
      return res.data;
    });
};

export const getManager = async (vendorId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'partner/api/v1/vendor/team/member/fetch/' +
        vendorId
    )
    .then((res) => {
      return res.data;
    });
};
