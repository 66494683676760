import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  // Alert,
  styled,
  Button
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import firebase from 'src/utils/firebase';
import useAuth from 'src/hooks/useAuth';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { fetchProfile as fetchVendorProfile } from 'src/services/vendor/profileService';
import { fetchProfile as fetchAdminProfile } from 'src/services/admin/profileService';
import { fetchProfile as fetchPartnerProfile } from 'src/services/partner/profileService';
import GoogleIcon from '@mui/icons-material/Google';
import { useTranslation } from 'react-i18next';
import { apiConfig } from 'src/config';
import StandardVendorLogo from 'src/components/Standard-Logo/StandardVendorLogo';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 0px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

function LoginCover() {
  const { method, signInWithGoogle } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useSnackbar();

  const googleSignInHandler = () => {
    signInWithGoogle()
      .then(async (data) => {
        console.log('google sign in success', data?.user?.email);

        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        //
        let claims = idTokenResult.claims;
        if (claims && claims.role === 'admin') {
          fetchAdminProfile(data?.user?.email, idTokenResult)
            .then(() => {
              // console.log('user profile', profile);
              navigate('/admin');
            })
            .catch((error) => {
              console.log(error);
              toast.enqueueSnackbar(
                'Error while fetching profile in bufferloop',
                { variant: 'error' }
              );
              navigate('/login');
            });
        } else if (claims && claims.role === 'vendor') {
          //  fetch profile
          fetchVendorProfile(data?.user?.email, idTokenResult)
            .then(() => {
              // console.log('user profile', profile);
              navigate('/vendor');
            })
            .catch((error) => {
              console.log(error);
              toast.enqueueSnackbar(
                'Error while fetching profile in bufferloop',
                { variant: 'error' }
              );
              navigate('/login');
            });
        } else if (claims && claims.role === 'partner') {
          fetchPartnerProfile(data?.user?.email, idTokenResult)
            .then(() => {
              // console.log('user profile', profile);
              navigate('/partner');
            })
            .catch((error) => {
              console.log(error);
              toast.enqueueSnackbar('Error while fetching partner profile', {
                variant: 'error'
              });
              navigate('/login');
            });
        } else {
          navigate('/login');
          toast.enqueueSnackbar('You are not authorized!', {
            variant: 'error'
          });
        }
      })
      .catch((error) => {
        console.log('google sign in error', error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Content>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <div style={{ marginTop: '7px' }}>
              <StandardVendorLogo logoHeight={50} />
            </div>

            <Card
              sx={{
                p: 4,
                my: 2
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}

              {apiConfig.DELIVERY_ENVIRONMENT === 'NETWORK' ||
              apiConfig.DELIVERY_ENVIRONMENT === 'DEMO' ? (
                <>
                  <Box my={4}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                      fontWeight="bold"
                    >
                      {t('Do you wish to become a Partner?')}
                    </Typography>{' '}
                    <Box display={{ xs: 'block', md: 'inline-block' }}>
                      <Link component={RouterLink} to="/partner/register">
                        <b>&nbsp;&nbsp;Partners can sign up here</b>
                      </Link>
                    </Box>
                  </Box>
                  <Box my={4} textAlign={'center'}>
                    <Tooltip arrow title={'Sign in with Google'}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          googleSignInHandler();
                        }}
                        color={'primary'}
                      >
                        <GoogleIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </>
              ) : null}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
