import { Box, useTheme } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
// import ThemeSettings from 'src/components/ThemeSett  ings';

import Sidebar from './Sidebar';
import Header from './Header';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import { getPartnerGroupForVendor } from 'src/services/partner/groupService';
import { useDispatch } from 'react-redux';
import {
  setSelectedVendor,
  setSelectedVendorId,
  setVendorGroupsMap,
  setVendorPlan
} from 'src/slices/vendorReducer';
import { getAllTeamsForLoggedInPartner } from 'src/services/partner/team-service';
import { setProfileTeams } from 'src/slices/profile-teams-reducer';
import { useSelector } from 'react-redux';
import {
  fetchVendorProfileById,
  getCompanyProfileForVendor,
  getManager
} from 'src/services/partner/programService';

const AccentHeaderLayout = () => {
  const theme = useTheme();
  const { user, logout, saveVendorGroupsMap } = useAuth();
  const { vendorId } = useParams();
  const dispatch = useDispatch();
  const selectedProfile = useSelector(
    (state) => state.profileTeams.selectedProfile
  );
  const vendor = useSelector((state) => state.vendor);
  const [partnerVendorLogo, setPartnerVendorLogo] = useState();
  const [partnerVendorCompanyProfile, setPartnerVendorCompanyProfile] =
    useState();
  const [vendorProfile, setVendorProfile] = useState();
  const [partnerManager, setPartnerManager] = useState('');
  const fetchVendorGroupsMap = async () => {
    let payload = {
      partnerId: selectedProfile?.partnerId
    };

    await getPartnerGroupForVendor(payload)
      .then(async (partnerGroupsForVendorData) => {
        let vendorGroupsMap = new Map();
        partnerGroupsForVendorData?.map((e) => {
          vendorGroupsMap.set(parseInt(e?.vendorId), e);
          return e;
        });
        await saveVendorGroupsMap(vendorGroupsMap);
        dispatch(
          setVendorGroupsMap({
            vendorGroupsMap: vendorGroupsMap
          })
        );
      })
      .catch((error) => {
        console.log('Error fetching vendor group info for partner', error);
      });
  };
  const fetchManager = async () => {
    const managersData = await getManager(vendorId);
    const vendorProfile = vendor?.vendorGroupsMap.get(parseInt(vendorId));
    const partnerVendorManager = managersData?.find(
      (item) => item.id === vendorProfile?.managerUserId
    );
    setPartnerManager(partnerVendorManager);
  };

  useEffect(async () => {
    fetchVendorGroupsMap();
    if (vendorId) {
      let selectedVendorProfile;
      fetchManager();
      await fetchVendorProfileById(vendorId)
        .then((vendorProfileRes) => {
          selectedVendorProfile = vendorProfileRes;
          setVendorProfile(vendorProfileRes);
          dispatch(
            setSelectedVendorId({
              selectedVendorId: vendorId
            })
          );
          dispatch(
            setSelectedVendor({
              selectedVendor: selectedVendorProfile
            })
          );
          dispatch(
            setVendorPlan({
              selectedVendorPlan: vendorProfileRes.currentPlan
            })
          );
        })
        .catch((error) => {
          console.log('error fetching vendor profile', error);
        });

      await getCompanyProfileForVendor(vendorId)
        .then((vendorProfileCompanyRes) => {
          setPartnerVendorCompanyProfile(vendorProfileCompanyRes);
          if (
            vendorProfileCompanyRes &&
            vendorProfileCompanyRes.logo &&
            vendorProfileCompanyRes.logo.data
          ) {
            let data = _arrayBufferToBase64(
              vendorProfileCompanyRes?.logo?.data
            );
            setPartnerVendorLogo(data);
          }
        })
        .catch((error) => {
          console.log('error fetching vendor profile', error);
        });
    }
  }, [vendorId, user]);

  useEffect(() => {
    getAllTeamsForLoggedInPartner(selectedProfile?.invitedPartnerId).then(
      (teamMappings) => {
        let teams = [];
        teamMappings?.map((mapping) => {
          mapping.team.partnerId = parseInt(mapping?.partnerId);
          mapping.team.invitedPartnerId = parseInt(mapping?.invitedPartnerId);
          mapping.team.profile = mapping?.profile;
          teams.push(mapping?.team);
          return mapping;
        });
        // setProfileTeams([...teams]);
        dispatch(
          setProfileTeams({
            profileTeams: teams
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    if (user?.role !== 'partner') {
      logout();
    }
  }, [user]);

  const _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  return (
    <>
      <Header
        partnerVendorLogo={partnerVendorLogo}
        vendorCompanyProfile={partnerVendorCompanyProfile}
        vendorProfile={vendorProfile}
      />
      <Sidebar partnerManager={partnerManager} />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${theme.sidebar.width}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentHeaderLayout;
