import HomeIcon from '@mui/icons-material/HomeTwoTone';
import ReportingIcon from '@mui/icons-material/ContentPasteTwoTone';
import RewardsIcon from '@mui/icons-material/AttachMoneyTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import SupportIcon from '@mui/icons-material/SupportAgentOutlined';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

const menuItems = [
  {
    items: [
      {
        name: 'Home',
        icon: HomeIcon,
        link: '/partner/home'
      },
      {
        name: 'MarketPlace',
        icon: StorefrontTwoToneIcon,
        link: '/partner/marketplace'
      },
      {
        name: 'Rewards',
        icon: RewardsIcon,
        link: '/partner/rewards'
      },
      {
        name: 'Settings',
        icon: SettingsIcon,
        link: '/partner/settings'
      },

      {
        name: 'Support',
        icon: SupportIcon,
        link: '/partner/support'
      },
      {
        name: 'Reporting',
        icon: ReportingIcon,
        link: '/partner/analytics'
      }
    ]
  }
  /* {
    heading: 'User Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/accent-header/management/users',
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      }
    ]
  },
  {
    heading: 'Program Management',
    items: [
      {
        name: 'Programs',
        icon: AccountTreeTwoToneIcon,
        link: '/accent-header/management/programs/list'
      }
    ]
  },
  {
    heading: 'Invoice Management',
    items: [
    ]
  } */
];

export default menuItems;
