import PropTypes from 'prop-types';
import {
  Card,
  Grid,
  CardActionArea,
  useTheme,
  styled,
  alpha,
  CardMedia,
  CardActions,
  // IconButton,
  Typography,
  Link,
  ListItem,
  Button,
  ListItemText,
  Divider,
  CardHeader,
  Box
} from '@mui/material';

// import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
// import { IosShareTwoTone } from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import { useTranslation } from 'react-i18next';

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
    display: flex;
    align-items: center;
    // justify-content: space-between;
    position: relative;

    .MuiTouchRipple-root {
        opacity: .3;
    }

    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .05;
        }
    }
  `
);

const PlaybookContents = ({ playbookContents, playbookPreview }) => {
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();

  const getFileNameFromKey = (key, vendorId, playbookId) => {
    let fileName = key?.replace(
      `vendor/${vendorId}/playbooks/${playbookId}/`,
      ''
    );
    return fileName;
  };
  const currentAttachments = playbookPreview?.attachments?.map(
    (file, index) => (
      <ListItem disableGutters component="div" key={index}>
        <>
          <ListItemText
            primary={getFileNameFromKey(
              file.key,
              playbookPreview.vendorId,
              playbookPreview.id
            )}
          />
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
              mr: 2
            }}
            href={file.url}
            target="_self"
            variant="outlined"
            startIcon={<DownloadIcon scale={100} color="success" />}
          >
            {t('Download')}
          </Button>
        </>
      </ListItem>
    )
  );

  return (
    <>
      <Card
        sx={{
          p: 5,
          mb: 3,
          mt: 3
        }}
      >
        <CardHeader
          title={
            <>
              <Typography
                variant="h4"
                mb={1}
                fontSize={20}
                textAlign={'center'}
              >
                {playbookPreview?.title}
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </>
          }
          disableTypography
        />
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={12}>
            <Typography variant="h4" component="h3" gutterBottom>
              {playbookPreview?.title}
            </Typography>
          </Grid> */}
          {/* <Grid container spacing={2}> */}
          {playbookPreview?.description && (
            // <Grid item xs={12} md={12}>
            <>
              <Grid item xs={12} md={8} p={2}>
                <Card
                  sx={{
                    padding: 1,
                    textAlign: 'left',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    minHeight: '100%',
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
                    }
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: playbookPreview?.description
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={4} p={2}>
                <Card
                  sx={{
                    textAlign: 'center',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
                    }
                  }}
                >
                  <CardActionAreaWrapper>
                    {!playbookPreview?.logo && (
                      <CardMedia
                        component="img"
                        height={'200px'}
                        width={'auto'}
                        image="/static/images/placeholders/content_image_blank.jpg"
                        alt="..."
                      />
                    )}
                    {playbookPreview?.logo && (
                      <Box>
                        <img
                          // src={imageUrl}
                          src={`data:image;base64, ${playbookPreview?.logo}`}
                          alt="content"
                          style={{
                            borderRadius: '5%'
                          }}
                          height={'200px'}
                          width={'auto'}
                        />
                      </Box>
                    )}

                    <CardActions
                      sx={{
                        bottom: 'auto',
                        top: `${theme.spacing(2)}`
                      }}
                    />
                  </CardActionAreaWrapper>
                  <CardActions
                    sx={{
                      justifyContent: 'center',
                      display: 'none'
                    }}
                  >
                    <Button
                      href={playbookPreview?.imageFileURL}
                      target="_self"
                      variant="outlined"
                      startIcon={<DownloadIcon scale={50} color="success" />}
                    >
                      {t('Download')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </>
            // </Grid>
          )}
          {/* </Grid> */}
          {playbookPreview?.attachments?.length > 0 && (
            <Grid item xs={12} md={12}>
              <Divider />
              <Card
                sx={{
                  p: 1,
                  mb: 2,
                  mt: 2
                }}
              >
                {currentAttachments}
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
      <Card
        sx={{
          p: 1,
          mb: 3
        }}
      >
        <Grid container spacing={2} p={2}>
          {playbookContents?.map((content) => (
            <Grid item xs={12} sm={8} md={3}>
              <Card
                sx={{
                  textAlign: 'center',
                  transition: `${theme.transitions.create([
                    'box-shadow',
                    'transform'
                  ])}`,
                  transform: 'translateY(0px)',

                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: `0 2rem 8rem 0 ${alpha(
                      theme.colors.alpha.black[100],
                      0.1
                    )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
                  }
                }}
              >
                <CardActionAreaWrapper>
                  {!content?.logo && (
                    <CardMedia
                      component="img"
                      height={'150px'}
                      width={'auto'}
                      image="/static/images/placeholders/content_image_blank.jpg"
                      alt="..."
                    />
                  )}
                  {content?.logo && (
                    <Box>
                      <img
                        // src={imageUrl}
                        src={`data:image;base64, ${content?.logo}`}
                        alt="content"
                        style={{
                          borderRadius: '5%'
                        }}
                        height={'150px'}
                        width={'auto'}
                      />
                    </Box>
                  )}
                  <CardActions
                    sx={{
                      bottom: 'auto',
                      top: `${theme.spacing(2)}`
                    }}
                  />
                </CardActionAreaWrapper>
                <Typography
                  variant="body2"
                  maxHeight={60}
                  minHeight={60}
                  m={1}
                  className="content_playbook_title"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 4
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/${location.pathname.split('/')[1]}/content/${
                      content.content_id
                    }`}
                  >
                    {content?.title}
                  </Link>
                </Typography>
                {/* <CardActions>
                  <IconButton
                    sx={{
                      p: 1,
                      borderRadius: 55
                    }}
                    size="small"
                    color="error"
                  >
                    <FavoriteTwoToneIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    sx={{
                      p: 1,
                      borderRadius: 55
                    }}
                    size="small"
                  >
                    <IosShareTwoTone fontSize="small" />
                  </IconButton>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    </>
  );
};
PlaybookContents.propTypes = {
  playbookContents: PropTypes.array.isRequired
};

PlaybookContents.defaultProps = {
  playbookContents: []
};
export default PlaybookContents;
