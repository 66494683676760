import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchVendorProfileById = async (vendorId) => {
  return axios
    .get(`${apiConfig.APP_BASE_URL}public/api/v1/profile/fetch/id/${vendorId}`)
    .then((res) => {
      return res.data;
    });
};

export const getCompanyProfileForVendor = async (vendorId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'public/api/v1/company/profile/' + vendorId)
    .then((res) => {
      return res.data;
    });
};

export const sendEmailVerificationLink = async (email) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'general/api/v1/send/email/verification', {
      email: email
    })
    .then((res) => {
      return res.data;
    });
};

export const getStandardVendorLogo = async (payload) => {
  return axios
    .post(`${apiConfig.APP_BASE_URL}public/api/v1/vendor/logo/fetch/`, payload)
    .then((res) => {
      return res.data;
    });
};
