import axios from 'axios';
import { apiConfig } from 'src/config';

export const registerPartner = async (partner) => {
  console.log();
  return axios
    .post(apiConfig.APP_BASE_URL + 'partner/public/api/v1/add/partner', partner)
    .then((res) => {
      return res.data;
    });
};

export const signupwithGoogle = async (payload) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'general/api/v1/google/signup', payload)
    .then((res) => {
      return res.data;
    });
};
