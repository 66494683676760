const moment = require('moment');

const getFormattedDate = (date) => {
  return moment(date).format('DD-MM-YYYY');
};
export const getFormattedDateTime = (date) => {
  return moment(date).format('DD-MM-YYYY hh:mm A');
};
export const bytesToSize = (bytes, seperator = '') => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
};

export const _arrayBufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
export const handleEmailBodyVariables = (partnerName, text) => {
  return text.replaceAll('{{partner_name}}', partnerName);
};

export const getColumnWidth = (
  charWidth,
  numberOfColumns,
  desiredColumnWidthInPixel
) => {
  const percentWidth = Math.round(
    (desiredColumnWidthInPixel / (charWidth / numberOfColumns)) * 100
  );
  return `${percentWidth}%`;
};

export default getFormattedDate;
