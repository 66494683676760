// import accountRoutes from './admin/bl-account';

import Authenticated from 'src/components/Authenticated';
import Guest from 'src/components/Guest';
import LoginCover from 'src/content/pages/Auth/Login/Cover';
import RegisterCover from 'src/content/pages/Auth/Register/Cover';
import RecoverPassword from 'src/content/pages/Auth/RecoverPassword';
// import BaseLayout from "src/layouts/BaseLayout";
import AccentHeaderLayoutVendor from 'src/layouts/vendor/AccentHeaderLayout';
import AccentHeaderLayoutPartner from 'src/layouts/partner/AccentHeaderLayout';
import AccentHeaderLayoutPartnerProgram from 'src/layouts/partnerProgram/AccentHeaderLayout';
import AccentHeaderLayoutAdmin from 'src/layouts/admin/AccentHeaderLayout';
import AccentHeaderLayoutCustomer from 'src/layouts/customer/AccentHeaderLayout';
import vendorRoutes from './vendorRoutes';
import adminRoutes from './adminRoutes';
import partnerRoutes from './partnerRoutes';
import partnerProgramRoutes from './partnerProgramRoutes';
import customerRoutes from './customerRoutes';
import BaseLayout from 'src/layouts/BaseLayout';
import OnboardingFormPublic from 'src/content/bufferloop/public/onboarding/BL-OnboardingForm';
import PlaybookPreview from 'src/content/bufferloop/public/playbook/BL-PlaybookContents';
import ContentPreview from 'src/content/bufferloop/public/content/ContentPreview';
import ProspectPageView from 'src/content/bufferloop/public/prospectPage';
import CustomerInformationPage from 'src/content/bufferloop/public/customerInformationForm';
import MarketPlacePage from 'src/content/bufferloop/public/MarketPlace';
import {
  contentURL,
  customerInformationpage,
  marketPlacePage,
  onboardingURL,
  playbookURL,
  prospectPageURLPath,
  demoCustomerInformationForm,
  appSumoCustomerInformationForm,
  stripeConnectRefreshPage
  // customerSuccessPage,
  // subscriptionsPage
} from './publicURLs';
import { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import EmailVerification from 'src/content/pages/Auth/EmailVerification';
import DemoCustomerInformationForm from 'src/content/bufferloop/public/customerInformationForm/DemoCustomerInformationForm';
import AppSumoCustomerInformationForm from 'src/content/bufferloop/public/customerInformationForm/AppSumoCustomerInformationForm';
import StripeConnectPage from 'src/content/bufferloop/public/BL-Stripe-Redirect';
// import SubscriprionSuccess from 'src/content/bufferloop/public/subscriptionsSuccess';
// import StripeSubscription from 'src/content/bufferloop/public/subscriptions';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
// import baseRoutes from './base';
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const router = [
  {
    path: '/partner/register',
    element: (
      // <Guest>
      <RegisterCover />
      // </Guest>
    )
  },
  {
    path: '/account/recover-password',
    element: (
      // <Guest>
      <RecoverPassword />
      // </Guest>
    )
  },
  {
    path: '/account/email-verification',
    element: (
      // <Guest>
      <EmailVerification />
      // </Guest>
    )
  },
  {
    path: '/login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  /* {
    path: '',
    element: (
        <Authenticated>
      </Authenticated>
    )
  }, */
  {
    path: '/vendor',
    element: (
      <Authenticated>
        <AccentHeaderLayoutVendor />
      </Authenticated>
    ),
    children: vendorRoutes
  },
  {
    path: '/partner',
    element: (
      <Authenticated>
        <AccentHeaderLayoutPartner />
      </Authenticated>
    ),
    children: partnerRoutes
  },
  {
    path: '/partner/program',
    element: (
      <Authenticated>
        <AccentHeaderLayoutPartnerProgram />
      </Authenticated>
    ),
    children: partnerProgramRoutes
  },
  {
    path: '/admin',
    element: (
      <Authenticated>
        <AccentHeaderLayoutAdmin />
      </Authenticated>
    ),
    children: adminRoutes
  },
  {
    path: '/customer',
    element: (
      <Authenticated>
        <AccentHeaderLayoutCustomer />
      </Authenticated>
    ),
    children: customerRoutes
  },
  {
    path: onboardingURL,
    element: (
      <BaseLayout>
        <OnboardingFormPublic />
      </BaseLayout>
    )
  },
  {
    path: playbookURL,
    element: (
      <BaseLayout>
        <PlaybookPreview />
      </BaseLayout>
    )
  },
  {
    path: contentURL,
    element: (
      <BaseLayout>
        <ContentPreview />
      </BaseLayout>
    )
  },
  {
    path: prospectPageURLPath,
    element: (
      <BaseLayout>
        <ProspectPageView />
      </BaseLayout>
    )
  },
  {
    path: customerInformationpage,
    element: (
      <BaseLayout>
        <CustomerInformationPage />
      </BaseLayout>
    )
  },
  {
    path: marketPlacePage,
    element: (
      <BaseLayout>
        <MarketPlacePage />
      </BaseLayout>
    )
  },
  {
    path: demoCustomerInformationForm,
    element: (
      <BaseLayout>
        <DemoCustomerInformationForm />
      </BaseLayout>
    )
  },
  {
    path: stripeConnectRefreshPage,
    element: (
      <BaseLayout>
        <StripeConnectPage />
      </BaseLayout>
    )
  },
  {
    path: appSumoCustomerInformationForm,
    element: (
      <BaseLayout>
        <AppSumoCustomerInformationForm />
      </BaseLayout>
    )
  },

  /* {
    path: subscriptionsPage,
    element: (
      <BaseLayout>
        <StripeSubscription />
      </BaseLayout>
    )
  },
  {
    path: customerSuccessPage,
    element: (
      <BaseLayout>
        <SubscriprionSuccess />
      </BaseLayout>
    )
  }, */
  /* {
    path: '/',
    element:(
      <Authenticated>
        <AccentHeaderLayoutPartner />
      </Authenticated>
    ),
    children: partnerRoutes
  }, */
  {
    path: '',
    element: <LoginCover />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
  /* {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  }, */
];

export default router;
