import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  // ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material';

import OfferIcon from '@mui/icons-material/LocalOffer';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: auto;
        box-shadow: none;
      }
`
);

function OffersDialog({
  selectedOfferList,
  openOfferDetailsDialog,
  setOpenOfferDetailsDialog
}) {
  const handleCreateLessonClose = () => {
    setOpenOfferDetailsDialog(false);
  };
  return (
    <>
      <DialogWrapper
        fullWidth
        maxWidth="sm"
        open={openOfferDetailsDialog}
        onClose={handleCreateLessonClose}
      >
        <Box
          p={2}
          display={{ xs: 'block', md: 'block' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'center' }}
          justifyContent="space-between"
        >
          <DialogTitle
            sx={{
              // flexDirection: 'row',
              textAlign: 'center'
            }}
          >
            <Typography variant="button" fontSize={18}>
              {'Available Offers'}
            </Typography>
          </DialogTitle>
          <Divider />
          {selectedOfferList?.offers?.map((OffersList) => (
            <List>
              <Box>
                <ListItem>
                  <ListItemIcon>
                    <OfferIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        pt: { xs: 2, md: 0 }
                      }}
                      variant="body2"
                    >
                      {OffersList}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Box>
            </List>
          ))}
          {(!selectedOfferList?.offers ||
            selectedOfferList?.offers?.length <= 0) && (
            <Box m={2}>
              <Typography>{'No Active offers found'}</Typography>
            </Box>
          )}
        </Box>
      </DialogWrapper>
    </>
  );
}

export default OffersDialog;
