import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

import { useTranslation } from 'react-i18next';

const BackButton = (props) => {
  const { handleBack } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackSelf = () => {
    if (handleBack) {
      navigate(handleBack);
    }
    else {
      navigate(-1);
    }
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
      <Tooltip arrow placement="top" title={t('Go back')}>
        <IconButton
          onClick={handleBackSelf}
          color="primary"
        >
          <ArrowBackTwoToneIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default BackButton;
