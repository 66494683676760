import { Box, Grid, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import loadingAnim from '../../static/loading2.json';

const LoadingAnimatio2 = ({ large }) => (
  <>
    {large && (
      <Grid container>
        <Grid item md={4} />
        <Grid item md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60vh'
            }}
          >
            <Lottie animationData={loadingAnim} />;
          </Box>
        </Grid>
        <Grid item md={4} />
      </Grid>
    )}
    {!large && (
      <Grid container>
        <Grid item md={5} />
        <Grid item md={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '60vh'
            }}
          >
            <Typography variant="h3" color={'primary'}>
              {'Please wait'}
            </Typography>
            <Lottie animationData={loadingAnim} />;
          </Box>
        </Grid>
        <Grid item md={5} />
      </Grid>
    )}
  </>
);
export default LoadingAnimatio2;
