import { mock } from 'src/utils/axios';

import { subDays } from 'date-fns';

let enrolledPartners = [
  {
    id: '1',
    name: 'User 1',
    screenshot: '/static/images/placeholders/fitness/1.jpg',
    description: 'Sample Program.',
    // tags: ['Software'],
    startDate: subDays(new Date(), 1).getTime(),
    dueDate: subDays(new Date(), 3).getTime(),
    memberIds: [
      {
        id: '1',
        name: 'Partner 1'
        // avatar: '/static/images/avatars/1.jpg'
      },
      {
        id: '2',
        name: 'Darice Malyon'
        // avatar: '/static/images/avatars/2.jpg'
      },
      {
        id: '3',
        name: 'Dwain Culpan'
        // avatar: '/static/images/avatars/3.jpg'
      },
      {
        id: '4',
        name: 'Carleton Henric'
        // avatar: '/static/images/avatars/4.jpg'
      },
      {
        id: '5',
        name: 'Dillie Considine'
        // avatar: '/static/images/avatars/5.jpg'
      }
    ],
    progress: 56,
    status: 'completed'
  }
];

mock.onGet('/api/enrolledPartners').reply(() => {
  return [200, { enrolledPartners }];
});
