import axios from 'axios';
import { apiConfig } from 'src/config';

export const createAppSumoCustomer = async (payload, imageFile) => {
  const formData = new FormData();
  payload.logoFileName = imageFile?.name;
  if (imageFile) {
    formData.append(imageFile?.name, imageFile);
  }
  formData.append('vendorsData', JSON.stringify(payload));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'public/api/v1/create/appSumo/vendor/',
      formData,
      config
    )
    .then((res) => {
      return res.data;
    });
};
