import SummaryIcon from '@mui/icons-material/AssignmentOutlined';
import BackToHomeIcon from '@mui/icons-material/ArrowBackTwoTone';
import LinksIcon from '@mui/icons-material/SendTwoTone';
import PerformanceIcon from '@mui/icons-material/AttachMoneyTwoTone';
import ReferrelIcon from '@mui/icons-material/GroupsTwoTone';
import DealsIcon from '@mui/icons-material/BallotTwoTone';
import LeadsIcon from '@mui/icons-material/RadarTwoTone';
import PlaybookIcon from '@mui/icons-material/SmartDisplay';
import ProspectPagesIcon from '@mui/icons-material/StarOutline';
import ContentLibraryIcon from '@mui/icons-material/MenuBookTwoTone';
import TrainingIcon from '@mui/icons-material/SchoolTwoTone';
import MessageIcon from '@mui/icons-material/MessageTwoTone';
// import NewspaperTwoToneIcon from '@mui/icons-material/NewspaperTwoTone';

const menuItems = [
  {
    items: [
      {
        name: 'back to dashboard',
        icon: BackToHomeIcon,
        link: '/partner'
      },
      // {
      //   name: 'News & Announcement',
      //   icon: NewspaperTwoToneIcon,
      //   link: '/partner/program/news'
      // },
      {
        name: 'Summary',
        icon: SummaryIcon,
        link: '/partner/program/summary'
      },
      {
        name: 'Performance',
        icon: PerformanceIcon,
        link: '/partner/program/performance'
      },

      {
        name: 'Links',
        icon: LinksIcon,
        link: '/partner/program/links'
      },
      {
        name: 'Deals',
        icon: DealsIcon,
        link: '/partner/program/deals'
      },
      {
        name: 'Lead',
        icon: LeadsIcon,
        link: '/partner/program/leads'
      },
      {
        name: 'Referral',
        icon: ReferrelIcon,
        link: '/partner/program/referrals'
      },
      {
        name: 'Prospect Pages',
        icon: ProspectPagesIcon,
        link: '/partner/program/prospectPages'
      },
      {
        name: 'Content Library',
        icon: ContentLibraryIcon,
        link: '/partner/program/content'
      },
      {
        name: 'Playbook',
        icon: PlaybookIcon,
        link: '/partner/program/playbook'
      },
      {
        name: 'Training',
        icon: TrainingIcon,
        link: '/partner/program/training'
      },
      {
        name: 'Messages',
        icon: MessageIcon,
        link: '/partner/program/messaging'
      }
    ]
  }
  /* {
    heading: 'User Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/accent-header/management/users',
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      }
    ]
  },
  {
    heading: 'Program Management',
    items: [
      {
        name: 'Programs',
        icon: AccountTreeTwoToneIcon,
        link: '/accent-header/management/programs/list'
      }
    ]
  },
  {
    heading: 'Invoice Management',
    items: [
    ]
  } */
];

export default menuItems;
