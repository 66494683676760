import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchProfile = async (userEmail, tokenResult) => {
  console.log(userEmail);
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'vendor/api/v1/profile/fetch',
      {
        userEmail
      },
      {
        headers: {
          token: tokenResult?.token
        }
      }
    )
    .then((res) => {
      return res?.data;
    });
};

export const updateVendorProfileName = async (name, vendorId) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/profile/update/' + vendorId, {
      business_name: name
    })
    .then((res) => {
      return res?.data;
    });
};

export const fetchVendorProfileById = async (vendorId) => {
  return axios
    .get(`${apiConfig.APP_BASE_URL}vendor/api/v1/profile/fetch/id/${vendorId}`)
    .then((res) => {
      return res.data;
    });
};

export const fetchActiveVendorSubscriptions = async (stripeCustomerId) => {
  return axios
    .post(apiConfig.APP_BASE_URL + 'vendor/api/v1/get/user/subscriptions', {
      stripeCustomerId: stripeCustomerId
    })
    .then((res) => {
      return res.data;
    });
};
