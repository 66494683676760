import axios from 'axios';
import { apiConfig } from 'src/config';

export const fetchProfile = async (userEmail, tokenResult) => {
  console.log(userEmail);
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/profile/fetch',
      {
        userEmail
      },
      {
        headers: {
          token: tokenResult?.token
        }
      }
    )
    .then((res) => {
      return res?.data;
    });
};

export const fetchPartnerProfileById = async (partnerId) => {
  return axios
    .get(apiConfig.APP_BASE_URL + 'partner/api/v1/profile/fetch/' + partnerId)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchStripeAccountForPartnerByPartnerId = async (partnerId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'stripe/api/v1/fetch/partner/account/' +
        partnerId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAvailableBalance = async (partnerId) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'stripe/api/v1/partner/fetch/balance/' +
        partnerId
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePartnerProfileName = async (name, partnerId) => {
  return axios
    .post(
      apiConfig.APP_BASE_URL + 'partner/api/v1/profile/update/' + partnerId,
      {
        business_name: name
      }
    )
    .then((res) => {
      return res?.data;
    });
};
