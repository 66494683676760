import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
// import Header from './Header';

const BaseLayout = ({ children }) => {
  // const {} = useParams();
  return (
    <>
      {/* <Header /> */}
      <Box
        sx={{
          flex: 1,
          height: '100%'
        }}
      >
        {children || <Outlet />}
      </Box>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
