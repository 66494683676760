import axios from 'axios';
import { apiConfig } from 'src/config';

export const getSinglePlaybook = async (payload) => {
  return axios
    .get(
      apiConfig.APP_BASE_URL +
        'public/api/v1/playbook/preview/' +
        payload.playbook_id
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
