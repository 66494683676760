import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Checkbox,
  // Divider,
  FormControlLabel,
  Link,
  TextField,
  Typography,
  CircularProgress
  // styled
} from '@mui/material';
// import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router';
import { registerPartner } from 'src/services/partner/registrationService';
import { useState } from 'react';
import { sendEmail } from 'src/services/email/emailService';
// import { welcomeEmailConfig } from 'src/config';
import { signUpEmailTemplate } from 'src/emailTemplates';

/* const ImgWrapper = styled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
); */

function RegisterFirebaseAuth({ setOpen }) {
  /* const { createUserWithEmailAndPassword, logout ,signInWithGoogle } =
    useAuth(); */
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const toast = useSnackbar();
  // const naviagte = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);

  /* const handleGoogleClick = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  }; */

  return (
    <>
      {/* <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        variant="outlined"
      >
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        Register with Google
      </Button>
      <Divider
        sx={{
          mt: 4,
          mb: 2
        }}
      >
        {t('or')}
      </Divider> */}
      {!loading && (
        <Formik
          initialValues={{
            email: '',
            name: '',
            password: '',
            terms: true,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('The email provided should be a valid email address'))
              .max(50)
              .required(t('The email is required')),
            name: Yup.string().max(50).required(t('The name is required')),
            password: Yup.string()
              .min(6)
              .max(15)
              .required(t('The password is required')),
            terms: Yup.boolean().oneOf(
              [true],
              t('You must agree to our terms and conditions')
            )
          })}
          onSubmit={async (values, { setErrors, setStatus }) => {
            // setLoading(true);
            // setSubmitting(true);
            try {
              toast.enqueueSnackbar('Creating account...', {
                variant: 'success'
              });
              /* await createUserWithEmailAndPassword(values.email, values.password)
              .then((userCreds) => {
                console.log(JSON.stringify(userCreds));
                userCreds.user.sendEmailVerification().then(function (res) {
                  console.log('send verification email call res', res);
                });
                toast.enqueueSnackbar('User is now registered', {
                  variant: 'success'
                });
                logout();
                naviagte('/login');
              })
              .catch((err) => {
                console.log(err.code);
                if (err.code === 'auth/email-already-in-use') {
                  toast.enqueueSnackbar(
                    'This email already exists in the system',
                    { variant: 'error' }
                  );
                } else {
                  toast.enqueueSnackbar('Error in signup', {
                    variant: 'error'
                  });
                }
              }); */
              let partnerPayload = {
                name: values.name,
                email: values.email,
                password: values.password
              };
              setSubmitDisable(true);
              registerPartner(partnerPayload)
                .then(async (response) => {
                  toast.enqueueSnackbar('User is now registered', {
                    variant: 'success'
                  });
                  console.log('partner registration response', response);
                  const vendorLogo = '';
                  // let emailPayload = {
                  //   subject: `${welcomeEmailConfig.subject} ${values.name}`,
                  //   messageBody: `${welcomeEmailConfig.messageBody}`,
                  //   toIds: [values.email]
                  // };

                  const subject = `Welcome to Bufferloop✌️🚀`;
                  const message = values.name;
                  const userLogo =
                    vendorLogo?.selectedVendorLogo ||
                    `https://app.bufferloop.com/Bufferloop-Logo-login.png`;
                  const urlLink = process.env.REACT_APP_UI_PORTAL_URL;
                  const emailPayload = signUpEmailTemplate(
                    subject,
                    message,
                    userLogo,
                    urlLink,
                    [values.email]
                  );

                  await sendEmail(emailPayload)
                    .then(() => {})
                    .catch((error) =>
                      console.log(
                        'Error sending welcome email to partner',
                        error
                      )
                    );
                  setSubmitDisable(false);
                  setOpen(true);
                  // naviagte('/login');
                })
                .catch((error) => {
                  setSubmitDisable(false);
                  let message = error?.response?.data?.message;
                  if (!message) {
                    message = error?.message;
                  }
                  toast.enqueueSnackbar('Error in registration - ' + message, {
                    variant: 'error'
                  });
                  console.log('partner registration error', error);
                })
                .finally(() => {
                  // setSubmitting(false);
                  setLoading(false);
                });
              if (isMountedRef.current) {
                setStatus({ success: true });
                setLoading(false);
              }
            } catch (err) {
              console.error(err);

              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
              }
              setLoading(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('Name')}
                placeholder={t('Your name here...')}
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('Email address')}
                placeholder={t('Your email address here...')}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('Password')}
                placeholder={t('Your password here...')}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.terms}
                    name="terms"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                }
              />
              <Button
                sx={{
                  mt: 3
                }}
                color="primary"
                // startIcon={
                //   isSubmitting ? <CircularProgress size="1rem" /> : null
                // }
                disabled={isSubmitting}
                startIcon={
                  submitDisable ? (
                    <CircularProgress size="1rem" sx={{ color: '#fff' }} />
                  ) : null
                }
                size="large"
                fullWidth
                type="submit"
                variant="contained"
              >
                {t('Create account')}
              </Button>
            </form>
          )}
        </Formik>
      )}
      {loading && <CircularProgress />}
    </>
  );
}

export default RegisterFirebaseAuth;
